import { PrinterOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';
import XDate from "xdate";
import { formatearCantidad } from '../../../recursos';
import { useArticulosStore } from '../../../Hooks/Articulos/useArticulosStore';

export const centrarTextoPDF = (doc, texto) => {
  let fontSize = doc.internal.getFontSize();
  let anchoDocumento = doc.internal.pageSize.width;
  let txtWidth =
    (doc.getStringUnitWidth(texto) * fontSize) / doc.internal.scaleFactor;
  let x = (anchoDocumento - txtWidth) / 2;
  return x;
};

const ButtonPDFArticulosPedido = ({ articulos }) => {

    const {
        master
    } = useMaestrosStore();

    const { pedido } = useArticulosStore();

    const exportarDocumento = () => {

        const doc = new jsPDF();
        let x = centrarTextoPDF(doc, "");
        doc.setFontSize(8);
        doc.setFont("", "bold");
        doc.setFontSize(12);
      
        autoTable(doc, {
          //cabecero
          didDrawPage: (data) => {
            data.doc.addImage(
              `/img/logo.png`,
              185, //izq
              6, //arri
              20, //anch
              20 //alto
            );
            data.doc.addImage(`/img/logo.png`, 10, 6, 20, 20);
            data.table.width = data.settings.tableWidth;
            
            data.doc.setFontSize(14);
            x = centrarTextoPDF(doc, master?.escuela?.nombre?.toUpperCase());
            data.doc.text(master?.escuela?.nombre?.toUpperCase(), x, 12);
            
            x = centrarTextoPDF(doc, 'PEDIDO DE EQUIPO');
            data.doc.text('PEDIDO DE EQUIPO', x, 17);

            x = centrarTextoPDF(doc, pedido?.no_pedido);
            data.doc.text(pedido?.no_pedido , x, 22);

            data.doc.setFontSize(9);
           /*  x = centrarTextoPDF(doc, `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`);
            data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, x, 27); */
          },
        });

        let total = 0;
        articulos?.forEach(art => {
            total = art.total + total;
        });
        
        autoTable(doc, {
          startY: 40,
          styles: {
            fontSize: 12,
          },
          head: [[
            'Articulo',
            'Pzas',
            'Talla',
            'Monto',
            
          ]],
          body: [ ...articulos?.map(data => {
            return [
              { content: data?.descripcion || '' },
              { content: data?.cantidad || ''  },
              { content: data?.talla?.descripcion || 'N/A' },
              { content: formatearCantidad(data?.total || 0) },
            ]
          }) ]
          /* [content: newData?.documento,] */,
        });

        autoTable(doc, {
            styles: {
              fontSize: 12,
            },
            body: [ 
              [
                { content: `Total: ${formatearCantidad(total)}`, styles: { halign: 'right' }  },
                
              ]
            ]
            /* [content: newData?.documento,] */,
        });

        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
            `${String(i)}/${pageCount}`,
            doc.internal.pageSize.width - 10,
            287,
            { align: "right" }
            );
        }

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setTextColor('gray');
            doc.text(
            `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`,
            10,
            287,
            { align: "left" }
            );
          }
      
        doc.save(`PEDIDO DE EQUIPO NO ${pedido?.no_pedido} ${XDate().toString("dd-MM-yyyy HH.mm.ss")}.pdf`);
    }

    return (
        <FloatButton 
            tooltip={<div>Imprimir Reporte PDF</div>} icon={<PrinterOutlined />} 
            onClick={exportarDocumento}
        />
    )
}

export default ButtonPDFArticulosPedido