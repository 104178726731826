import { PictureOutlined, PlusOutlined } from '@ant-design/icons';
import { faArrowDown, faArrowUp, faCarTunnel, faCartArrowDown, faCartFlatbed, faCartPlus, faMinus, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Avatar, Badge, Divider, Form, Input, Modal as ModalAnt, Select, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, ListGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { colorProductos, formatearCantidad, formatearFecha, URL_API, URL_IMG, URL_IMG_PRODUCTO, URL_IMG_USER } from '../../recursos';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { useArticulosStore } from '../../Hooks/Articulos/useArticulosStore';
import { btnFlotante, globalStyles } from '../../recursos/styles';
import { swalCenterInfo } from '../../recursos/Toast';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';

const ProductosCarrito = () => {

    const navigate = useNavigate();

    const [ productos, setProductos ] = useState([]);
    const [compra, setCompra] = useState(null);
    const [productosCompra, setProductosCompra] = useState([]);
    /* const [productosCompraDirecta, setProductosCompraDirecta] = useState([]); */
    const [directo, setDirecto] = useState([]);
    const [openCompraDirecta, setOpenCompraDirecta] = useState(false);
    const [talla, setTalla] = useState('');
    const [tallaId, setTallaId] = useState(null);
    

    const [index, setIndex] = useState(1);
    const [openPedidos, setOpenPedidos] = useState(false);
    const [formTalla, setFormTalla] = useState(false);

    const { student } = useStudentsStore();
    const { master } = useMaestrosStore();
    const { 
        articulosList, 
        loadingList,
        addAdeudoArticuloStore,
        addCompraStore,
        getArticulosListStore,
        pedidosList, 
        nuevaTallaArticuloStore, 
        getPedidosListStore
    } = useArticulosStore();
    const { configuracion } = useCourrentUserStore();

    const [total, setTotal] = useState(0);
    
    useEffect(() => {
        getArticulosListStore();
        getPedidosListStore();
    }, []);

    useEffect(() => {
        let productos = [];
        articulosList.forEach(articulo => {
            productos.push({
                id: articulo.id,
                descripcion: articulo.descripcion, 
                costo: articulo.costo,
                img: articulo.img,
                cantidad: 0,
                talla_id: null,
                tallas: articulo.tallas
            });
        });
        setProductos(productos);
        
    }, [articulosList]);

    useEffect(() => {
        if (compra) {
            if (productos.length > 0) {
                let articuloLocal = productos.find(data => data.id === compra.id);
                setCompra({
                    id: articuloLocal.id,
                    descripcion: articuloLocal.descripcion, 
                    costo: articuloLocal.costo,
                    img: articuloLocal.img,
                    cantidad: articuloLocal?.cantidad || 0,
                    talla_id: articuloLocal?.talla_id || null,
                    tallas: articuloLocal.tallas
                });
            }
            
        }
    }, [productos]);
    

    const styleImagen = {
        width: 60,
        height: 60,
        borderRadius: 100,
    };

    const styleNombre = {
        color: '#2F2F2F',
        fontSize: 15,
        fontWeight: '700',
        textTransform: 'uppercase',
        marginBottom: 2
    };

    const styleApellidos = {
        fontSize: 17,
        color: '#64748B',
        marginBottom: 2
    };

    const setCantidad = (producto, add) => {

        if (tallaId === null || tallaId === '') {
            swalCenterInfo('Por favor selecciona la talla del producto.');
            return; 
        }

        let productoExist = productosCompra?.filter(data => data?.id === producto?.id && data?.talla_id === tallaId);
        let productosListLocal = productosCompra;   
        let total = 0
        if (productoExist?.length === 0) {
            productosListLocal.push({...producto, cantidad: add, talla_id: tallaId});
            setCompra({...producto, cantidad: add});
            setProductosCompra(productosListLocal.map(data => {
                    total = total + (data.cantidad*data.costo);
                    return data;
            }));
        } else {

            setProductosCompra(
                productosCompra?.map(data => {
                    if (data.id === producto.id) {
                        if (data.talla_id === tallaId){
                            data.cantidad = data.cantidad + add;  
                        }
                        setCompra(data);
                    } 
                    total = total + (data.cantidad*data.costo);
                    if (data.cantidad > 0) {
                        return data;
                    }
                   
                })
            );
        }
        
        setTotal(total);
    }


    const addTalla = (e) => {
        e.preventDefault();
        
        if (talla !== '') {
            nuevaTallaArticuloStore({ descripcion: talla, articulo_id: compra.id });
            setTalla('');
            setFormTalla(false);
        }

    }

    const quitarArticulos = (prod) => {

        let list = productosCompra?.filter(data => (data !== prod ));
        let total = 0;

        list.forEach(data => {
            total = total + (data.cantidad*data.costo);    
        });
        setTotal(total);
        setProductosCompra(list);
    }

    const changeTalla = (talla_id) => {

        let newCompra = productosCompra.find(data => data.id === compra.id && data.talla_id === talla_id);
        setTallaId(talla_id);
        
        if (newCompra) {
            setCompra(newCompra);
        } else {
            setCompra({...compra, cantidad: 0, talla_id: talla_id});
        }
         
    }

    const selectCompra = (newData) => {
        if (newData) {
            let oldCompra = productosCompra.find(data => data.id === newData?.id && data.talla_id === 0);

            if (oldCompra) {
                setCompra(oldCompra);
            } else {
                setCompra({...newData, cantidad: 0, talla_id: '0'});
            }
        }
        
    };

    const infoPedidoConfirm = (pedido) => {
        setOpenCompraDirecta(false);
        ModalAnt.confirm({
          title: 'Confirmación de Compra',
          content: (
            <div>
              <p>Se agregaran los articulos al siguiente pedido <strong className='text-primary'>{pedido?.no_pedido}</strong>.</p>
              <p>Por favor confirme...</p>
            </div>
          ),
          centered: true,
          onCancel() {},
          onOk() {
            let array = [];
            productosCompra.forEach(articulo => {
                array.push({
                    id: articulo.id,
                    concepto_id: 2,
                    cantidad: articulo.cantidad,
                    alumno_id: student.id, 
                    talla_id: articulo?.talla_id || null,
                    pedido_id: pedido.id
                }, );
            });
            addCompraStore(JSON.stringify(array), student.id);
            navigate(-1);
          },
        });
    };

    const verificarExistencia = () => {
        
        let arrayDirecta = [];
        let arrayDirectaComp = [];
        productosCompra.forEach(articulo => {
            arrayDirecta.push({...articulo, a: 0});
        });

        arrayDirecta.forEach(old => {
            let disponible = old?.tallas?.find(data => data.id === old?.talla_id)?.stock;
            
            if (disponible !== null && disponible > 0) {
                if (disponible < old?.cantidad) {
                    old.cantidad = disponible;
                }
                arrayDirectaComp.push({...old, b: 0});
            }  
        });
        
        setDirecto(arrayDirectaComp);

        if (arrayDirectaComp?.length > 0) {
            setOpenCompraDirecta(true);
        } else {
            setOpenPedidos(true);
        }
    }

    const updateCantidad = (producto, add) => {
        
        let talla = producto?.tallas?.find(data => data?.id === producto?.talla_id);
        let stock = producto?.tallas?.find(data => data?.id === producto?.talla_id)?.stock;
        let registroOriginal = productosCompra?.find(data => data.id === producto.id);

        let newdirecto = directo.map(articulo => {
            let cantidad = articulo.cantidad + add;

            if (articulo.id === producto.id && talla.id === articulo.talla_id) {
                
                if (cantidad >= 0 && cantidad <= stock) {
                    if (cantidad <= registroOriginal.cantidad) {
                        articulo.cantidad = cantidad;
                    }                
                }
                
            }
            return articulo;
        });

        setDirecto(newdirecto);
    }

    const generarCompraArticulosExistentes = () => {
        
        ModalAnt.confirm({
            zIndex: 10000, 
            title: 'Confirmación de Compra',
            content: (
              <div>
                <p>Los articulos seleccionados se tomaran del stock existente.</p>
                <p>Por favor confirme...</p>
              </div>
            ),
            centered: true,
            onCancel() {},
            onOk() {
              let array = [];
              directo.forEach(articulo => {
                  array.push({
                      id: articulo.id,
                      concepto_id: 2,
                      cantidad: articulo.cantidad,
                      alumno_id: student.id, 
                      talla_id: articulo?.talla_id || null,
                      pedido_id: null
                  });
                  setTotal(total - (articulo.costo * articulo.cantidad));
              });
              addCompraStore(JSON.stringify(array), student.id);

              let updateCompras = productosCompra?.map(articulo => {
                  let articuloStock = directo?.find(data => data.id === articulo.id && data.talla_id === articulo.talla_id);
                  if (articuloStock) {
                      articulo.cantidad = articulo.cantidad - articuloStock.cantidad; 
                  }
                  return articulo;
              });
              updateCompras = updateCompras.filter(data => data.cantidad > 0);
              
              setOpenCompraDirecta(false);
              setDirecto([]);
              setProductosCompra(updateCompras);
              if (updateCompras?.length > 0) {
                  setOpenPedidos(true);
              } else {
                  navigate(-1);
              }
            },
          });
    
    }



    return (
        <>
        <div className="p-0 " style={{ marginLeft: 0, marginRight: 0 }}>
            <Spin spinning={false} tip="Cargando información, por favor Espere..." size="large" style={{ zIndex: 10 }}>
            <div style={{position: 'fixed', top: 0, zIndex: 10, width: '100%'}}>
                <div className='col-lg-12 p-0'>
                    <ButtonGroup style={{ width: '100%'  }}>
                        <Button 
                            className='text-end'
                            style={{ background: configuracion?.colorNavBar?.equipo, borderColor: configuracion?.colorNavBar?.equipo, borderRadius: 0 }}
                            onClick={() => navigate(-1)}
                        >
                            <FontAwesomeIcon icon={faTimes}/> Cerrar
                        </Button>
                    </ButtonGroup>
                </div>
                <div className='col-12 p-0'>
                    {/* CARD ALUMNO */}
                    <Card className='shadow-sm' style={{ borderRadius: 0 }}>
                        <Card.Body >
                            <div className='row'>
                                <div className='col-3' style={{ alignSelf: 'center' }}>
                                    <Avatar
                                        size={70}
                                        src={student.urlimg !== null ? `${URL_IMG}${student.token}/${student.urlimg}` : URL_IMG_USER }
                                    />
                                </div>
                                <div className='col-5' style={{ alignSelf: 'center' }}>
                                    <p style={{...styleNombre}}>
                                        {student.nombre}.
                                    </p>
                                    <p style={styleApellidos}>{student.apellido_paterno} {student.apellido_materno}.</p>
                                </div>
                                <div className='col-4 text-center' style={{ alignSelf: 'center' }}>
                                    <strong>{formatearCantidad(total) }</strong>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div style={{ marginTop: '11em' }}></div>
            {/* LISTA DE PRODUCTOS */}
            {index === 1 && productos?.map((producto, index) => {
                return (
                <div className='col-lg-12 p-0 animated fadeIn' key={index}>
                
                        <Card className='shadow-sm' style={{ borderRadius: 0 }} onClick={() => selectCompra(producto)}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-6' style={{ alignSelf:'center' }}>
                                        <p style={{...styleNombre}}>
                                            {producto.descripcion}.
                                        </p>
                                    </div>
                                    {producto.img ? (
                                    <div className='col-3' style={{ alignSelf:'center' }}>
                                        <Avatar
                                            className='shadow-sm'
                                            shape="square"
                                            size={70}
                                            src={`${URL_API}/img/maestros/${master.token_file}/articulos/${producto.img}`}
                                        />
                                    </div>
                                    ) : (
                                        <div className='col-3' style={{ alignSelf: 'center'  }}>
                                            <Avatar
                                                className='shadow-sm'
                                                shape="square"
                                                icon={<PictureOutlined/>}
                                                size={70}
                                            />
                                        </div>
                                    )}
                                    <div className='col-3' style={{ alignSelf:'center' }}>
                                    <p style={{...styleApellidos, fontSize: 14}}>{formatearCantidad(producto.costo)}</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    
                </div>
                )
            })}

            {/* LISTA DE ARTICULOS AGREGADOS */}
            {index === 2 && (
                productosCompra?.map(producto => {
                    return (
                    <div className='col-lg-12 p-0 animated fadeIn' key={index}>
                    
                        <Card className='shadow-sm' key={index} style={{ borderRadius: 0 }} >
                            <Card.Body>
                                <div className='row'>
                                    {producto?.img ? (
                                    <div className='col-3'>
                                        <img
                                            style={{...styleImagen, borderRadius: 5}}
                                            src={`${URL_API}/img/maestros/${master?.token_file}/articulos/${producto?.img}`}
                                        />
                                    </div>
                                    ) : (
                                        <div className='col-3' style={{ alignSelf: 'center'  }}>
                                            <PictureOutlined style={{ fontSize: '50px' }}/>
                                        </div>
                                    )}
                                    <div className='col-5'>
                                        <p style={{...styleNombre, color: '#496491', fontSize: 12}}>
                                            {producto?.descripcion}.
                                        </p>
                                        <p style={{...styleApellidos}}>{formatearCantidad(producto?.costo || 0)}</p>
                                        <p style={styleApellidos}>
                                            <strong>Talla:</strong> {`${producto?.tallas?.find(data => data.id === producto?.talla_id)?.descripcion} (${producto?.tallas?.find(data => data.id === producto?.talla_id)?.stock || 0})` || 'N/A'}
                                        </p>
                                    </div>
                                    <div className='col-2 text-center' style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <Button disabled variant='light'> <h6>pzas</h6><h2>{producto?.cantidad}</h2></Button>
                                    </div>
                                    <div className='col-2 text-center' style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <Button variant='danger' size='sm' onClick={() => quitarArticulos(producto)}> <FontAwesomeIcon icon={faTrash}/> </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    )
                })
                
            )}
            </Spin>

            {(index === 2 && productosCompra?.length > 0) && (
            <Button 
                style={{...globalStyles.btnAddProductos, bottom: 80, borderRadius: 0, width: 'auto', height: 'auto', background: `${configuracion?.colorNavBar?.equipo}`}}
                onClick={() => {verificarExistencia()/* setOpenPedidos(true) */}} 
            >
                <FontAwesomeIcon icon={faPlus} /> Agregar Compra
            </Button>
            )}

            <div style={{ marginBottom: '5em' }}></div>
            <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0, width: '100%', zIndex: 10 }}>
                    
                <Button
                    size='large'
                    style={{ borderRadius: 0, background:  index === 1 ? `${configuracion?.colorNavBar?.equipo}ab` : configuracion?.colorNavBar?.equipo, borderColor: configuracion?.colorNavBar?.equipo }}
                    onClick={() => setIndex(1)}
                >
                    <FontAwesomeIcon icon={faCartFlatbed}/> <br></br>Articulos
                </Button>
                
                <Button
                    size='large'
                    style={{ borderRadius: 0, background: index === 2 ? `${configuracion?.colorNavBar?.equipo}ab` : configuracion?.colorNavBar?.equipo, borderColor: configuracion?.colorNavBar?.equipo }}
                    onClick={() => setIndex(2)}
                >
                    <FontAwesomeIcon icon={faCartPlus}/> <br></br>Carrito   
                </Button>
                
            </ButtonGroup>
        </div>

        {/* MODAL AGREGAR */}
        <ModalAnt 
            open={compra ? true : false}
            onCancel={() => { setCompra(null); setTallaId(null) }} 
            
            footer={null}
        >
            <div className='row'>
                {compra?.img ? (
                <div className='col-lg-8 text-center mb-1'>
                    <Avatar
                        className='shadow-sm'
                        shape="square"
                        size={70}
                        src={`${URL_IMG_PRODUCTO}${master.token_file}/articulos/${compra?.img}`}
                    />
                </div>
                ) : (
                    <div className='col-lg-8 text-center mb-1'>
                        <Avatar
                            className='shadow-sm'
                            shape="square"
                            icon={<PictureOutlined/>}
                            size={70}
                                                
                        />
                    </div>
                )}
                
                <div className='col-lg-12 text-center'>
                    <p style={{...styleNombre, color: student?.estatus === 1 ? '#496491' : 'gray'}}>
                        {compra?.descripcion}.
                    </p>
                    <p style={styleApellidos}>{formatearCantidad(compra?.costo || 0)}</p>
                    <Divider></Divider>
                </div>
                
                <div className='col-lg-12 text-center'>
                    <ButtonGroup>
                        <Button variant='warning' onClick={() => setCantidad(compra, -1)} disabled={compra?.cantidad === 0}>
                            <FontAwesomeIcon icon={faMinus}/> 
                        </Button>
                        <Button disabled variant='light'> <strong>{compra?.cantidad}</strong></Button>
                        <Button variant='success' onClick={() => setCantidad(compra, 1)}><FontAwesomeIcon icon={faPlus}/></Button>
                    </ButtonGroup>
                </div>
                <div className='col-12 text-center mt-3'>
                    <p>
                    <Divider>Seleccione una talla</Divider>
                    {/* <Button
                        style={{ marginRight: 5, borderRadius: 0, marginBottom:5 }}
                        variant={tallaId === 0 ? 'secondary' : 'outline-secondary'}
                        onClick={() => changeTalla(0)}
                    >
                        N/A
                    </Button> */}
                    {compra?.tallas?.map((item) => (
                        
                        <Button 
                            key={item.id}
                            style={{ marginRight: 5, borderRadius: 0, marginBottom:5, alignSelf: 'center' }}
                            variant={item.id === tallaId ? 'secondary' : 'outline-secondary'}
                            onClick={() => changeTalla(item.id)}
                        >
                            {item?.descripcion}
                            {' '}
                            <strong className={Number(item?.stock) === 0 ? 'text-danger' : 'text-primary'}>{'('}{item?.stock || 0}{')'}</strong>
                        </Button>
                    ))}
                    <Button
                        style={{ marginRight: 5, borderRadius: 0, marginBottom:5 }}
                        variant={'primary'}
                        onClick={() => setFormTalla(true)}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </Button>
                    </p>
                    <p hidden={!formTalla}>
                    <Divider>Agregar una talla nueva</Divider>
                    <Space
                        align="center"
                        style={{
                        padding: '0 8px 4px',
                        }}
                    >
                        <Input 
                            placeholder="Ingresa la talla" 
                            value={talla} 
                            onChange={(e) => setTalla(e.target.value)} 
                        />
                        <Typography.Link
                            onClick={addTalla}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <PlusOutlined /> Agregar
                        </Typography.Link>
                    </Space>
                    </p>
                    
                </div>
            </div>
        </ModalAnt>

        {/* MODAL PEDIDOS */}
        <ModalAnt 
            open={openPedidos}
            onCancel={() => { setOpenPedidos(false); }}
            footer={null}
        >
            <div className='row'>
                <div className='col-lg-12 text-center mt-3'>
                    <p>Seleccione el pedido al que decea agregar los articulos.</p>
                    <Divider>PEDIDOS</Divider>
                </div>
                {pedidosList?.map((data, index) => {
                    if (data.estatus === 'ABIERTO')
                    return (
                        <div className='col-lg-12 d-grid gap-2 mb-1' key={index}>
                            <Button
                                disabled={loadingList} 
                                variant='light' 
                                className='text-start shadow' 
                                style={{ borderRadius: 0 }}
                                onClick={() => infoPedidoConfirm(data)}
                            >
                                <p style={{...globalStyles.nombre}}>
                                    <strong> NO. PEDIDO: </strong> {data.no_pedido}
                                </p>
                                <p style={{...globalStyles.nombre}}>
                                    <strong>ESTATUS: </strong> <span className={data?.estatus === 'ABIERTO' ? 'text-success' : 'text-danger'}>{data.estatus}</span>
                                </p>
                                <p className='text-primary' style={{...globalStyles.nombre}}>
                                    <strong>NO. DE ARTICULOS : </strong> {data?.compras?.length}
                                </p>
                                <p  style={{...globalStyles.nombre}}>
                                    <strong className='text-muted'>FECHA DE REGISTRO : </strong> <span>{formatearFecha(data?.created_at)}</span>
                                </p>
                            </Button>
                        </div>
                    )
                })}
            </div>
        </ModalAnt>

        {/* MODAL COMPRA DIRECTA */}
        <Modal
            show={openCompraDirecta}
            onHide={() => setOpenCompraDirecta(false)}
            fullscreen
        >
            <Modal.Header closeButton>
                <strong>Productos en existencia</strong>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='col-12'>
                    <Alert
                        style={{ borderRadius: 0 }}
                        description='Actualmente se encuentran en existencia los siguientes articulos, ¿decea tomarlos del stock existente?, el producto restante se registrara en el pedido.'
                    />
                </div>
                {directo?.map(producto => {
                    return (
                    <Card style={{ borderRadius: 0 }} key={producto.id}>
                        <Card.Body >
                           
                            <div className='row'>
                                
                                {producto?.img ? (
                                <div className='col-3'>
                                    <img
                                        style={{...styleImagen, borderRadius: 5}}
                                        src={`${URL_API}/img/maestros/${master?.token_file}/articulos/${producto?.img}`}
                                    />
                                </div>
                                ) : (
                                    <div className='col-3' style={{ alignSelf: 'center'  }}>
                                        <PictureOutlined style={{ fontSize: '50px' }}/>
                                    </div>
                                )}
                                <div className='col-5'>
                                    <p style={{...styleNombre, color: '#496491', fontSize: 12}}>
                                        {producto?.descripcion}.
                                    </p>
                                    <p style={{...styleApellidos}}>{formatearCantidad(producto?.costo || 0)}</p>
                                    <p style={styleApellidos}>
                                        <strong>Talla:</strong> {`${producto?.tallas?.find(data => data.id === producto?.talla_id)?.descripcion} (${producto?.tallas?.find(data => data.id === producto?.talla_id)?.stock || 0})` || 'N/A'}
                                    </p>
                                </div>
                                <div className='col-4 text-center' style={{ alignItems: 'center', alignSelf: 'center' }}>
                                    <ButtonGroup>
                                    
                                        <Button size='sm' disabled variant='light'><h2 className='text-primary'>{producto?.cantidad}</h2></Button>
                                        <Button 
                                            size='sm'  
                                            variant='light'
                                            onClick={() => updateCantidad(producto, -1)}
                                        >
                                            <FontAwesomeIcon icon={faMinus}/>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                            
                        </Card.Body>
                    </Card>
                    )
                })}
                {openCompraDirecta && (
                <>
                <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0, width: '100%', zIndex: 10 }}>
                        
                        <Button
                            size='large'
                            style={{ borderRadius: 0, background: 'blue' }}
                            onClick={() => generarCompraArticulosExistentes()}
                        >
                            Compra de Articulos Existentes
                        </Button>
                        
                        <Button
                            size='large'
                            style={{ borderRadius: 0, background: 'orange', borderColor: 'orange' }}
                            onClick={() => { setOpenCompraDirecta(false); setOpenPedidos(true);}}
                        >
                            Agregar Articulos a Pedido   
                        </Button>
                        
                    </ButtonGroup>
                </>
                )}
            </Modal.Body>
        </Modal>
        </>
    )
}

export default ProductosCarrito;