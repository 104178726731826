import React from 'react'
import { useState, useEffect } from 'react';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { SocketContext } from '../../context/SocketContext';
import { Button } from 'react-bootstrap';

const TableroGlobal = () => {

    const [Rojo, setRojo] = useState(0);
    const [Negro, setNegro] = useState(0);

    const params = useParams();
    //const { socket } = useContext( SocketContext );

/*     useEffect(() => {

        socket.on('edit-tablero-usuario', ({token, rojo, negro}) => {
            if ( params?.token === token ) {
                setRojo(rojo);
                setNegro(negro);
            }
        });
        
        
        return () => socket.off('edit-tablero-usuario');
    }, [ socket ]);
 */
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div 
                    className='col-6 p-0 mb-1 bg-danger text-center text-white' 
                    style={{ height: window.screen.height, fontSize: 140 }}
                >
                    <Button variant='danger' style={{ width: '100%', height: '100%', fontSize: 200 }}>
                        <strong>{Rojo}</strong>
                    </Button>
                </div>
                <div
                    className='col-6 p-0 mb-1 bg-dark text-center text-white' 
                    style={{ height: window.screen.height, fontSize: 140 }}
                >
                    <Button variant='dark' style={{ width: '100%', height: '100%', fontSize: 200 }}>
                        <strong>{Negro}</strong>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TableroGlobal