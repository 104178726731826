import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    master: null, 
    loading: false,
    error: false,
    graficaEstilo: 2,
    selEst: 0,
    saldos: {
        concepto: {
            mensualidad: 0,
            equipo: 0,
            eventos: 0
        },
        historialConcepto: {
            mensualidad: [],
            equipo: [],
            eventos: []
        }
    }
};

export const MasterSlice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        setSaldos: (state, { payload }) => {
            state.saldos = payload;
        },
        setMaster: (state, { payload }) => {
            state.master = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setEstiloGrafica: (state, { payload }) => {
            state.graficaEstilo = payload;
        },
        setSelEst: (state, { payload }) => {
            state.selEst = payload;
        },
        reset: (state) => {
            state.master =  null;
            state.loading =  false;
            state.error =  false;
            state.graficaEstilo =  2;
            state.selEst =  0;
            state.saldos =  {
                concepto: {
                    mensualidad: 0,
                    equipo: 0,
                    eventos: 0
                },
                historialConcepto: {
                    mensualidad: [],
                    equipo: [],
                    eventos: []
                }
            };
        },
    }
});

export const {
    setSaldos,
    setMaster,
    setLoading,
    setError,
    setEstiloGrafica,
    setSelEst,
    reset,
} = MasterSlice.actions;