import { faSave, faTimes, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { colorEventos } from '../../recursos';
import { globalStyles as css } from '../../recursos/styles';
import { swalCenterWarning } from '../../recursos/Toast';
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore';
import { useCatalogosStore } from '../../Hooks/Catalogos/useCatalogosStore';
import { FloatButton } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';

const EventosEdit = () => {

    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [date, setDate] = useState('')
    const [tipoEvento, setTipoEvento] = useState(1);
    const [descripcion, setDescripcion] = useState('');
    const [costo, setCosto] = useState('');
    const [noCategorias, setNoCategorias] = useState('');
    const [costoCategoriasEx, setCostoCategoriasEx] = useState('');

    const { tipoEventosList, getTipoEventosStore } = useCatalogosStore();;
    const { evento, createEventoStore, setEventoStore } = useEventosStore();
    const { configuracion } = useCourrentUserStore();

    useEffect(() => {
        if (tipoEventosList.length === 0) {
            getTipoEventosStore();
        }

        if (evento !== null) {
            setId(evento.id);
            setDate(evento.fecha_evento);
            setTipoEvento(evento.tipo_evento_id);
            setDescripcion(evento.descripcion);
            setCosto(evento.costo);
            setNoCategorias(evento.no_categorias);
            setCostoCategoriasEx(evento.extra ? evento.extra.monto : '');
        }
    
      return () => {
        setEventoStore(null);
      }
    }, []);
    

    const isValidate = () => {
        
        switch (tipoEvento) {
            case 1:
                if(descripcion.trim() === '' 
                    || costo === ''
                    || noCategorias === '' 
                    || costoCategoriasEx === ''
                ) 
                    return false;
                else 
                    return true;
            case 2:
                if(descripcion.trim() === '' 
                    || costo === '' 
                    || noCategorias === '' 
                    || costoCategoriasEx === ''
                )
                    return false;
                 else 
                    return true 
            case 3:     
                if (descripcion.trim() === '')
                    return false;
                else 
                    return true;  
            case 4:
                if(descripcion.trim() === '' 
                    || costo === '' 
                )
                    return false;
                 else 
                    return true
            case 5:
                if(descripcion.trim() === '' 
                    || costo === ''
                )
                    return false;
                 else 
                    return true  
            case 6:
                if(descripcion.trim() === ''
                )
                    return false;
                else 
                    return true;
            case 7:
                if(descripcion.trim() === '' 
                    || costo === ''
                ) 
                    return false;
                else 
                    return true;
            default:
                return false;
        }

    }

    const guardarDatos = () => {
        let fecha = date;
        
        if (isValidate()) {
            createEventoStore({
                id: id,
                tipo_evento_id: tipoEvento,
                descripcion: descripcion,
                costo: costo,
                no_categorias: noCategorias,
                categoria_extra: costoCategoriasEx,
                fecha_evento: fecha
            });
            navigate(-1);
        } else {
            swalCenterWarning('Por favor ingrese todos los datos.');
        }
    }

    return (
        <Modal
            onHide={() => {
                navigate(-1);
            }}
            show
            fullscreen
            scrollable
        >
            <Modal.Body className='p-0' style={{ zIndex: 19 }}>
                <div className='col-lg-12 p-0' style={css.encabezadoModalTop}>
                    <ButtonGroup style={{ width: '100%'  }}>
                        <Button 
                            className='text-start'
                            style={{ background: configuracion?.colorNavBar?.eventos,  borderRadius: 0, borderColor: configuracion?.colorNavBar?.eventos }}
                        >
                            <FontAwesomeIcon icon={faTrophy}/> {id !== '' ? 'Actualizar' : 'Registrar'} Evento
                        </Button>
                        <Button 
                            className='text-end'
                            style={{ background: configuracion?.colorNavBar?.eventos,  borderRadius: 0, borderColor: configuracion?.colorNavBar?.eventos }}
                            onClick={() => navigate(-1)}
                        >
                            <FontAwesomeIcon icon={faTimes}  /> Cerrar
                        </Button>
                    </ButtonGroup>
                </div>
                <div className='row ' style={{ marginTop: '3em', marginRight: 3, marginLeft: 3}}>
                    <div className='col-lg-12 form-group'>
                        <strong className="form-label">Tipo de Evento:</strong>
                        <select 
                            className="form-control" 
                            onChange={e => setTipoEvento(Number(e.target.value))}
                            value={tipoEvento}
                        >
                            <option value="">Seleccione una opción</option>
                            {tipoEventosList.map((tipo, index) => (
                                <option key={index} value={tipo.id}>{tipo.descripcion}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-lg-12 mt-3 form-group'>
                        <strong className="form-label">Fecha del Evento:</strong>
                        <input 
                            type="date" 
                            className="form-control"
                            onChange={e => setDate(e.target.value)}
                            value={date}
                        />
                    </div>
                    <div className='col-lg-12 mt-3 form-group'>
                        <strong className="form-label">Descripción del Evento:</strong>
                        <input 
                            type="text" 
                            className="form-control"
                            placeholder='Ingrese la descripción evento.'
                            onChange={e => setDescripcion(e.target.value)}
                            value={descripcion}
                        />
                    </div>
                    {(tipoEvento !== 3) && (
                    <div className='col-lg-12 mt-3 form-group'>
                        <strong className="form-label">Costo del Evento:</strong>
                        <input 
                            type="number" 
                            className="form-control"
                            placeholder='Ingrese el costo del evento.'
                            onChange={e => setCosto(e.target.value)}
                            value={costo}
                        />
                    </div>
                    )}
                    {(tipoEvento === 1 || tipoEvento === 2 || tipoEvento === 5) && (
                    <div className='col-lg-12 mt-3 form-group'>
                        <strong className="form-label">Número de Categorias:</strong>
                        <input 
                            type="number" 
                            className="form-control"
                            placeholder='Ingresa número de categorias que incluye el pago.'
                            onChange={e => setNoCategorias(e.target.value)}
                            value={noCategorias}
                        />
                    </div>
                    )}
                    {(tipoEvento === 1 || tipoEvento === 2 || tipoEvento === 5) && (
                    <div className='col-lg-12 mt-3 form-group'>
                       <strong className="form-label">Costo de categorias extra</strong>
                       <input 
                           type="number" 
                           className="form-control"
                           placeholder='Ingresa el costo de la categoria extra.'
                           onChange={e => setCostoCategoriasEx(e.target.value)}
                           value={costoCategoriasEx}
                       />
                   </div> 
                   )}
                    
                </div>
                <FloatButton
                    type="primary"
                    onClick={guardarDatos}
                    tooltip='Nuevo Evento'
                    style={{
                        right: 20,
                        bottom: 30,
                        opacity: 0.8,
                    }}
                    icon={<SaveOutlined />}
                />
            </Modal.Body>
                    
        </Modal>
    )
}

export default EventosEdit