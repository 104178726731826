import { faCartPlus, faDashboard, faDollar, faHome, faRefresh, faSave, faShoppingCart, faTrophy, faUserCircle, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Container, Form, Navbar } from 'react-bootstrap'
import { colorAlumnos, colorEventos, colorProductos, colorSaldos } from '../../recursos'
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore'
import { useState } from 'react'
import { Divider } from 'antd'
import { useEffect } from 'react'
import { swalCenterSuccess } from '../../recursos/Toast'

const ConfiguracionColores = () => {

    const { configuracion, setConfiguracionStore, restablecerValoresNavBar } = useCourrentUserStore();
    const [Inicio, setInicio] = useState('');
    const [Alumnos, setAlumnos] = useState('');
    const [Maestro, setMaestro] = useState('');
    const [Equipo, setEquipo] = useState('');
    const [Saldos, setSaldos] = useState('');
    const [Eventos, setEventos] = useState('');
    const [Tablero, setTablero] = useState('');

    useEffect(() => {
        setInicio(configuracion?.colorNavBar?.inicio);
        setAlumnos(configuracion?.colorNavBar?.alumnos);
        setMaestro(configuracion?.colorNavBar?.maestro);
        setEquipo(configuracion?.colorNavBar?.equipo);
        setSaldos(configuracion?.colorNavBar?.saldos);
        setEventos(configuracion?.colorNavBar?.eventos);
        setTablero(configuracion?.colorNavBar?.tablero);
    }, [configuracion]);

    const guardarConfiguracion = () => {
        setConfiguracionStore({
            ...configuracion,
            colorNavBar: {
                ...configuracion?.colorNavBar,
                inicio: Inicio,
                alumnos: Alumnos,
                maestro: Maestro,
                equipo: Equipo,
                saldos: Saldos,
                eventos: Eventos,
                tablero: Tablero
            }
        });
        swalCenterSuccess('Se guardo la configuración de colores.');
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-9 mt-3 mb-2'>
                    <Navbar style={{ background: Inicio }}>
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faHome}/>
                            {' '}
                            Inicio
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Inicio}
                        value={Inicio}
                        onChange={e => setInicio( e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Alumnos }}>
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faUserGroup}/>
                            {' '}
                            Alumnos
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Alumnos}
                        value={Alumnos}
                        onChange={e => setAlumnos( e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Maestro }} >
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faUserCircle}/>
                            {' '}
                            Maestro
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Maestro}
                        value={Maestro}
                        onChange={e => setMaestro( e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Equipo }} >
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faShoppingCart}/>
                            {' '}
                            Equipo
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Equipo}
                        value={Equipo}
                        onChange={e => setEquipo(e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Saldos }} >
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faDollar}/>
                            {' '}
                            Saldos
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Saldos}
                        value={Saldos}
                        onChange={e => setSaldos(e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Eventos }} >
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faTrophy}/>
                            {' '}
                            Eventos
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Eventos}
                        value={Eventos}
                        onChange={e => setEventos( e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-9 mb-2'>
                    <Navbar style={{ background: Tablero }} >
                        <Container>
                        <Navbar.Brand className='text-white'>
                            <FontAwesomeIcon icon={faDashboard}/>
                            {' '}
                            Tablero Puntos
                        </Navbar.Brand>
                        </Container>
                    </Navbar>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                    <Form.Control
                        type="color"
                        defaultValue={Tablero}
                        value={Tablero}
                        onChange={e => setTablero( e.target.value)}
                    />
                </div>
            </div>
            <div className='row'>
                <div>
                    <Divider/>
                </div>
                <div className='col-12 text-end'>
                    <Button variant='secondary' style={{ marginRight: 10 }} onClick={() => restablecerValoresNavBar()}>
                        <FontAwesomeIcon icon={faRefresh}/> Restablecer
                    </Button>
                    <Button variant='primary' onClick={guardarConfiguracion}>
                        <FontAwesomeIcon icon={faSave}/> Guardar
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ConfiguracionColores