import { faDownload, faQrcode, faShare, faUserPlus, faUserXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'antd'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalInfoInformacionPagos = ({ open, setOpen }) => {
  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      fullscreen

    >
      <Modal.Header closeButton className='bg-primary text-white'>
        <strong>INFORMACIÓN</strong>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12 mb-2'>
            <Card
              className='shadow-sm'
              title={'Alta y baja del alumno'}
            >
              <p>El boton con el icono <FontAwesomeIcon icon={faUserXmark}/> permitira dar de baja al alumno.</p>
              <p>El boton con el icono <FontAwesomeIcon icon={faUserPlus}/> permitira dar de alta nuevamente al alumno.</p>
            </Card>
          </div>
          <div className='col-12 mb-2'>
            <Card
              className='shadow-sm'
              title={'Código QR'}
            >
              <p>El código QR <FontAwesomeIcon icon={faQrcode}/> permitira al alumno ver su información general, recibos, adeudos, y su historial en participacion de torneos, seminarios, entre otros a los que se haya inscrito.</p>
            </Card>
          </div>
          <div className='col-12 mb-2'>
            <Card
              className='shadow-sm'
              title={'Descargar QR'}
            >
              <p>El botón con el icono <FontAwesomeIcon icon={faDownload}/> le permitira descargar el codigo QR y compartirlo.</p>            
            </Card>
          </div>
          <div className='col-12 mb-2'>
            <Card
              className='shadow-sm'
              title={'Compartir link QR'}
            >
              <p>El botón con el icono <FontAwesomeIcon icon={faShare}/> le permitira copiar el link en vez del QR que podra compartir al alumno.</p>        
            </Card>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInfoInformacionPagos