
import { useDispatch, useSelector } from 'react-redux';
import { 
    setEvento,
    setEventoAlumnos,
    setEventosList,
    setEventoNotificaciones,
    reset
} from '../../store/EventosSlice';
import { cancelarParticipacionService, createEventoService, editParticipacionService, getEventoAlumnosService, getEventosListService, getEventosNotificacionesService } from '../../Services/EventosServices';
import { swalCenterSuccess, swalCenterWarning } from '../../recursos/Toast';
import { warningToastCenter } from '../../recursos/Toast';
import { dangerToast } from '../../recursos/Toast';
import { getDatosAlumnoService } from '../../Services/StudentsServices';
import { setStudents } from '../../redux/actions/Student.actions';

export const useEventosStore = () => {
  
    const dispatch = useDispatch();

    const { 
        eventosList,
        loadingList,
        eventoAlumnos,
        eventoNotificaciones,
        evento
    } = useSelector(store => store.Eventos);
    
    const { 
        studentsList
    } = useSelector(store => store.Student);
    
    const {
        courrentUser
    } = useSelector(store => store.CourrentUser);

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    //const { getStudentsListStore } = useStudentsStore();

    const setEventoStore = (data) => {
        dispatch( setEvento(data) );
    }

    const setEventoAlumnosStore = (data) => {
        dispatch( setEventoAlumnos(data) );
    }

    const setEventosListStore = (data) => {
        dispatch( setEventosList(data) );
    }

    const setEventoNotificacionesStore = (data) => {
        dispatch( setEventoNotificaciones(data) );
    }

    const resetEventosStore = () => {
        dispatch( reset() );
    }

    const createEventoStore = (params) => {
        
        if (!navigator.onLine) {
            return ;
        }

        createEventoService(params,token)
        .then( response => {
            if (response?.status === 200) {
                swalCenterSuccess(response?.data?.message);
                getEventosListStore();
                getEventosNotificacionesStore();
            } else {
                swalCenterWarning('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            swalCenterWarning('Algo ocurrio, intente de nuevo');
        });
    };

    const getEventoAlumnosStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }
        
        getEventoAlumnosService(params,token)
        .then( response => {
            if (response?.status === 200) {
                setEventoAlumnosStore(response?.data);
                getEventosNotificacionesStore();
            } else {
                swalCenterWarning('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
           
        }).catch( error => {
            swalCenterWarning('Algo ocurrio, intente de nuevo');
        });

    }

    const getDatosAlumnoStore = (id) => {

        if (!navigator.onLine) {
            return ;
        }
        //setLoadingStore(true);
        getDatosAlumnoService(id, token).then( response => {
            if (response?.status === 200) {

                let datoAlumno = response?.data;
                dispatch (
                    setStudents(
                        studentsList.map(alumno => {
                            if (alumno.id === datoAlumno.id) {
                                alumno = datoAlumno;
                            }
                            return alumno;
                        })
                    ))
                ;

            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta: '+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos.');
        }); 
    };

    const editParticipacionStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        editParticipacionService(params,token)
        .then( response => {
            if (response?.status === 200) {
                swalCenterSuccess(response?.data?.message);
                getEventoAlumnosStore({ evento_id: params?.evento_id }, token);
                getDatosAlumnoStore(params?.alumno_id);
                getEventosNotificacionesStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Algo ocurrio, intente de nuevo');
        });

    }

    const getEventosListStore = () => {

        if (!navigator.onLine) {
            return ;
        }
        
        getEventosListService(token)
        .then( response => {
            if (response.status === 200) {
                setEventosListStore(response.data);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
           
        }).catch( error => {
            dangerToast('Algo ocurrio, intente de nuevo');
        }).finally(finallly => {
            //setLoading(false);
        }); 
        
    };

    const getEventosNotificacionesStore = () => {

        if (!navigator.onLine) {
            return ;
        }
        
        getEventosNotificacionesService(token)
        .then( response => {
            if (response.status === 200) {
                dispatch( setEventoNotificaciones(response.data) );
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
           
        }).catch( error => {
            dangerToast('Algo ocurrio al cargar las notificaciones, intente de nuevo');
        }).finally(finallly => {
            //setLoading(false);
        }); 
    }

    const cancelarParticipacionStore = (params) => {
        if (!navigator.onLine) {
            return ;
        }
        
        cancelarParticipacionService(params, token)
        .then(response => {

            swalCenterSuccess(response?.data?.message);
            getEventoAlumnosStore({ evento_id: params?.evento_id }, token);
            getDatosAlumnoStore(params?.alumno_id);
            getEventosNotificacionesStore();

        })
        .catch(error => {
            dangerToast('Algo ocurrio al cargar las notificaciones, intente de nuevo');
        });
    }


    return {
        /* parametros */
        eventosList,
        loadingList,
        eventoAlumnos,
        eventoNotificaciones,
        evento,
        /* funciones */
        setEventoStore,
        setEventoAlumnosStore,
        setEventosListStore,
        setEventoNotificacionesStore,
        resetEventosStore,
        createEventoStore,
        getEventoAlumnosStore,
        editParticipacionStore,
        getEventosListStore,
        getEventosNotificacionesStore,
        cancelarParticipacionStore
    }
}