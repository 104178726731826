import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    studentsList: [], 
    student: null,
    loading: false,
    error: false, 
    alumnosFiltroList: [],
    filtro: {
        mensualidad: false,
        equipo: false,
        eventos: false,
        nombre: '',
        grado: null,
        evento: ''
    }
};

export const StudentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setStudents: (state, { payload }) => {
            state.studentsList = payload;
        },
        setStudent: (state, { payload }) => {
            state.student = payload;
        } ,
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setAlumnosFiltroList: (state, { payload }) => {
            state.alumnosFiltroList = payload;
        },
        setFiltro: (state, { payload }) => {
            state.filtro = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        reset: (state) => {
            state.studentsList = [];
            state.student = null;
            state.loading = false;
            state.error = false;
        },
    }
});

export const {
    setStudents,
    setStudent,
    setLoading,
    setError,
    reset,
    setAlumnosFiltroList,
    setFiltro
} = StudentSlice.actions;