import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gradosList: [],
    estadosList: [],
    municipiosList: [],
    tipoEventosList: []
};

export const CatalogosSlice = createSlice({
    name: 'catalogos',
    initialState,
    reducers: {
        setTipoEventosList: (state, { payload }) => {
            state.tipoEventosList = payload;
        },
        setGradosList: (state, { payload }) => {
            state.gradosList = payload;
        },
        setEstadosList: (state, { payload }) => {
            state.estadosList = payload;
        },
        setMunicipiosList: (state, { payload }) => {
            state.municipiosList = payload;
        },
        reset: (state) => {
            state.gradosList = [];
            state.estadosList = [];
            state.municipiosList = [];
            state.tipoEventosList = [];
        }
    }
});

export const {
    setTipoEventosList,
    setGradosList,
    setEstadosList,
    setMunicipiosList,
    reset
} = CatalogosSlice.actions;