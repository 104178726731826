import { faAddressCard, faArrowLeft, faCircleDollarToSlot, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Avatar, Badge, Form, Upload, Button, Spin, Watermark } from 'antd'
import React, { useEffect } from 'react'
import { Button as ButtonBts, Card, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { URL_IMG, URL_IMG_USER, colorEventos } from '../../recursos'
import { useNavigate } from 'react-router-dom'
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore'

import { 
    DollarCircleFilled,
    ShopOutlined,
    UserOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { useState } from 'react'
import AlumnoGeneralQR from './AlumnoQRInfo/AlumnoGeneralQR'
import PagosPendientesQR from './AlumnoQRInfo/PagosPendientesQR'
import RecibosQR from './AlumnoQRInfo/RecibosQR'
import { useReSizeWindow } from '../../Hooks/useReSizeWindow';
import { globalStyles } from '../../recursos/styles'
import { swalCenterInfo, swalCenterWarning } from '../../recursos/Toast'
import QrScanner from 'qr-scanner';

const { Content, Footer, Sider } = Layout;



const AlumnoInfoQR = () => {

    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const { screenIsOnLandscape } = useReSizeWindow();

    const { student, getAlumnoQRStore, setStudentStore, loading } = useStudentsStore();

    const [index, setIndex] = useState(null);
    const [indexMobile, setIndexMobile] = useState('1');
    const [expand, setExpand] = useState(false);
    const [img, setImg] = useState(null);
    //const [resultQR, setResultQR] = useState(null);

    const onFinish = () => {
        const file = img[0].originFileObj;
        if (!file) {
            return;
        }
        QrScanner.scanImage(file, { returnDetailedScanResult: true })
            .then(result => {
                let array = result?.data?.split('-');
                getAlumnoQRStore(array[array.length - 1]);
            })
            .catch(e => swalCenterWarning('La imagen cargada no es un código QR'));
    }

    const normFilePortada = (e) => {
      
        if (Array.isArray(e)) {
          return ;
        }
  
        const isPNG = e.file.type === 'image/png';
        const isJPG = e.file.type === 'image/jpeg';
        if (!isPNG && !isJPG) {
            setImg([]);
        } else {
            setImg(e.fileList);
        }
        return e && e.fileList;
    };

    const beforeUpload = file => {
        const isPNG = file.type === 'image/png';
        const isJPG = file.type === 'image/jpeg'
        const valida = false;
        if (!isPNG && !isJPG) {
            swalCenterInfo(`${file.name} no es una imagen permitida.`);
            //message.error(`${file.name} no es una imagen permitida.`);
            valida = true;
        }
  
        return false/* <valida || Upload.LIST_IGNORE> */;
    }

    useEffect(() => {

    }, []);

    const isRutaActual = (ruta) => {
            
        if (ruta === indexMobile) {
            return true;
        } else {
            return false;
        }
               
    };

    const styleSelected = { background: colorEventos, borderRadius: 5, padding: 10, color: 'white' };

    return (
        <>
        
        {(screenIsOnLandscape && student !== null) && (
        <Layout hasSider >
        <Sider
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className="demo-logo-vertical text-center mb-3 mt-3">
                <Avatar
                    size={90}
                    src={student?.urlimg !== null ? `${URL_IMG}${student?.token}/${student?.urlimg}` : URL_IMG_USER }
                />
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onSelect={value => setIndex(value)} items={[
                { key: '1', icon: <UserOutlined/>, label: 'Alumno'},
                { key: '2', icon: <DollarCircleFilled/>, label: 'Pagos Pendientes'},
                { key: '3', icon: <ShopOutlined/>, label: 'Recibos'}
            ]} />
        </Sider>
        <Layout
            className="site-layout"
            style={{
            marginLeft: 200,
            }}
        >
            <Content
            style={{
                margin: '24px 16px 0',
                overflow: 'initial',
            }}
            >
            <div
                style={{
                padding: 24,
                textAlign: 'center',
                background: colorBgContainer,
                }}
            >
                {(index === null || index?.key === '1') && (
                    <AlumnoGeneralQR/>
                )}

                {(index?.key === '2') && (
                    <PagosPendientesQR/>
                )}

                {(index?.key === '3') && (
                    <RecibosQR/>
                )}
            </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >
            Mi Academia Limalama ©2023            
            </Footer>
        </Layout>
        </Layout>
        )}
        {(!screenIsOnLandscape && student !== null) ? (
            <>
            <Navbar key={false} style={{ background: '#001529', position: 'fixed', top: 0, zIndex: 1, width: '100%' }} variant="dark" expand={false} expanded={expand} onToggle={() => setExpand(!expand)}> 
            <Container fluid>
              <Navbar.Brand href="#">
                  {(indexMobile === null || indexMobile === '1' ) ? (<><FontAwesomeIcon icon={faUser}/> Alumno</>) : null}
                  {indexMobile === '2' ? (<><FontAwesomeIcon icon={faCircleDollarToSlot}/> Pagos Pendientes</>) : null}
                  {indexMobile === '3' ? (<><FontAwesomeIcon icon={faAddressCard}/> Recibos</>) : null}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`}/>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-sm`}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm}`}
                style={{ width: '85%' }}
              >
                <Offcanvas.Header closeButton onClick={() => setExpand(false)}>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                    <div className='row'>
                            <div className='col-5'>
                                <img src='/img/logo.png' style={{ width: 100 }}></img>
                            </div>
                            <div className='col-7' style={{ alignSelf: 'center' }}>
                                Academia Ohana App <br></br>
                                {navigator.onLine ? <Badge status="processing" text="En línea"/> : <Badge status="error" text="Sin Conexión"/>}
                            </div>
                    </div>  
                  </Offcanvas.Title>
                </Offcanvas.Header> 
                <Offcanvas.Body>
                  <Divider style={{ marginTop: 0, marginBottom: 5 }}/>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link onClick={() => {setIndexMobile('1'); setExpand(false); }} className={!isRutaActual('1') ? "text-muted": "text-white"} style={isRutaActual('1') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faUser}/> Alumno
                    </Nav.Link>
                    <Nav.Link onClick={() => {setIndexMobile('2'); setExpand(false); }} className={!isRutaActual('2') ? "text-muted": "text-white"} style={isRutaActual('2') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faCircleDollarToSlot}/> Pagos Pendientes
                    </Nav.Link>
                    <Nav.Link onClick={() => {setIndexMobile('3'); setExpand(false); }} className={!isRutaActual('3') ? "text-muted": "text-white"} style={isRutaActual('3') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faAddressCard}/> Recibos
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          <div style={{ marginBottom: '4em' }}></div>
          {(indexMobile === '1' && student !== null) && (
                <AlumnoGeneralQR/>
           )}

           {(indexMobile === '2' && student !== null) && (
               <PagosPendientesQR/>
           )}

           {(indexMobile === '3' && student !== null) && (
               <RecibosQR/>
           )}
          </>
        ) : ''}

        {(student === null) && (
            <Watermark
                height={80}
                width={80}
                zIndex={-1}
                gap={[5,5]}
                image={'/img/logo.png'}
            >   
            <div className='container' style={styleBack}>
                <div className='row'>
                    <div className='col-lg-12 text-white'>
                        <Card>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-12'>
                                    <Form
                                        layout='vertical'
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            name="img"
                                            label="Ingrese el QR proporcionado por su maestro."
                                            valuePropName="fileList"
                                            tooltip="Este es un campo requerido."
                                            getValueFromEvent={normFilePortada}
                                            beforeUpload={beforeUpload}
                                        >
                                            <Upload 
                                                name="img"
                                                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                listType="picture" 
                                                maxCount={1}
                                                beforeUpload={beforeUpload}
                                            >
                                                <Button icon={<UploadOutlined />}>Haga clic para cargar.</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Button
                                            type="primary" 
                                            htmlType="submit"
                                            shape="round"
                                            block
                                        >
                                            Ir
                                        </Button>
                                    </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                </div>
            </div>
            </Watermark>
        )}
        <div className='container'>
            <div className='row'>
                <div className='col-12 text-center mb-3' style={{ marginTop: '40vh' }}>
                <Spin tip="Cargando información, por favor espere..." spinning={loading} >
                </Spin>
                </div>
            </div>
        </div>
        <ButtonBts 
            style={{...globalStyles.btnAddProductos, bottom: 10, borderRadius: 0, width: 'auto', height: 'auto', background: `black`, borderColor: 'black'}}
            onClick={() => {navigate('/'); setStudentStore(null)}}
        >
            <FontAwesomeIcon icon={faArrowLeft} /> Salir
        </ButtonBts>
        
        </>
    )
};

const styleBack = {
    /* background: '#282c34',  */display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'
};

const styleImagen = {
    width: 60,
    height: 60,
    borderRadius: 100,
};

const styleNombre = {
    color: '#2F2F2F',
    fontSize: 15,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: 2
};

const styleApellidos = {
    fontSize: 17,
    color: '#64748B',
    marginBottom: 2
};



export default AlumnoInfoQR