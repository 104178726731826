
import {
    setUsuario, 
    setCurrentUser, 
    setLoading,
    setConfiguracion,
    reset
} from '../../store/CurrentUserSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { swalCenterInfo, swalCenterWarning } from '../../recursos/Toast';
import { loginAccessService, registrarUsuarioService } from '../../Services/CourrentUserService';
import { useMaestrosStore } from '../Maestros/useMaestrosStore';
import { colorAlumnos, colorEventos, colorProductos, colorSaldos } from '../../recursos';

export const useCourrentUserStore = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        courrentUser,
        isLoading,
        error,
        usuario,
        configuracion
    } = useSelector(store => store.CourrentUser);

    const { getDatosMaestroStore } = useMaestrosStore();

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    const restablecerValoresNavBar = () => {
        dispatch( 
            setConfiguracion({
            ...configuracion, 
            colorNavBar: {
            inicio: '#000000',
            alumnos: colorAlumnos,
            maestro: 'purple',
            equipo: colorProductos,
            saldos: colorSaldos,
            eventos: colorEventos,
            tablero: '#000000'
            }}) 
        );
    }

    const getOnlineStatus = () => {
        return navigator.onLine;
    }

    const setUsuarioStore = (usuario) => {
        dispatch( setUsuario(usuario) );
    }

    const setCourrentUserStore = (currentUser) => {
        dispatch( setCurrentUser(currentUser) );
    }

    const setLoadingCourrentStore = (currentUser) => {
        dispatch( setLoading(currentUser) );
    }

    const setConfiguracionStore = (configuracion) => {
        dispatch( setConfiguracion(configuracion) );
    }

    const resetCourrentUser = () => {
        dispatch( reset() );
    }
    
    const loginAccessStore = (usuario, contrasena) => {
        
        if (!navigator.onLine) {
            return ;
        }

        setLoadingCourrentStore(true);
        loginAccessService(usuario,contrasena).then( response => {
            if (response.status === 200) {
                const hoy = Date.now();
                const f = hoy + (response.data.expires_in * 1000);
                
                let currentUser = { 
                    isSignedIn: true,
                    token_type: response.data.token_type,
                    expires_in: response.data.expires_in,
                    dateExpire: f,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                };
           
                dispatch( setCurrentUser (currentUser) );      
                getDatosMaestroStore(`${currentUser?.token_type} ${currentUser?.access_token}`);          
                navigate('/inicio');
            }
            

        }).catch( error => {
            swalCenterWarning('Datos de usuario incorrectos.');
        }).finally(final => {
            setLoadingCourrentStore(false);
             
        }); 
    }

    const registrarUsuarioStore = (usuario, contrasena) => {

        if (!navigator.onLine) {
            return ;
        }

        registrarUsuarioService(usuario, contrasena)
        .then( response => {

            if (response.data.success) {
                
                let user = { 
                    id: 0,
                    name: '',
                    email: response.data.usuario,
                    psw: response.data.psw,
                    role_id: 0
                };
           
                dispatch( setUsuario (user) );
                window.location.replace('/');
            }
            swalCenterInfo(response.data.message);

        }).catch( error => {
            swalCenterWarning('Ocurrio un error, intente de nuevo.');
        }).finally(final => dispatch( setLoading(false) ) ); 
    
    }

    return {
        /* Funciones */
        loginAccessStore,
        registrarUsuarioStore,
        setUsuarioStore,
        setCourrentUserStore,
        setLoadingCourrentStore,
        setConfiguracionStore,
        resetCourrentUser,
        restablecerValoresNavBar,
        getOnlineStatus,
        /* Parametros */
        courrentUser,
        isLoading,
        error,
        usuario,
        configuracion,
        token
    }
}