import { Button, Form, Input, Spin, Watermark } from 'antd';
import React, { useEffect, useState } from 'react'
import { Card, Button as ButtonBts  } from 'react-bootstrap';
import { Loading3QuartersOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';
import { useReSizeWindow } from '../../Hooks/useReSizeWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserCircle, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { colorAlumnos } from '../../recursos';

const Login = () => {

    const navigate = useNavigate();
    const [registro, setRegistro] = useState(false);
    const [acceso, setAcceso] = useState(0);//1 iniciar maestro - 2 iniciar alumno
    const {
        registrarUsuarioStore,
        loginAccessStore,
        setLoadingCourrentStore,
        isLoading
    } = useCourrentUserStore();

    const { screenIsOnLandscape } = useReSizeWindow();

    const onFinish = (values) => {
        if (registro) {
            registrarUsuarioStore(values.usuario, values.contrasena);
        } else {
            loginAccessStore(values.usuario, values.contrasena);
        }
        setRegistro(false);
    }

    useEffect(() => {
        setLoadingCourrentStore(false);
    }, []);

    const styleBack = {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'
    };
    

    return (
      <>
      
        <div className='container' >
        {!screenIsOnLandscape && (
            <>
            {acceso === 0 && (
             <Watermark
                height={80}
                width={80}
                zIndex={-1}
                gap={[5,5]}
                image={'/img/logo.png'}
            >   
            <div className='row animated fadeIn' style={styleBack}>
                {/* <div className='col-lg-12 mt-3 d-grid gap-2'>
                <img src='/img/logo.png' style={{ width: '100%'}}></img>
                </div> */}
                <div className='col-lg-12 mt-3 d-grid gap-2'>
                    <ButtonBts variant='dark' size='lg' style={{ borderRadius: 0}} onClick={() => setAcceso(1)}>
                    <FontAwesomeIcon icon={faUserCircle}/> Maestro
                    </ButtonBts>
                </div>
                <div className='col-lg-12 mt-3 d-grid gap-2'>
                    <ButtonBts variant='primary' size='lg' style={{ borderRadius: 0 }} onClick={() => navigate('/detalle/0')}>
                        <FontAwesomeIcon icon={faUserGraduate}/> Alumno
                    </ButtonBts>
                </div>
            </div>
            </Watermark>
            )}
            {acceso === 1 && (
            <div className='row animated fadeIn'>
                <ButtonBts onClick={() => setAcceso(0)} variant='light' className='' style={{ float: 'right' }}>
                    <FontAwesomeIcon icon={faArrowLeft}/>{' '}Volver al menú 
                </ButtonBts>
                <div className='col-lg-12 mt-3 text-center'>
                    <img src='/img/logo.png' style={{ width: '100%' }}></img>
                </div>
                <div className='col-lg-12 mt-3'>
                    <Spin tip='Cargando'  spinning={isLoading}  style={{ color: 'gray' }} indicator={<Loading3QuartersOutlined spin />}>
                    <Card className='shadow'>
                            <Card.Header className='text-center'>
                                <Card.Title>
                                {registro ? 'Registrar usuario' : 'Iniciar sesión'}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="usuario"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa el usuario!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Por favor ingrese un emial valido!',
                                        }
                                        ]}
                                    >
                                        <Input 
                                            prefix={<UserOutlined className="site-form-item-icon" />} 
                                            placeholder="Usuario" 
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="contrasena"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa la contraseña!',
                                        },
                                        ]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Contraseña"
                                            size="large"
                                            maxLength={4}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button 
                                            type="primary" 
                                            htmlType="submit" 
                                            className="login-form-button" 
                                            shape="round" 
                                            block
                                        >
                                        {!registro ? 'Iniciar' : 'Guardar'}
                                        </Button>
                                    </Form.Item>
                                </Form>
                                {!registro &&
                                <Button
                                    className="login-form-button" 
                                    shape="round" 
                                    block
                                    onClick={() => setRegistro(true)}
                                >
                                    Registrarme
                                </Button>
                                }
                                {registro &&
                                <Button
                                    className="login-form-button" 
                                    shape="round" 
                                    block
                                    onClick={() => setRegistro(false)}
                                >
                                    Volver
                                </Button>
                                }
                                
                            </Card.Body>
                    </Card>
                    
                    </Spin>
                </div>
            </div>
            )}
            </>
        )}
        </div>
      </>
    )
}

export default Login;