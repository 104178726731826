import clienteAxios from "../config/axios";

export const createEventoService = async (params, token) => {
    return await clienteAxios.post('eventos/create', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const getEventoAlumnosService = async (params, token) => {
    return await clienteAxios.post('eventos/alumnos', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const editParticipacionService = async (params, token) => {
    return await clienteAxios.post('eventos/participacion/edit', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}


export const getEventosListService = async (token) => {
    return await clienteAxios.get('eventos',
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const getEventosNotificacionesService = async (token) => {
    
    return await clienteAxios.get('eventos/notificaciones',
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const cancelarParticipacionService = async (params, token) => {
    return await clienteAxios.post('eventos/participacion/cancelar', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}