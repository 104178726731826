import React, { useState } from 'react';
import { faCalendar, faDollar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Checkbox, Form, InputNumber } from 'antd';
import { formatearCantidad } from '../../../recursos';
import { DollarOutlined } from '@ant-design/icons';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { useEffect } from 'react';
import { swalCenterInfo } from '../../../recursos/Toast';
import ModalBorrarRegistroPago from '../modals/ModalBorrarRegistroPago';

const MensualidadesAlumno = () => {

    const [form] = Form.useForm();
    const { 
        student, 
        loading, 
        aplicarPagoStore,
        setLoadingStore
    } = useStudentsStore();

    const [idFlag, setIdFlag] = useState(false);
    const [comprasPago, setComprasPago] = useState([]);
    const [openCondonar, setOpenCondonar] = useState(false);

    const total = (concepto) => {
        if (student.adeudos.length > 0) {
 
            let data = student.adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return Number(data[0].total);
            } else {
                return 0;
            }
            
        } else {
            return 0;
        }
    };

    const totalSeleccion = () => {
        let totallocal = 0;

        comprasPago.forEach(compra => {
            totallocal = totallocal + (compra.total - compra.pagado);
        });

        return totallocal;
    }

    
    const checkBoxValue = (data) => {
        let arrayFilter = comprasPago.filter(compra => compra?.id === data?.id);

        if (arrayFilter.length > 0) {
            return true;
        }

        return false;
    }

    const checkAll = () => {

        let array = [];

        student?.compras?.forEach( compra => {
            if(compra?.pagado < compra?.costo && compra?.cantidad > 0){
                array.push(compra);
            }
        });

        if (comprasPago.length < array.length) {
            setComprasPago(array);
        } else {
            setComprasPago([]);
        }
       
    }

    const onChangeCheck = (data) => {

        let arrayFilter = comprasPago.filter(compra => compra?.id === data?.id);

        if (arrayFilter.length > 0) {
            setComprasPago(comprasPago.filter(compra => compra?.id !== data?.id));
        } else {
            setComprasPago([...comprasPago, data]);
        }

    }

    const aplicarPagoMensualidad = (values) => {
        setLoadingStore(true);
        let pagoMensualidad = values.pagoMensualidad;

        let totallocal = 0;

        comprasPago.forEach(compra => {
            totallocal = totallocal + (compra.total - compra.pagado);
        });
        
        let pago = pagoMensualidad;
        if (pagoMensualidad > totallocal) {
            pago = totallocal;
            aplicarPagoStore( student.id, 1, Number(pago), JSON.stringify(comprasPago));
            swalCenterInfo('Regresar '+formatearCantidad(pagoMensualidad - totallocal)+' de cambio');
        } else {
            aplicarPagoStore(student.id, 1, Number(pagoMensualidad), JSON.stringify(comprasPago));
        }
        setComprasPago([]);
        form.resetFields();
    }

    useEffect(() => {
        setLoadingStore(false);
    }, []);
    

    return (
        <>
        <Card className='shadow' title={
            <div className='col-lg-12 h5 fw-bold text-center' onClick={() => setIdFlag(!idFlag)} style={{...styleTitulo}}>
                PAGOS PENDIENTES
            </div>
            }
        >
            <div className='row'>
                <div className='col-lg-12 p-0'>
                    <table className="table table-sm table-striped">
                        <thead className='bg-dark text-white'>
                            <tr>
                            <th scope="col">Descripción</th>
                            <th scope="col">Cant</th>
                            <th scope="col">Total</th>
                            <th scope="col">Abonado</th>
                            <th scope="col">
                                <Checkbox 
                                    //checked={data.dominio}
                                    onChange={checkAll}
                                />
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                        {student?.compras?.map((compra, index) => {
                            if(compra?.pagado < compra?.costo && compra?.cantidad > 0){
                                return (
                                    <tr key={index}>
                                        <td>
                                            <strong>{compra.descripcion}</strong>
                                        </td>
                                        <td className='text-center align-middle fw-bold'>{compra.cantidad}</td>
                                        <td className='align-middle fw-bold'>{formatearCantidad(compra.total)}</td>
                                        <td className={`align-middle fw-bold`} style={{ color: compra.pagado === 0 ? 'red' : 'blue'}}>{formatearCantidad(compra.pagado)}</td>
                                        <td className='align-middle'>
                                        <Checkbox 
                                            checked={checkBoxValue(compra)}
                                            onChange={() => onChangeCheck(compra)}
                                        />
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                        {student?.compras?.filter(com => com.pagado < com.costo && com.cantidad > 0).length === 0 &&
                            <tr>
                                <td className='text-center' colSpan={5}>No se encontrarón pagos pendientes</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-12 text-end mt-2'>
                    <strong>Total a pagar: </strong> {formatearCantidad(totalSeleccion())}
                </div>
                
            </div>
            {(total(1)+total(2)+total(3)) > 0 && (
            <div className='row'>
                <div className='col-lg-12 text-end mt-2'>
                    <Form layout="horizontal" onFinish={aplicarPagoMensualidad} form={form}>
                        <Form.Item
                            name="pagoMensualidad"
                            rules={[{ required: true, message: 'Por favor ingrese una cantidad!' }]}
                        >
                            <InputNumber 
                                min={1} 
                                size="large" 
                                style={{width: '100%'}}
                                disabled={comprasPago.length === 0 ? true : loading}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                style={{ background: 'orange', marginRight: 10 }}
                                htmlType="button"
                                size='large'
                                shape="round" 
                                disabled={comprasPago.length === 0 ? true : loading}
                                loading={loading}
                                onClick={() => setOpenCondonar(true)}
                            >
                                Condonar pago
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#425910' }}
                                htmlType="submit"
                                size='large'
                                shape="round" 
                                disabled={comprasPago.length === 0 ? true : loading}
                                loading={loading}
                            >
                                Abonar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            )}
        </Card>
        <ModalBorrarRegistroPago 
            alumno_id={student.id}
            open={openCondonar}
            setOpen={setOpenCondonar}
            arrayCompras={JSON.stringify(comprasPago)}
            setComprasArray={setComprasPago}
        />
        </>
    )
}

const styleTitulo =  {
    fontSize: 18,
    color: '#425910',
    fontWeight: 'bold',
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: 'center',
    textTransform: 'uppercase'
};

export default MensualidadesAlumno