import { faArrowLeft, faCalendar, faCartPlus, faDollar, faTimes, faTrophy, faUserAlt, faUserPlus, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty, Badge, FloatButton } from 'antd';
import React, { useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { colorAlumnos } from '../../recursos';
import DetalleAlumno from './info/DetalleAlumno';
import MensualidadesAlumno from './info/MensualidadesAlumno';
import ArticulosAlumno from './info/ArticulosAlumno';
import EventosAlumno from './info/EventosAlumno';
import HeaderAlumno from './info/HeaderAlumno';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';
import RecibosQR from './AlumnoQRInfo/RecibosQR';

const AlumnosInfo = () => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(2);
    const { 
        student,
        changeStatusStore,
        siguienteAlumno,
        anteriorAlumno
    } = useStudentsStore();
    const { configuracion } = useCourrentUserStore();

    const getNotificacion = (index) => {
        return student?.compras?.filter(compra => (compra.pagado < compra.total) ).length || 0;
    }

    const buttonGroup = (
        <ButtonGroup style={{ width: '100%'  }}>
                <Button 
                    className='text-start'
                    style={{ background: configuracion?.colorNavBar?.alumnos,  borderRadius: 0, borderColor: configuracion?.colorNavBar?.alumnos }}
                    onClick={() => changeStatusStore(student.id)}
                >
                    <FontAwesomeIcon icon={student.estatus ? faUserXmark : faUserPlus}/> {student.estatus ? 'Baja' : 'Alta'}
                </Button>
                <Button 
                    className='text-end'
                    style={{ background: configuracion?.colorNavBar?.alumnos,  borderRadius: 0, borderColor: configuracion?.colorNavBar?.alumnos }}
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faTimes}/> Cerrar
                </Button>
        </ButtonGroup>
    );

    return (
        <>
            {student ? (
            <div className="p-0 " style={{ marginLeft: 0, marginRight: 0 }}>

                <HeaderAlumno buttonGroup={buttonGroup}/>
                <div style={{ marginTop: '12em' }}></div>
                {index === 1 && (
                    <div className='col-12 p-1 animated fadeIn fast'>
                        <DetalleAlumno/>
                    </div>
                )}
                {index === 2 && (
                <div className='col-12 p-1 animated fadeIn fast'>
                    <MensualidadesAlumno/>
                </div>
                )}
                {index === 3 && (
                <div className='col-12 p-1 animated fadeIn fast'>
                    <ArticulosAlumno/>
                </div>
                )}
                {index === 4 && (
                <div className='col-12 p-1 animated fadeIn fast'>
                    <EventosAlumno/>
                </div>
                )}
                <div style={{ marginBottom: '4em' }}></div>
                <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0, width: '100%' }}>
                    
                    <Button
                        size='large'
                        style={{ borderRadius: 0, background: index === 2 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(2)}
                    >
                        {/* {getNotificacion(1) === 0 ? ( */}
                            <label>
                            <FontAwesomeIcon icon={faDollar}/> <br></br>Pagos Pendientes
                            </label>
                        {/* ) : (
                            <Badge count={getNotificacion(1)}>
                                <label className='text-white'>
                                <FontAwesomeIcon icon={faDollar}/> <br></br>Pagos
                                </label>
                            </Badge>
                        )} */}
                    </Button>
                    <Button
                        size='large'
                        style={{ borderRadius: 0, background:  index === 1 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(1)}
                    >
                        <FontAwesomeIcon icon={faUserAlt}/> <br></br>Datos Alumno
                    </Button>
                    {/* <Button
                        size='large'
                        style={{ borderRadius: 0, background: index === 3 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(3)}
                    >
                        {getNotificacion(2) === 0 ? (
                            <label>
                            <FontAwesomeIcon icon={faCartPlus}/> <br></br>Articulos
                            </label>
                        ) : (
                            <Badge count={getNotificacion(2)}>
                            <label className='text-white'>
                            <FontAwesomeIcon icon={faCartPlus}/> <br></br>Articulos
                            </label>
                            </Badge>
                        )}
                    </Button>
                    <Button
                        size='large'
                        style={{ borderRadius: 0, background: index === 4 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(4)}
                    >
                        {getNotificacion(3) === 0 ? (
                            <label>
                            <FontAwesomeIcon icon={faTrophy}/> <br></br>Eventos
                            </label>
                        ) : (
                            <Badge count={getNotificacion(3)}>
                            <label className='text-white'>
                            <FontAwesomeIcon icon={faTrophy}/> <br></br>Eventos
                            </label>
                        </Badge>
                        )}
                    </Button> */}
                </ButtonGroup>
            </div>) : (
                <div className='col-lg-12 text-center h4'>
                    <Empty
                        description={
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    No se encontro información disponible.
                                </div>
                                <div className='col-lg-12 text-center mt-3'>
                                    <Button
                                        variant="secondary" 
                                        style={{ borderRadius: 50 }}
                                        onClick={() => navigate(-1)}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft}/> Volver
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                </div>
            )}  
            <FloatButton 
                icon={<ArrowLeftOutlined/>} 
                type="" 
                style={{ left: 24, top: '15em', opacity: 0.2 }} 
                onClick={anteriorAlumno}                
            /> 
            <FloatButton 
                icon={<ArrowRightOutlined/>} 
                style={{ right: 24, top: '15em', opacity: 0.2 }} 
                onClick={siguienteAlumno}                
            />   

            
        </>
    )
}

export default AlumnosInfo