import { ArticulosSlice } from "./ArticulosSlice";
import { CatalogosSlice } from "./CatalogosSlice";
import { CourrentUserSlice } from "./CurrentUserSlice";
import { EventosSlice } from "./EventosSlice";
import { MasterSlice } from "./MasterSlice";
import { StudentSlice } from "./StudentSlice";

export const rootSlice = {
    CourrentUser: CourrentUserSlice.reducer,
    Master: MasterSlice.reducer,
    Student: StudentSlice.reducer,
    Catalogos: CatalogosSlice.reducer,
    Articulos: ArticulosSlice.reducer,
    Eventos: EventosSlice.reducer,
};