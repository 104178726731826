import { FloatButton, Empty, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { InfoCircleOutlined, SettingOutlined, HistoryOutlined, FilterOutlined, ClearOutlined, ControlOutlined, LoadingOutlined } from '@ant-design/icons';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';
import { Element, scroller  } from 'react-scroll';
import AlumnoCardGuia from './guia/AlumnoCardGuia';
import ButtonPDFReporteAlumnos from './buttons/ButtonPDFReporteAlumnos';
import { useCatalogosStore } from '../../Hooks/Catalogos/useCatalogosStore';
import CardAlumno from './cards/CardAlumno';
import ModalFiltroAlumnos from './modals/ModalFiltroAlumnos';
import ModalConfiguracionCard from './modals/ModalConfiguracionCard';
import ModalMenuOpciones from './modals/ModalMenuOpciones';

const Alumnos = () => {

    const [modalMenu, setModalMenu] = useState(false);
    const [openGuia, setOpenGuia] = useState(false);
    const [configuracionCard, setConfiguracionCard] = useState(false);
    const [modalOpciones, setModalOpciones] = useState(false);

    const { 
        studentsList,
        alumnosFiltroList,
        getStudentsListStore,
        setStudentStore, 
        setFiltroAlumnosStore,
        setFiltroStore,
        aplicarFiltro,
        loading
    } = useStudentsStore();

    const { 
        getGradosStore, 
    } = useCatalogosStore();

    useEffect(() => {
        getGradosStore();
        if (studentsList?.length === 0) {
            getStudentsListStore();
        }
    }, []);

    useEffect(() => {
        aplicarFiltro();
    }, [studentsList]);

    useEffect(() => {
        if (!modalMenu) {
            setStudentStore(null);
        }
    }, [modalMenu]);

    return (
        <>
        <div className='cotainer-fluid'>
            <Element name="top"></Element>
            <div className='row' style={{ marginLeft: 2, marginRight: 2 }}>
                {(alumnosFiltroList?.length === 0 && loading) && (
                    <Result
                        icon={<LoadingOutlined
                            style={{
                              fontSize: 24,
                            }}
                            spin
                          />}
                        title={'Cargando información...'}
                    />
                )}

                {openGuia && (
                    <AlumnoCardGuia openGuia={openGuia} setOpenGuia={setOpenGuia}/>
                )}

                {alumnosFiltroList?.map((alumno, index) => 
                    <CardAlumno alumno={alumno} setModalOpciones={setModalOpciones} key={index}/>
                )}

                {(alumnosFiltroList?.length === 0 && !loading) && (
                    <div style={{ marginTop: '5em' }}>
                        <Empty description={<span>No se econtrarón resultados.</span>} />
                    </div>
                )}

            </div>
            <div style={{ marginBottom: '4em' }}></div>
        </div>

        <ModalFiltroAlumnos
            open={modalMenu}
            setOpen={setModalMenu}
            aplicarFiltro={aplicarFiltro}
        />     

        <ModalConfiguracionCard
            open={configuracionCard}
            setOpen={setConfiguracionCard}
        />

        <ModalMenuOpciones
            open={modalOpciones}
            setOpen={setModalOpciones}
        />

        <FloatButton.Group
            trigger="click"
            type="primary"
            size="large"
            style={{
                right: 24,
                bottom: 24,
            }}
            icon={<SettingOutlined />}
        >
            <FloatButton onClick={() => { setFiltroAlumnosStore(studentsList);  setFiltroStore({mensualidad: false,equipo: false,eventos: false,nombre: '',grado: null, evento: ''})}} tooltip={<div>Limpiar Filtro</div>} icon={<ClearOutlined/>} />
            <FloatButton onClick={() => setModalMenu(true)} tooltip={<div>Filtro</div>} icon={<FilterOutlined />} />
            <FloatButton onClick={() => getStudentsListStore()} tooltip={<div>Refrescar</div>} icon={<HistoryOutlined />} />
            {/* {configuracion?.vistaAlumno === 2 ? (
                <FloatButton tooltip={<div>Lista</div>} icon={<DatabaseOutlined/>} onClick={() => setVistaConfiguracion(1)}/>
            ) : (
                <FloatButton tooltip={<div>Cuadricula</div>} icon={<AppstoreOutlined />} onClick={() => setVistaConfiguracion(2)}/>
            )} */}
            {studentsList?.length > 0 && (
            <ButtonPDFReporteAlumnos/>
            )}
            <FloatButton 
                tooltip={<div>Configuración Tarjeta Alumno</div>} 
                icon={<ControlOutlined />}  
                onClick={ () => {
                    setConfiguracionCard(true);  
                }}
            />
            {/* <FloatButton 
                tooltip={<div>Información</div>} 
                icon={<InfoCircleOutlined />}  
                onClick={ () => { 
                    scroller.scrollTo("top", {duration: 0,delay: 0, smooth: true, offset: -60}); 
                    setOpenGuia(true);  }
                }
            /> */}
            
        </FloatButton.Group>
        
        <Outlet/>
        </>
    )
}

export default Alumnos