import { Checkbox, Modal } from 'antd';
import React from 'react'
import AlumnoCardGuia from '../guia/AlumnoCardGuia';
import { useCourrentUserStore } from '../../../Hooks/CourrentUser/useCourrentUserStore';

const ModalConfiguracionCard = ({ open, setOpen }) => {

    const { 
        configuracion, 
        setConfiguracionStore
    } = useCourrentUserStore();

    return (
        <>
        <Modal 
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            centered
            footer={[]}
        >
            <div className='row' style={{ marginTop: '3em' }}>
                {open && (
                <AlumnoCardGuia openGuia={false}/>
                )}
                <div className='col-lg-12 mb-3 mt-3'>
                <Checkbox checked={configuracion?.cardAlumno?.totalMensualidad} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, totalMensualidad: e.target.checked } })}>Total Mensualidades.</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={configuracion?.cardAlumno?.totalEquipo} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, totalEquipo: e.target.checked } })}>Total Equipo.</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={configuracion?.cardAlumno?.totalEventos} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, totalEventos: e.target.checked } })}>Total Eventos/Torneos</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={configuracion?.cardAlumno?.totalGeneral} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, totalGeneral: e.target.checked } })}>Total General</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={configuracion?.cardAlumno?.grado} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, grado: e.target.checked } })}> Grado</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={configuracion?.cardAlumno?.notificacion} onChange={e => setConfiguracionStore({...configuracion, cardAlumno: { ...configuracion?.cardAlumno, notificacion: e.target.checked } })}> Notificacion</Checkbox>
                </div>
            </div>
        </Modal>
        </>
    )
}

export default ModalConfiguracionCard