import clienteAxios from "../config/axios";

export const getStudentsListService = async (token) => {
    
    return await clienteAxios.get('students',
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const getDatosAlumnoService = async (id,token) => {
    
    return await clienteAxios.post('students/datos-alumno', { alumno_id: id },
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const getAlumnoQRService = async (tokenAlumno, token) => {
    
    return await clienteAxios.post('students/alumnoqr', { token: tokenAlumno });
}

export const changeStatusService = async (alumno_id, token) => {
    return await clienteAxios.get('students/estatus/'+alumno_id,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const aplicarPagoService = async ({ alumno_id, concepto_id, monto, comprasPago}, token) => {
    return await clienteAxios.post('pagos/aplicar-pago', {alumno_id, concepto_id, monto, comprasPago},
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const eliminarPagoService = async ({ id, psw, arrayCompras, alumno_id }, token) => {
    return await clienteAxios.post('pagos/eliminar-registro', { id, psw, arrayCompras, alumno_id},
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const nuevoAlumnoService = async (params) => {
    return await clienteAxios.post('students/new-alumno', params);
}

export const modificarAlumnoService = async (params, token) => {
    return await clienteAxios.post('students/update', params,
    {
        headers: {
            'Authorization' : token
        }
    })
}

export const addEstadisticaService = async (params, token) => {
    return await clienteAxios.post('estadisticas/add', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const editEstadisticaService = async (params, token) => {
    return await clienteAxios.post('estadisticas/edit', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const editConocimientoService = async (params, token) => {
    return await clienteAxios.post('students/conocimiento/status', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const editAsistenciaService = async (params, token) => {
    return await clienteAxios.post('students/asistencia', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}