import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Card, Divider, FloatButton, Form, Input, QRCode, Select } from 'antd';
import { Button as Btn, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { faAddressBook, faCalendar, faDownload, faEnvelope, faPhone, faQrcode, faShare, faUser, faUserPlus, faUserXmark } from '@fortawesome/free-solid-svg-icons'
import { colorAlumnos, formatearFecha, getColorGrado } from '../../../recursos';
import { CloseOutlined, EditOutlined, FileTextOutlined, HistoryOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { useCatalogosStore } from '../../../Hooks/Catalogos/useCatalogosStore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { swalCenterInfo } from '../../../recursos/Toast';
import ModalInfoInformacionPagos from '../modals/ModalInfoInformacionPagos';
import RecibosQR from '../AlumnoQRInfo/RecibosQR';
import AlumnoGeneralQR from '../AlumnoQRInfo/AlumnoGeneralQR';
import { AES, enc, SHA256 } from 'crypto-js';

const { Option } = Select;

const downloadQRCode = (nombre) => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = nombre+'.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

const DetalleAlumno = () => {

    const [form] = Form.useForm();
    const [modalEdit, setModalEdit] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [modalRecibo, setModalRecibo] = useState(false);
    const [modalHistorial, setModalHistorial] = useState(false);

    const { 
        student,
        modificarAlumnoStore
    } = useStudentsStore();
    const { 
        gradosList,
        estadosList, 
        municipiosList,
        getGradosStore,
        getEstadosStore,
        getMunicipiosStore 
    } = useCatalogosStore();

    useEffect(() => {
        form.setFieldValue('id', student?.id);
        form.setFieldValue('nombre', student?.nombre);
        form.setFieldValue('apellido_paterno', student?.apellido_paterno);
        form.setFieldValue('apellido_materno', student?.apellido_materno);
        form.setFieldValue('curp', student?.curp);
        form.setFieldValue('telefono', student?.telefono);
        form.setFieldValue('correo', student?.correo);
        form.setFieldValue('genero_id', student?.genero_id);
        form.setFieldValue('curp', student?.curp);
        form.setFieldValue('escolaridad_id', student?.escolaridad_id.toString());
        form.setFieldValue('grado_id', student?.grado_id);
        form.setFieldValue('colonia', student?.direccion?.colonia);
        form.setFieldValue('calle', student?.direccion?.calle);
        form.setFieldValue('no_int', student?.direccion?.no_int);
        form.setFieldValue('no_ext', student?.direccion?.no_ext);
        form.setFieldValue('estado_id', student?.direccion?.estado_id);
        form.setFieldValue('municipio_id', student?.direccion?.municipio_id);
        getGradosStore();
        getEstadosStore();
        getMunicipiosStore();
    }, []);

    const getGenero = () => {
        switch(student?.genero_id){
            case 1:
                return 'Masculino'
            case 2: 
                return 'Femenino'
            default:
                return 'Otro';
        }
    };

    const handleCopy = () => {
        swalCenterInfo('Se copio el link en el portapapeles.');
    } 

    const onFinish = (values) => {
        modificarAlumnoStore(values);
        setModalEdit(false);
    };

    return (
        <>
        <Card className='shadow' title={
            <div className='col-lg-12 text-center'>
                <label style={{...styleTitulo}}>Datos del Alumno</label>
            </div>
            }
        >
            {!modalEdit ? (
            <div className='row justify-content-center'>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faUser}/> Genero: </strong> {getGenero()}
                </div>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faAddressBook}/> CURP: </strong> {student.curp && student.curp.toUpperCase() }
                </div>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faCalendar}/> Fecha Nacimiento : </strong> {formatearFecha(student.fecha_nacimiento)}
                </div>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faEnvelope}/> Email: </strong> {student.correo}
                </div>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faPhone}/> Teléfono: </strong> {student.telefono}
                </div>
                <div className='col-12 text-muted' style={styleLabel}>
                    <strong> <FontAwesomeIcon icon={faAddressBook}/> Dirección: </strong> {student.direccion ? 
                        `${student.direccion.colonia}, ${student.direccion.calle} No. int ${student.direccion.no_int} No. ext ${student.direccion.colonia}, ${student.direccion.municipio.nombre}, ${student.direccion.estado.nombre}` 
                    : 'No existen datos.'}
                </div>
                <div className='col-6 mt-3 text-center' id="myqrcode">
                    <Btn 
                        onClick={() => downloadQRCode(`${student?.nombre}_${student?.apellido_paterno}`)}
                        style={{ padding: 0 }}
                        variant='light'
                    >
                        <QRCode 
                            value={`${SHA256(student?.token)}-${student?.token}`}
                            color='#555555'
                        />
                    </Btn>
                    
                </div>
                <div className='col-12 mt-3 mb-3 text-center'>
                    {/* <Btn 
                        variant="outline-secondary" 
                        onClick={() => downloadQRCode(`${student?.nombre}_${student?.apellido_paterno}`)}
                        style={{ marginRight: 5, borderRadius: 0 }}
                    >
                        <FontAwesomeIcon icon={faDownload}/>
                    </Btn> */}
                    {/* <CopyToClipboard text={'http://applila.develogit.com/detalle/'+student?.token}>
                        <Btn 
                            variant="outline-secondary" 
                            onClick={handleCopy}
                            style={{ borderRadius: 0 }}
                        >
                            <FontAwesomeIcon icon={faShare}/>
                        </Btn>
                    </CopyToClipboard> */}
                </div>
                <ModalInfoInformacionPagos
                    open={modalInfo}
                    setOpen={setModalInfo}
                />
            </div>
            ) : (
            <div className='row'>
                <div className='col-12'>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="id"
                        label="ID"
                        hidden
                    >
                        <Input placeholder="ID" />
                    </Form.Item>
                    <Form.Item
                        name="nombre"
                        label="Nombre"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el nombre',
                        },
                        ]}
                    >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        name="apellido_paterno"
                        label="Apellido Paterno"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el apellido paterno',
                        },
                        ]}
                    >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        name="apellido_materno"
                        label="Apellido Materno"
                    >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        name="curp"
                        label="CURP"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa la CURP',
                            },
                        ]}
                    >
                        <Input placeholder="CURP" />
                    </Form.Item>
                    <Form.Item
                        name="telefono"
                        label="Télefono"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa el télefono',
                            },
                        ]}
                    >
                        <Input placeholder="Télefono" />
                    </Form.Item>
                    <Form.Item
                        name="correo"
                        label="Correo Eléctronico"
                        rules={[
                            {
                              type: 'email',
                              message: 'El correo no es valido!',
                            },
                            {
                              required: true,
                              message: 'Ingresa el correo electronico!',
                            },
                          ]}
                    >
                        <Input placeholder="Correo eléctronico" />
                    </Form.Item>
                    <Form.Item
                        name="genero_id"
                        label="Genero"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el genero!',
                        },
                        ]}
                    >
                        <Select placeholder="Selecciona el genero.">
                            <Option value={1}>Masculio</Option>
                            <Option value={2}>Femenino</Option>
                            <Option value={3}>Otro</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="escolaridad_id"
                        label="Escolaridad"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa la escolaridad!',
                        },
                        ]}
                    >
                        <Select placeholder="Selecciona el genero.">
                            <Option value="1">Preescolar</Option>
                            <Option value="2">Primaria</Option>
                            <Option value="3">Secundaria</Option>
                            <Option value="4">Media superior</Option>
                            <Option value="5">Superior</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="grado_id"
                        label="Grado"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el grado!',
                        },
                        ]}
                    >
                        <Select placeholder="Selecciona un grado.">
                        {gradosList.map((grado, index) => (
                            <Option key={index} value={grado.id}>{`${grado.color}-${grado.nombre}`}</Option>
                        ) )}
                        </Select>
                    </Form.Item>
                    <Divider>Dirección</Divider>
                    <Form.Item
                        name="colonia"
                        label="Colonia"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa la colonia',
                            },
                        ]}
                    >
                        <Input placeholder="Colonia" />
                    </Form.Item>
                    <Form.Item
                        name="calle"
                        label="Calle"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa la calle',
                            },
                        ]}
                    >
                        <Input placeholder="Calle" />
                    </Form.Item>
                    <Form.Item
                        name="no_int"
                        label="No. Interior"
                        rules={[
                            {
                                required: true,
                                message: 'Ingresa el no. interior',
                            },
                        ]}
                    >
                        <Input placeholder="No. Interior" />
                    </Form.Item>
                    <Form.Item
                        name="no_ext"
                        label="No. Exterior"
                    >
                        <Input placeholder="No. Exterior" />
                    </Form.Item>
                    <Form.Item
                        name="estado_id"
                        label="Estado"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el estado!',
                        },
                        ]}
                    >
                        <Select placeholder="Selecciona un estado.">
                        {estadosList.map((estado, index) => (
                            <Option key={index} value={estado.id}>{estado.nombre}</Option>
                        ) )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="municipio_id"
                        label="Municipio"
                        rules={[
                        {
                            required: true,
                            message: 'Ingresa el municipio!',
                        },
                        ]}
                    >
                        <Select placeholder="Selecciona un municipio.">
                        {municipiosList.map((municipio, index) => (
                            <Option key={index} value={municipio.id}>{municipio.nombre}</Option>
                        ) )}
                        </Select>
                    </Form.Item>
                    
                </Form>
                </div>
            </div>
            )}
        </Card>
        <div style={{ marginBottom: '4em' }}></div>

        {/* <FloatButton 
            icon={<InfoCircleOutlined/>} 
            tooltip={'Información'} 
            style={{ left: 24, bottom: 80 }} 
            onClick={() => setModalInfo(true)}                
        /> */}
        <FloatButton 
            icon={<FileTextOutlined/>} 
            tooltip={'Recibos'} 
            style={{ left: 24, bottom: 80 }} 
            onClick={() => setModalRecibo(true)}                
        />
        <FloatButton 
            icon={<HistoryOutlined/>} 
            tooltip={'Historial'} 
            style={{ left: 80, bottom: 80 }} 
            onClick={() => setModalHistorial(true)}                
        />
        <FloatButton 
            icon={!modalEdit ? <EditOutlined /> : <SaveOutlined/>} 
            tooltip={modalEdit ? 'Guardar' : 'Modificar'} 
            type="primary" 
            style={{ right: 24, bottom: 80 }} 
            onClick={() => modalEdit ? form.submit() : setModalEdit(true)}                
        />
        {modalEdit &&
        <FloatButton 
            icon={<CloseOutlined/> } 
            tooltip={'Cerrar'} 
            type="" 
            style={{ right: 24, bottom: 140 }} 
            onClick={() => setModalEdit(false)}                
        />}

        <Modal 
            show={modalRecibo}
            fullscreen
            onHide={() => setModalRecibo(false)}
        >
            <Modal.Header closeButton style={{ background: colorAlumnos }} className='text-white'>
                RECIBOS
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='mt-3'></div>
                <RecibosQR />
            </Modal.Body>
        </Modal>   

        <Modal 
            show={modalHistorial}
            fullscreen
            onHide={() => setModalHistorial(false)}
        >
            <Modal.Header closeButton style={{ background: colorAlumnos }} className='text-white'>
                HISTORIAL
            </Modal.Header>
            <Modal.Body className='p-0'>
                <AlumnoGeneralQR/>
            </Modal.Body>
        </Modal>   
        </>
    )
}

const styleTitulo =  {
    fontSize: 18,
    color: '#091F92',
    fontWeight: 'bold',
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: 'center',
    textTransform: 'uppercase'
};

const styleLabel = {
    fontSize: 17
};

export default DetalleAlumno