import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { URL_IMG_USER, formatearCantidad, formatearFecha } from '../../recursos';
import { Avatar, Divider, FloatButton } from 'antd';
import { BankOutlined, CloseOutlined, EditOutlined, ShopOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCalendarDay, faCalendarXmark, faCircleDollarToSlot, faGraduationCap, faPhone, faUniversity, faUserAlt, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import EditMaestro from './EditMaestro';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { useCatalogosStore } from '../../Hooks/Catalogos/useCatalogosStore';


const Maestro = () => {

    const [edit, setEdit] = useState(false);
    const { master } = useMaestrosStore();
    const { gradosList, getGradosStore } = useCatalogosStore();

    useEffect(() => {
        getGradosStore();
    }, [])
    

    const getGrado = (grado_id) => {
        if (gradosList) {
            let grado = gradosList?.find(data => data.id === Number(grado_id));
            return `${grado?.color} - ${grado?.nombre}`;
        }
        return '';
    }

    const getGenero = (genero_id) => {
        switch (genero_id) {
            case 1:
                return 'Masculino';
                break;
            case 2:
                return 'Femenino';
            case 3: 
                return 'Otro';
            default:
                return '';
        }
    }

    return (
        <>
        {!edit && (
        <div className='cotainer-fluid'>   
            <div className='row animated fadeIn' style={{ marginLeft: 0, marginRight: 0 }}>
                <div className='col-lg-12 mt-3'>
                    <Card className='shadow'>
                        <Card.Header>
                        <strong>Datos del Maestro</strong>
                        </Card.Header>
                        <Card.Body className='text-center'>
                            <div className='row mb-3'>
                                <div className='col-12 text-center mb-3'>
                                    <Avatar
                                        //shape="square"
                                        //size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                        size={80}
                                        src={ URL_IMG_USER }
                                    />
                                </div>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faUserAlt}/> Nombre.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    {master?.nombre} {master?.apellido_paterno} {master?.apellido_materno}
                                    </strong>
                                </div>
                            </div>
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faCalendarDay}/> Fecha de Nacimiento.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    { master?.fecha_nacimiento ? formatearFecha(master?.fecha_nacimiento) : 'Sin Información' }
                                    </strong>
                                </div>
                            </div>
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faAddressCard}/> CURP.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    { master?.curp ? master?.curp : 'Sin Información' }
                                    </strong>
                                </div>
                            </div>
                            
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faPhone}/> Teléfono.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    { master?.telefono ? master?.telefono : 'Sin Información' }
                                    </strong>
                                </div>
                            </div>
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faVenusMars}/> Genero.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    { master?.genero_id ? getGenero(master?.genero_id) : 'Sin Información' }
                                    </strong>
                                </div>
                            </div>
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faGraduationCap}/> Grado.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    {master?.grado_id ? getGrado(master?.grado_id) :  'Sin Información'}
                                    </strong>
                                </div>
                               
                            </div>
                            
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-12 mt-3 mb-3'>
                    <Card className='shadow'>
                        <Card.Header>
                            <strong>Datos de la Escuela</strong>
                        </Card.Header>
                        <Card.Body>
                            <div className='row  mb-3'>
                                <div className='col-12 text-center mb-3'>
                                    <Avatar
                                        size={70}
                                        src={ URL_IMG_USER }
                                    />
                                </div>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faUniversity}/> Nombre de la escuela.</strong>
                                </div>
                                <div className='col-12 text-muted'>
                                    <strong>
                                    {master?.escuela?.nombre}
                                    </strong>
                                </div>
                            </div>
                            <div className='row  mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faCalendarXmark}/> El día <label className='text-danger'>{master?.pago?.dia_limite}</label> es el día de pago de la mensualidad.</strong>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faCircleDollarToSlot}/> Costo de la mensualidad.</strong>
                                </div>
                                <div className='col-12 text-success'>
                                    <strong>
                                    {formatearCantidad(master?.pago?.monto || 0)}
                                    </strong>
                                </div>
                            </div>
                            {master?.pago?.monto_add > 0 &&
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <strong><FontAwesomeIcon icon={faCircleDollarToSlot}/> Costo de la mensualidad si se paga antes del día <label className='text-danger'>{master?.pago?.dia_limite}</label>.</strong>
                                </div>
                                <div className='col-12 text-warning'>
                                    <strong>{formatearCantidad((master?.pago?.monto || 0) - (master?.pago?.monto_add || 0))}</strong>
                                </div>
                            </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        )}
        {edit && (
            <EditMaestro/>
        )}
        <FloatButton 
            icon={!edit ? <EditOutlined /> : <CloseOutlined/>} 
            tooltip={edit ? 'Cerrar' : 'Editar'} 
            type={edit ? '' : "primary"} 
            style={edit ? { right: 24, top: 70 } : { right: 24, bottom: 30 }} 
            onClick={() =>  setEdit(!edit)}                
        />
        </>
    )
}

export default Maestro