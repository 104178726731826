import { faCheckSquare, faClockFour, faCogs, faColonSign, faDashboard, faDollar, faHome, faInfoCircle, faNavicon, faShoppingCart, faSignOut, faTimes, faTrophy, faUserCircle, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Container, Modal, Nav, Navbar, Offcanvas, Badge as BadgeB } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { colorAlumnos, colorEventos, colorProductos, colorSaldos, colorSecundarioAlumnos } from '../../recursos'
import { useDispatch } from 'react-redux';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore'
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore'
import { useArticulosStore } from '../../Hooks/Articulos/useArticulosStore'
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore'
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore'
import { useReSizeWindow } from '../../Hooks/useReSizeWindow'
import { Badge, Divider } from 'antd'

const Header = () => {
      
      const navigate = useNavigate();
      const location = useLocation();

      const [expand, setExpand] = useState(false);
      const [Logout, setLogout] = useState(false);

      const { resetMaestrosStore, master } = useMaestrosStore();
      const { resetCourrentUser, configuracion, getOnlineStatus, courrentUser } = useCourrentUserStore();
      const { resetArticulosStore } = useArticulosStore();
      const { resetEventosStore } = useEventosStore();
      const { resetStudentsStore } = useStudentsStore();
      const { screenIsOnLandscape } = useReSizeWindow();

      const resetAll = () => {
            resetMaestrosStore();
            resetCourrentUser();
            resetArticulosStore();
            resetEventosStore();
            resetStudentsStore();
            navigate('/');
        }
      
      const getColorByRuta = () => {
            switch (location.pathname) {
                  case '/alumnos':
                        return configuracion?.colorNavBar?.alumnos;
                  case '/asistencia':
                        return colorSecundarioAlumnos;
                  case '/productos':
                        return configuracion?.colorNavBar?.equipo;
                  case '/saldos':
                        return configuracion?.colorNavBar?.saldos;
                  case '/eventos':
                        return configuracion?.colorNavBar?.eventos;
                  case '/tablero-puntos':
                        return configuracion?.colorNavBar?.tablero;
                  case '/sobre':
                        return '#cc6601';
                  case '/maestro':
                        return configuracion?.colorNavBar?.maestro;
                  default:
                        return configuracion?.colorNavBar?.inicio;
            }
      }

      const isRutaActual = (ruta) => {
            if (ruta === location.pathname) {
                  return true;
            } else {
                  return false;
            }
      }

      const styleSelected = { background: '#018FB0', borderRadius: 5, padding: 10, color: 'white' };
      
      return (
      <>
      {!screenIsOnLandscape && (
          <Navbar  key={false} style={{ background: getColorByRuta(), position: 'fixed', top: 0, zIndex: 1, width: '100%' }} variant="dark" expand={false} expanded={expand} onToggle={() => setExpand(!expand)}> 
            <Container fluid>
              <Navbar.Brand href="#">
                  {location.pathname === '/inicio' ? (<><FontAwesomeIcon icon={faHome}/> Inicio</>) : null}
                  {location.pathname === '/alumnos' ? (<><FontAwesomeIcon icon={faUserGroup}/> Alumnos</>) : null}
                  {location.pathname === '/asistencia' ? (<><FontAwesomeIcon icon={faCheckSquare}/> Asistencia</>) : null}
                  {location.pathname === '/productos' ? (<><FontAwesomeIcon icon={faShoppingCart}/> Equipo, Artículos y Más...</>) : null}
                  {location.pathname === '/saldos' ? (<><FontAwesomeIcon icon={faDollar}/> Ingresos</>) : null}
                  {location.pathname === '/eventos' ? (<><FontAwesomeIcon icon={faTrophy}/> Torneos, Eventos y Otros...</>) : null}
                  {location.pathname === '/tablero-puntos' ? (<><FontAwesomeIcon icon={faDashboard}/> Tablero Puntos</>) : null}
                  {location.pathname === '/sobre' ? (<><FontAwesomeIcon icon={faInfoCircle}/> Sobre</>) : null}
                  {location.pathname === '/logout' ? (<><FontAwesomeIcon icon={faSignOut}/> Salir</>) : null}
                  {location.pathname === '/maestro' ? (<><FontAwesomeIcon icon={faUserCircle}/> Maestro</>) : null}
                  {location.pathname === '/colores' ? (<><FontAwesomeIcon icon={faNavicon}/> Estilo Barra Navegador</>) : null}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`}/>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-sm`}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm}`}
                style={{ width: '85%' }}
                /* placement="top" */
              >
               <Offcanvas.Header closeButton onClick={() => setExpand(false)}>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  <div className='row'>
                        <div className='col-5'>
                              <img src='/img/logo.png' style={{ width: 100 }}></img>
                        </div>
                        <div className='col-7' style={{ alignSelf: 'center' }}>
                             Academia Ohana App <br></br>
                             {getOnlineStatus() ? <Badge status="processing" text="En línea"/> : <Badge status="error" text="Sin Conexión"/>}
                        </div>
                  </div>    
                  </Offcanvas.Title>
                </Offcanvas.Header> 
                <Offcanvas.Body>
                  <Divider style={{ marginTop: 0, marginBottom: 5 }}/>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    
                    <Nav.Link onClick={() => {navigate('/inicio'); setExpand(false); }} className={!isRutaActual('/inicio') ? "text-muted": "text-white"} style={isRutaActual('/inicio') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faHome}/> Inicio
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/alumnos'); setExpand(false); }} className={!isRutaActual('/alumnos') ? "text-muted": "text-white"} style={isRutaActual('/alumnos') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faUserGroup}/> Alumnos
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/productos'); setExpand(false); }} className={!isRutaActual('/productos') ? "text-muted": "text-white"} style={isRutaActual('/productos') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faShoppingCart}/> Equipo, Artículos y Más...
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/eventos'); setExpand(false); }} className={!isRutaActual('/eventos') ? "text-muted": "text-white"} style={isRutaActual('/eventos') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faTrophy}/> Torneos, Eventos y Otros...
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/saldos'); setExpand(false); }} className={!isRutaActual('/saldos') ? "text-muted": "text-white"} style={isRutaActual('/saldos') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faDollar}/> Ingresos
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/tablero-puntos'); setExpand(false); }} className={!isRutaActual('/tablero-puntos') ? "text-muted": "text-white"} style={isRutaActual('/tablero-puntos') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faDashboard}/> Tablero Puntos
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/colores'); setExpand(false); }} className={!isRutaActual('/colores') ? "text-muted": "text-white"} style={isRutaActual('/colores') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faNavicon}/> Estilo Barras Navegador
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/maestro'); setExpand(false); }} className={!isRutaActual('/maestro') ? "text-muted": "text-white"} style={isRutaActual('/maestro') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faUserCircle}/> Maestro
                    </Nav.Link>
                    <Nav.Link onClick={() => {navigate('/sobre'); setExpand(false); }} className={!isRutaActual('/sobre') ? "text-muted": "text-white"} style={isRutaActual('/sobre') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faInfoCircle}/> Sobre Nosotros
                    </Nav.Link>
                    <Nav.Link onClick={() => {setLogout(true); setExpand(false); }} className={!isRutaActual('/salir') ? "text-muted": "text-white"} style={isRutaActual('/salir') ? styleSelected : {  background: 'white' }}>
                          <FontAwesomeIcon icon={faSignOut}/> Salir
                    </Nav.Link>
                    
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          )}
          
          <Modal show={Logout} centered>
            <Modal.Header>
                Cerrar Sesión
            </Modal.Header>
            <Modal.Body className='text-center'>
            ¿Estas seguro de cerrar sesión?
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={() => setLogout(false)}>
                   <FontAwesomeIcon icon={faTimes}/> Cancelar
                </Button>
                <Button variant='primary' onClick={() => resetAll()}>
                    <FontAwesomeIcon icon={faSignOut} /> Aceptar
                </Button>
            </Modal.Footer>
            </Modal>
          {courrentUser?.isSignedIn && <div style={{ marginBottom: '3.6em' }}></div>}
            
          </>
      )
}

export default Header