import React from 'react';
import './App.css';
import { 
  BrowserRouter as Router,
  Routes,
  Route  
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header/Header';
import { ToastContainer } from 'react-toastify';
import Home from './components/home/Home';
import { useSelector } from 'react-redux';
import Login from './components/login/Login';
import { Badge, Button, Spin } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import Private from './components/login/Private';
import Alumnos from './components/alumnos/Alumnos';
import AlumnosInfo from './components/alumnos/AlumnosInfo';
import ProductosCarrito from './components/productos/ProductosCarrito';
import Eventos from './components/eventos/Eventos';
import EventosEdit from './components/eventos/EventosEdit';
import EventosRegistro from './components/eventos/EventosRegistro';
import Logout from './components/login/Logout';
import Productos from './components/productos/Productos';
import Saldos from './components/saldos/Saldos';
import AlumnoEstadisticas from './components/alumnos/AlumnoEstadisticas';
import TableroPuntos from './components/tablero/TableroPuntos';
import About from './components/about/About';
import Maestro from './components/maestros/Maestro';
import AlumnoInfoQR from './components/alumnos/AlumnoInfoQR';
import './components/styles/animated.css';
import IsAuth from './components/login/IsAuth';
import AlumnosRegistro from './components/alumnos/AlumnosRegistro';
import { useOnline } from './Hooks/useOnline';
import ConfiguracionColores from './components/configuraciones/ConfiguracionColores';
import TableroGlobal from './components/tablero/TableroGlobal';
//import 'sweetalert2/src/sweetalert2.scss';


const App = () =>  {

  const { courrentUser, isLoading } = useSelector(store => store.CourrentUser);

  return (
    <>
    <ToastContainer style={{ opacity: 0.9}}/>
    
    <Router>
      {courrentUser?.isSignedIn && <Header/>}
      <Routes>
        <Route 
          path='/inicio' 
          element={
            <Private>
              <Home/>
            </Private>
          }
        />
        <Route 
          path='/colores' 
          element={
            <Private>
              <ConfiguracionColores/>
            </Private>
          }
        />
        <Route 
          path='/sobre' 
          element={
            <Private>
              <About/>
            </Private>
          }
        />
        <Route 
          path='/maestro' 
          element={
            <Private>
              <Maestro/>
            </Private>
          }
        />
        <Route 
          path='/saldos' 
          element={
            <Private>
              <Saldos/>
            </Private>
          }
        />
        <Route 
          path='/eventos' 
          element={
            <Private>
              <Eventos/>
            </Private>
          }
        >
          <Route 
            path='edit' 
            element={
              <Private>
                <EventosEdit/>
              </Private>
            }
          />
          <Route 
            path='registro' 
            element={
              <Private>
                <EventosRegistro/>
              </Private>
            }
          />
        </Route>
        <Route 
          path='/alumnos' 
          element={
            <Private>
              <Alumnos/>
            </Private>
          }
        >
          <Route path='info' element={<AlumnosInfo/>}/>
          
          <Route path='carrito' element={<ProductosCarrito/>}/>
        </Route>
        <Route path='info-alumno' element={
          <Private>
            <AlumnosInfo/>
          </Private>
        }/>
        <Route path='/carrito' element={
          <Private>
            <ProductosCarrito/>
          </Private>
        }/>
        <Route path='/estadisticas' element={
          <Private>
            <AlumnoEstadisticas/>
          </Private>
        }/>
        <Route path='/tablero-puntos' element={
          <Private>
            <TableroPuntos/>
          </Private>
        }/>
        <Route 
          path='/edit-evento' 
          element={
            <Private>
              <EventosEdit/>
            </Private>
        }/>
        <Route 
          path='/registro-a-evento' 
          element={
            <Private>
              <EventosRegistro/>
            </Private>
        }/>
        <Route 
          path='/productos' 
          element={
            <Private>
              <Productos/>
            </Private>
          }
        ></Route>
        <Route path='/detalle/:token' element={<AlumnoInfoQR/>} ></Route>
        <Route path='/login' element={
            <IsAuth>
              <Login/>
            </IsAuth>
          } ></Route>
        <Route path='/' element={
          <IsAuth>
              <Login/>
          </IsAuth>
          } ></Route>
          <Route path='/tablero-puntos/:token' element={<TableroGlobal/>}/>
        <Route path='/logout' element={<Logout/>} ></Route>
        <Route path='/registro-de-alumnos/:token' element={<AlumnosRegistro/>} ></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
