import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    eventosList: [],
    loadingList: false,
    eventoAlumnos: [],
    eventoNotificaciones: [],
    evento: null
};

export const EventosSlice = createSlice({
    name: 'eventos',
    initialState,
    reducers: {
        setEvento: (state, { payload }) => {
            state.evento = payload;
        },
        setEventoAlumnos: (state, { payload }) => {
            state.eventoAlumnos = payload;
        },
        setEventosList: (state, { payload }) => {
            state.eventosList = payload;
        },
        setEventoNotificaciones: (state, { payload }) => {
            state.eventoNotificaciones = payload;
        },
        setLoadingList: (state, { payload }) => {
            state.loadingList = payload;
        },
        reset: (state) => {
            state.eventosList = [];
            state.loadingList = false;
            state.eventoAlumnos = [];
            state.eventoNotificaciones = [];
            state.evento = null;
        },
    }
});

export const {
    setEvento,
    setEventoAlumnos,
    setEventosList,
    setEventoNotificaciones,
    setLoadingList,
    reset,
} = EventosSlice.actions;