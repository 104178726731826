import React from 'react'
import { useSelector } from 'react-redux';
import NotAuthorized from './NotAuthorized';
import { Navigate } from 'react-router-dom';
import { Card, Result } from 'antd';

import { useReSizeWindow } from '../../Hooks/useReSizeWindow';
import { LockOutlined, MobileOutlined  } from '@ant-design/icons';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';

const Private = ({children}) => {
    const { isSignedIn } = useSelector(store => store.CourrentUser.courrentUser);
    const { master } = useMaestrosStore();
    const { screenIsOnLandscape } = useReSizeWindow();
    
    if (isSignedIn) {
        if (!screenIsOnLandscape) {
          if (master?.estatus === 1) {
            return children;
          } else {
            return (
              <div className='container'>
                <div className='row'>
                  <div className='col-12 text-center'></div>
                   
                      <Result
                        icon={<LockOutlined />}
                        title={
                          <div>
                            <img src='/img/logo.png' style={{ width: 30 }}></img> Por el momento tu usuario no se encuentra activo, comunicate con el administrador para la activación de tu cuenta! <img src='/img/logo.png' style={{ width: 30 }}></img>

                          </div>

                          }
                      />
                </div>
              </div>
            );
          }
             
        } else {
          return <div className='container'>
             <div className='row'>
                <div className='col-lg-12 text-center'></div>
                  <Card className='shadow'>
                    <Result
                      icon={<MobileOutlined />}
                      title={
                      <div>
                          <img src='/img/logo.png' style={{ width: 50 }}></img> Lo sentimos, por el momento solo se encuentra disponible la versión móvil! <img src='/img/logo.png' style={{ width: 50 }}></img>
                        
                        </div>
                        
                        }
                    />
                  </Card>
                  </div>
                
          </div>
        }
    } else {
        return <Navigate to='/'/>;
    }
}

export default Private