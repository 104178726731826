
export const colorAlumnos = '#15297c';//15297c

export const colorSecundarioAlumnos = '#107acc';//107acc

export const colorProductos = '#f57f17';//f57f17

export const colorSaldos = '#c20000';//c20000

export const colorEventos = '#496491';//32cd32

export const colorHome = '#000000';

export const URL_API = 'https://limalama-academy.develogit.com'/* 10.0.2.2:8000 */

export const URL_LOCAL = window.location.host;

export const URL_IMG = URL_API + '/img/students/';
export const URL_GRADOS = URL_API + '/img/grados/';
export const URL_IMG_USER = URL_API + '/img/perfil.png';
export const URL_IMG_PRODUCTO = URL_API + '/img/maestros/';

export const getImageEvento = (tipo) => {
   switch (tipo) {
      case 1:
         return URL_API+'/img/eventos/estatal.jpg'
      case 2:
         return URL_API+'/img/eventos/nacional.jpg';
      case 3: 
         return URL_API+'/img/eventos/examen.png';
      case 4: 
         return URL_API+'/img/eventos/seminario.png';
      case 5:
         return URL_API+'/img/eventos/negros.png';
      case 6: 
         return URL_API+'/img/eventos/conade.jpg';
      case 7: 
         return URL_API+'/img/eventos/mascota.png';
      case 98: /* Equipo */
         return URL_API+'/img/eventos/compras.png';
      case 99: /* Mensualidad */
         return URL_API+'/img/eventos/dinero.jpg';
      default:
         break;
   }
}

export const getImageEventoLocal = (tipo) => {
   switch (tipo) {
      case 1:
         return '/img/eventos/estatal.jpg'
      case 2:
         return '/img/eventos/nacional.jpg';
      case 3: 
         return '/img/eventos/examen.png';
      case 4: 
         return '/img/eventos/seminario.png';
      case 5:
         return '/img/eventos/negros.png';
      case 6: 
         return '/img/eventos/conade.jpg';
      case 7: 
         return '/img/eventos/mascota.png';
      case 98: /* Equipo */
         return '/img/eventos/compras.png';
      case 99: /* Mensualidad */
         return '/img/eventos/dinero.jpg';
      default:
         break;
   }
}

export const getImageGuia = (tipo) => {
   switch (tipo) {
      case 1:
         return URL_API+'/img/guia/menu_alumno.png'
      default:
         break;
   }
}

export const getColorGrado = (key) => {
   if (key >= 1 && key <= 5) {
      switch (key) {
         case 1:
            return '#000000';
         case 2: 
            return '#CA4F13';
         case 3: 
            return '#4A277E';
         case 4: 
            return '#091E8D';
         case 5: 
            return '#015021';
         default:
            return ''
      }
   }
   if (key >= 6 && key <= 9) {
      switch (key) {
         case 6:
            return '#7F4D22';
         case 7: 
            return '#744318';
         case 8: 
            return '#6A3A0F';
         case 9: 
            return '#5F3106';
         default:
            return ''
      }
   }

   if (key > 9) {
      return '#555555';
   }
}

export const getImageBanner = () => {
   let tipo = 3/* Math.floor(Math.random() * (8 - 1) + 1) */;
   switch (tipo) {
      case 1:
         return URL_API+'/img/fondos/img1.jpg'
      case 2:
         return URL_API+'/img/fondos/img2.jpg';
      case 3: 
         return URL_API+'/img/fondos/img3.jpg';
      case 4: 
         return URL_API+'/img/fondos/img4.png';
      case 5: 
         return URL_API+'/img/fondos/img5.jpg';
      case 6:
         return URL_API+'/img/fondos/img6.png';
      case 7: 
         return URL_API+'/img/fondos/img7.jpg';
      case 8: 
         return URL_API+'/img/fondos/rojo.jpg';
      case 9: 
         return URL_API+'/img/fondos/home.png';
      default:
         break;
   }
}

export const alumnos = [
];

export const formatearCantidad = cantidad => {

   return Number(cantidad).toLocaleString("es-MX", { style:"currency", currency:"MXN" });

}

export const generarId = () => {
   const random = Math.random().toString(36).substring(2, 11);
   const fecha = Date.now().toString(36);
   return random + fecha;
}

export const formatearFecha = (fecha) => {
  
   const fechaNueva = new Date(fecha);
   const opciones = {
       year: 'numeric',
       month: 'long',//long, short
       day: '2-digit'
   }
   return fechaNueva.toLocaleDateString('es-ES', opciones);
}

export const formatearFechaHora = (fecha) => {

   let fechaHora = fecha.split('T');
   fechaHora = fechaHora[1].split('.');

   return fechaHora[0];
}

export const formatearFechaMA = (fecha) => {
   const fechaNueva = new Date(fecha);
   const opciones = {
       year: 'numeric',
       month: 'long',//long, short
   }
   return fechaNueva.toLocaleDateString('es-ES', opciones);
}