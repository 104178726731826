import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

const About = () => {

  const [result, setResult] = useState(''); // Para almacenar el resultado del escaneo

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  }

  const handleError = (error) => {
    console.error(error);
  }

  return (
    <div className='cotainer-fluid'>   
      
        <div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
            <div className='col-12 mt-3 h3 text-center'>
              <img src='/img/logo.png' style={{ width: 200 }}></img>
            </div>
            <div className='col-12 mt-3 h3 text-center'>
                Mi Academia Ohana App V1.0
            </div>
            <div className='col-12 h4'>
                <p className='text-muted text-center mt-3'>
                Mi Academia Ohana App es un aplicación creada para maestros del arte marcial limalama, permitiendoles el control de ingresos, eventos de la escuela, venta de equipo, seguimiento de aptitudes de los alumnos, mantiene al tanto al maestro de mensualidades entre otras funciones.
                </p>
            </div>
            <div className='col-lg-12 h4 text-center'>
            Desarrollador.
            </div>
            <div className='col-lg-12 h4 text-center text-muted'>
            ISC. Adrián Fernández Portillo
            </div>
            <div className='col-lg-12 h4 text-center mt-3'>
            Correo Personal.
            </div>
            <div className='col-lg-12 text-center text-muted h4'>
            fernandezadrian2402@gmail.com
            </div>
            <div className='col-lg-12 h4 text-center mt-3'>
            Iniciativa del Proyecto.
            </div>
            <div className='col-lg-12 h4 text-center text-muted'>
            Mtra. Liliana Arce - Academia Ohana
            </div>
            <div className='col-lg-12 mt-3'>
              
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
              />
              <p>Resultado: {result}</p>
            </div>
        </div>
         
    </div>
  )
}

export default About