import { faCalendarDay, faEdit, faPlus, faTrophy, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { colorEventos, formatearCantidad, formatearFecha, getImageEvento } from '../../recursos';
import { globalStyles as css } from '../../recursos/styles';
import { Dropdown, Empty, FloatButton } from 'antd';
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore';
import { PlusOutlined } from '@ant-design/icons';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';

const Eventos = () => {

    const navigate = useNavigate();
    const [index, setIndex] = useState(0);

    const { 
        eventosList,
        eventoAlumnos, 
        evento, 
        getEventoAlumnosStore, 
        getEventosListStore, 
        setEventoStore 
    } = useEventosStore();

    const { configuracion } = useCourrentUserStore();

    useEffect(() => {
        
             getEventosListStore();
        
    }, []);

    useEffect(() => {
        getEventosListStore();
    }, [eventoAlumnos]);

    const condicionFecha = (fecha) => {
        if (index === 0) {
            if (Date.now() <= new Date(fecha)) {
                return true;
            }
            return false;
        } else {
            if (Date.now() > new Date(fecha)) {
                return true;
            }
            return false;
        }
    }

    const onClick = ({ key }) => {
        switch (Number(key)) {
            case 1:
                navigate('/edit-evento');
                break;
            case 2: 
                getEventoAlumnosStore({ evento_id: evento.id });
                navigate('/registro-a-evento');
                break;
            default:
                break;
        }
    };

    const items = [
        {
          label: 'Modificar Evento',
          key: '1',
          icon: <FontAwesomeIcon icon={faEdit}/>
        },
        {
          label: 'Registro de Participantes',
          key: '2',
          icon: <FontAwesomeIcon icon={faUsersGear}/>
        },
      ];


    const styleImagen = {
        width: 70,
        height: 70,
        marginRight: 20,
        borderWidth: 2,
        borderRadius: 5
    };

    return (
        <>
        <div className='cotainer-fluid animated fadeIn'>
            
            <div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                {eventosList.length === 0 && (
                <div style={{ marginTop: '5em' }}>
                    <Empty description={<span>No se econtrarón resultados.</span>} />
                </div>
                )}
                {eventosList.map((evento, index) => {
                    if (condicionFecha(evento.fecha_evento)) {
                        const { id, descripcion, costo, tipo_evento_id, tipo_evento, fecha_evento  } = evento;
                        return (
                        <div className='col-12 p-0' key={index}>
                            
                            <Card className='shadow' style={{ borderRadius: 0 }}>
                                <Card.Body>
                                    <div className='row'>
                                        <div className='col-1' style={{alignSelf: 'center', opacity: 0.2 }}>
                                            <img
                                                style={{...styleImagen}}
                                                src={getImageEvento(tipo_evento_id)}
                                            />
                                        </div>
                                        <div className='col-8'>
                                            <Dropdown
                                                menu={{
                                                    items,
                                                    onClick 
                                                }}
                                                arrow={{
                                                    pointAtCenter: true,
                                                }}
                                                onClick={() => setEventoStore(evento)}
                                            >
                                                <div>
                                                    <div style={css.nombre}>{tipo_evento.descripcion}</div>
                                                    <div style={css.descripcionEvento}>{descripcion}</div>
                                                    <div style={css.fecha}>{formatearFecha(fecha_evento)}</div>
                                                    <div style={{...css.alumnosRegistrados}}>Alumnos registrados: {evento.participaciones.length}</div>
                                                </div>
                                            </Dropdown>
                                        </div>
                                        <div className='col-3 fw-bold text-center' style={{ alignSelf: 'center',fontSize: 14 }}>
                                        {costo > 0 ? formatearCantidad(costo) : '...'}
                                        </div>
                                        
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        )
                    }
                    
                })}
                {/* <Button style={css.btnAddEventos}>
                    <FontAwesomeIcon icon={faPlus} size="2x"/>
                </Button> */}
                
                <div style={{ marginBottom: '4em' }}></div>
                <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0 }}>
                <Button
                    size='large'
                    style={{ borderRadius: 0, background: index === 0 ? `${configuracion?.colorNavBar?.eventos}e5` : configuracion?.colorNavBar?.eventos, borderColor: configuracion?.colorNavBar?.eventos }}
                    onClick={() => setIndex(0)}
                >
                    <FontAwesomeIcon icon={faCalendarDay}/> <br></br>Proximos Eventos
                </Button>
                <Button
                    size='large'
                    style={{ borderRadius: 0, background: index === 1 ? `${configuracion?.colorNavBar?.eventos}e5` : configuracion?.colorNavBar?.eventos, borderColor: configuracion?.colorNavBar?.eventos }}
                    onClick={() => setIndex(1)}
                >
                    <FontAwesomeIcon icon={faTrophy}/> <br></br>Eventos Terminados
                </Button>
                </ButtonGroup>
            </div>
            <FloatButton
                type="primary"
                onClick={() => navigate('edit')}
                tooltip='Nuevo Evento'
                style={{
                    right: 20,
                    bottom: 70,
                }}
                icon={<PlusOutlined />}
            />
        </div>
        <Outlet/>
        
        </>
    )
}

export default Eventos;