import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const warningToast = (mensaje) => {
    toast.warn(
        mensaje, 
        {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const successToast = (mensaje) => {
    toast.success(
        mensaje, 
        {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const warningToastCenter = (mensaje) => {
    toast.warn(
        mensaje, 
        {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const dangerToast = (mensaje) => {
    toast.error(
        mensaje, 
        {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const infoToastCenter = (mensaje) => {
    toast.info(
        mensaje, 
        {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const swalCenterInfo = (mensaje) => {
    Swal.fire({
        title: 'Aviso',
        icon: 'info',
        text: mensaje,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar'
    })
}

export const swalCenterSuccess = (mensaje) => {
    Swal.fire({
        title: 'Aviso',
        icon: 'success',
        text: mensaje,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar'
      })
}

export const swalCenterWarning = (mensaje) => {
    Swal.fire({
        title: 'Aviso',
        icon: 'warning',
        text: mensaje,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar'
      })
}

export const msjError = (error) => {
    let msj = '';
    switch (error.response.status) {
        case 401:
            msj = 'No tienes los permisos autorización para la solicitud.';
            break;
        case 403: 
            msj = 'No tienes los permisos de acceso necesarios para la solicitud';
            break;
        case 404: 
            msj = 'El recurso al que intenta acceder no existe.';
        case 405:
            msj = 'No se púede acceder con el método HTTP solicitado.';
        case 406: 
            msj = 'El recurso de destino no admite el formato de datos solicitado en la cabecera de Accept.';
            break;
        case 409: 
            msj = 'Cambio conflictivo durante un intento de modificación de un recurso.';
            break;
        case 415: 
            msj = 'El recurso de destino no admite el formato de datos del cuerpo de la solicitud especificado en la cabecera de Content-Type.';
            break;
       default: 
            msj = 'Se ha producido un error interno en el servidor.'
            break;
    }
    if (error.response.status === 500) {
        dangerToast(msj);
    } else {
        warningToast(msj);
    }
   

}