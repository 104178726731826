import { SET_EVENTOS_LIST, SET_EVENTO_ALUMNOS, SET_EVENTO_NOTIFICACIONES, SET_LOADING_EVENTOS } from "../constants/EventosTypes";

export const setEvento = (evento) => {
    return {
        type: 'SET_EVENTO',
        payload: evento
    }
} 

export const setEventoAlumnos = (list) => {
    return {
        type: SET_EVENTO_ALUMNOS,
        payload: list
    }
}

export const setEventosList = (eventosList) => {
    return {
        type: SET_EVENTOS_LIST,
        payload: eventosList
    }
}

export const setEventoNotificaciones = (notificaciones) => {
    return {
        type: SET_EVENTO_NOTIFICACIONES,
        payload: notificaciones
    }
}

export const reset = () => {
    return {
        type: 'RESET_EVENTOS_ALL'
    }
}