import { faCar, faCartArrowDown, faDashboard, fack, faListCheck, faPlus, faTag, faTags, faEdit, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, ListGroup, Modal, Form as FormtB, Badge as BadgeB } from 'react-bootstrap';
import { Form, Upload, Button as ButtonAnt, Input, Divider, Avatar, FloatButton, Empty, Alert, Badge, Space, Typography, Modal as ModalAnt } from 'antd';
import { colorProductos, formatearCantidad, URL_API, URL_IMG_PRODUCTO } from '../../recursos';
import { globalStyles as css } from '../../recursos/styles';
import { PictureOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { swalCenterInfo, swalCenterWarning } from '../../recursos/Toast';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { useArticulosStore } from '../../Hooks/Articulos/useArticulosStore';
import Pedidos from './Pedidos/Pedidos';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';

const Productos = () => {

    const { articulosList, ckArticuloStore, getArticulosListStore } = useArticulosStore();
    const { configuracion } = useCourrentUserStore();
    const { 
        getPedidosListStore, 
        articulo, 
        setArticuloStore, 
        addStockStore, 
        editArticuloStore, 
        nuevaTallaArticuloStore,
        deleteTallaStore
    } = useArticulosStore();
    const { master } = useMaestrosStore();
    const [openck, setOpenck] = useState(false);
    const [img, setImg] = useState(null);
    const [index, setIndex] = useState(1);
    const [openStock, setOpenStock] = useState(false);  
    const [talla, setTalla] = useState(''); 
    const [openBorrarTalla, setOpenBorrarTalla] = useState(false);

    useEffect(() => {
      
        getArticulosListStore();  
        getPedidosListStore();
        return () => {
            
        }
    }, []);

    const styleImagen = {
        width: 60,
        height: 60,
        borderRadius: 100,
    };

    const selectArticulo = (articulo) => {
        setArticuloStore(articulo);
        setOpenck(true);
    }

    const selectArticuloStock = (articulo) => {
        setArticuloStore(articulo);
        setOpenStock(true);
    }

    const normFilePortada = (e) => {
      
        if (Array.isArray(e)) {
          return ;
        }
  
        const isPNG = e.file.type === 'image/png';
        const isJPG = e.file.type === 'image/jpeg';
        if (!isPNG && !isJPG) {
            setImg([]);
        } else {
            setImg(e.fileList);
        }
        return e && e.fileList;
    };

    const beforeUpload = file => {
        const isPNG = file.type === 'image/png';
        const isJPG = file.type === 'image/jpeg'
        const valida = false;
        if (!isPNG && !isJPG) {
            swalCenterWarning(`${file.name} no es una imagen permitida.`);
            //message.error(`${file.name} no es una imagen permitida.`);
            valida = true;
        }
  
        return false/* <valida || Upload.LIST_IGNORE> */;
    }

    const guardarArticulo = (values) => {
        console.log(values);
        let formData  = new FormData();
        formData.append('id', articulo?.id || '');
        formData.append('costo', values?.costo);
        formData.append('descripcion', values?.descripcion);
        formData.append('img', img ? img[0].originFileObj : null);
        
        editArticuloStore(formData);
        setOpenck(false); 
        setArticuloStore(null); 
        setImg(null);
    }

    const guardarStock = () => {
        if (articulo?.tallas?.filter(talla => talla?.stockAdd && talla?.stockAdd !== '').length > 0) {
            addStockStore(JSON.stringify(articulo?.tallas));
        } else {
            swalCenterInfo('Por favor ingrese alguna cantidad a agregar.');
        }
       
    }

    const addTalla = (e) => {
        e.preventDefault();
        
        if (talla !== '') {
            nuevaTallaArticuloStore({ descripcion: talla, articulo_id: articulo.id });
            setTalla('');
        }

    }

    return (
        <>
        <div className='cotainer-fluid'>
            
            {index === 1 && (
            <>
            <div className='row' style={{ marginLeft: 2, marginRight: 2, marginBottom: 70 }}>
                {articulosList.length === 0 && (
                <div style={{ marginTop: '5em' }}>
                    <Empty description={<span>No se econtrarón resultados.</span>} />
                </div>
                )}
                
                {articulosList.map((articulo, index) => {
                    const { descripcion, costo, img } = articulo;
                    return (
                    <div className='col-lg-12 p-0' key={index}>
                
                            <Card className='shadow-sm' key={index} style={{ borderRadius: 0 }}>
                                <Card.Body>
                                    <div className='row'>
                                        {img ? (
                                        <div className='col-3' style={{ alignSelf: 'center'  }}>
                                            <Avatar
                                                shape="square"
                                                size={70}
                                                src={`${URL_API}/img/maestros/${master.token_file}/articulos/${img}`}
                                            />
                                        </div>
                                        ) : (
                                        <div className='col-3' style={{ alignSelf: 'center'  }}>
                                            <Avatar
                                                className='shadow-sm'
                                                shape="square"
                                                icon={<PictureOutlined/>}
                                                size={70}
                                                
                                            />
                                        </div>
                                        )}
                                        <div className='col-5' style={{ alignSelf: 'center'  }}>
                                            <p style={{...css.nombre}}>
                                                {descripcion}.
                                            </p>
                                            <p style={{...css.apellidos}}>{formatearCantidad(costo)}</p>
                                            
                                        </div>
                                        <div className='col-4 text-center' style={{ alignItems: 'center', alignSelf: 'center' }}>
                                            <ButtonGroup>
                                            <Button className='text-white' variant='warning' onClick={() => selectArticulo(articulo)} style={{ borderRadius: 0 }}>
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </Button>
                                            <Button className='text-white' variant='info' onClick={() => selectArticuloStock(articulo)} style={{ borderRadius: 0 }}>
                                                <FontAwesomeIcon icon={faTags}/>
                                            </Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className='col-12'>
                                            <Divider style={{ marginTop: 0, marginBottom: 0 }}>Inventario</Divider>
                                            {articulo?.tallas.map(talla => {
                                                return (
                                                    <BadgeB bg={Number(talla?.stock || 0) === 0 ? 'danger' : 'primary'} text={Number(talla?.stock || 0) === 0 ? 'white' : 'white'} className={Number(talla?.stock || 0) === 0 ? 'parpadea' : ''} style={{ marginRight: 5, marginBottom: 5 }} >
                                                        {talla?.descripcion}
                                                        {` [${talla?.stock || 0}]`}
                                                       
                                                    </BadgeB>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        
                    </div>
                    )
                })}
            </div>
            <FloatButton
                type="primary"
                onClick={() => {setOpenck(true); setArticuloStore(null); setImg(null);}}
                tooltip='Nuevo Articulo'
                style={{
                    right: 20,
                    bottom: 70,
                }}
                icon={<PlusOutlined />}
            />
            </>
            )}
            
            {index === 2 && (
                <>
                <Pedidos/>
                </>
            )}
            <div style={{ marginBottom: '4em' }}></div>
            <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0, width: '100%', zIndex: 10 }}>
                    
                <Button
                    size='large'
                    style={{ borderRadius: 0, background:  index === 1 ? `${configuracion?.colorNavBar?.equipo}e5` : configuracion?.colorNavBar?.equipo, borderColor: configuracion?.colorNavBar?.equipo }}
                    onClick={() => setIndex(1)}
                >
                    <FontAwesomeIcon icon={faCartArrowDown}/> <br></br>Articulos
                </Button>
                
                <Button
                    size='large'
                    style={{ borderRadius: 0, background: index === 2 ? `${configuracion?.colorNavBar?.equipo}e5` : configuracion?.colorNavBar?.equipo, borderColor: configuracion?.colorNavBar?.equipo }}
                    onClick={() => setIndex(2)}
                >
                    <FontAwesomeIcon icon={faListCheck}/> <br></br>Pedidos   
                </Button>
                
            </ButtonGroup>
        </div>

        <Modal
            show={openck}
            onHide={() => setOpenck(false)} 
            centered
        >       
            <Modal.Body>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form
                            layout='vertical'
                             fields={[
                                { name: ['id'], value: articulo?.id || '' },
                                { name: ['descripcion'], value: articulo?.descripcion || '' },
                                { name: ['costo'], value: articulo?.costo || '' },
                            ]}
                            onFinish={guardarArticulo}
                        >
                            <div className='row'>
                                {articulo?.img && (
                                <div className='col-lg-8 text-center mb-1'>
                                     <Avatar
                                        className='shadow-sm'
                                        shape="square"
                                        src={`${URL_IMG_PRODUCTO}${master.token_file}/articulos/${articulo?.img}`}
                                        size={90}                
                                    />
                                    <Divider></Divider>
                                </div>
                                )}
                                <div className='col-lg-8'>
                                    <Form.Item
                                        name="img"
                                        label="Foto del Articulo"
                                        valuePropName="fileList"
                                        tooltip="Este es un campo requerido."
                                        getValueFromEvent={normFilePortada}
                                        beforeUpload={beforeUpload}
                                    >
                                        <Upload 
                                            name="img"
                                            action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                            listType="picture" 
                                            maxCount={1}
                                            beforeUpload={beforeUpload}
                                        >
                                            <ButtonAnt icon={<UploadOutlined />}>Haga clic para cargar.</ButtonAnt>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Descripción"
                                        name="descripcion"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa la descripción del articulo!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa una descripción"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Costo"
                                        name="costo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el costo del articulo!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa un costo"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <ButtonAnt
                                        type="primary" 
                                        htmlType="submit"
                                        shape="round"
                                        block
                                    >
                                        Guardar
                                    </ButtonAnt>
                                </div>
                            </div>
                            
                        </Form>
                    </div>
                </div>
                
            </Modal.Body>       
        </Modal>

        <Modal
            show={openStock}
            onHide={() => setOpenStock(false)} 
            fullscreen
        >       
            <Modal.Header closeButton>
                <strong>Articulo Stock</strong>
            </Modal.Header>
            <Modal.Body>
                <div className='row justify-content-center'>
                    {articulo?.img ? (
                    <div className='col-lg-8 text-center mb-1'>
                    
                        <Avatar
                            className='shadow-sm'
                            shape="square"
                            src={`${URL_IMG_PRODUCTO}${master.token_file}/articulos/${articulo?.img}`}
                            size={70}
                                                
                        />
                    </div>
                    ) : (
                        <div className='col-lg-8 text-center mb-1'>
                            <Avatar
                                className='shadow-sm'
                                shape="square"
                                icon={<PictureOutlined/>}
                                size={70}                
                            />
                        </div>
                    )}
                    <div className='col-lg-12 h6 text-center'>
                        {articulo?.descripcion}
                    </div>
                    <div className='col-lg-12 h6 text-center'>
                        {formatearCantidad(articulo?.costo)}
                    </div>
                    <div className='col-12 mb-2'>
                    <Divider>Agregar talla</Divider>
                    <Space
                        align="center"
                        style={{
                        padding: '0 8px 4px',
                        }}
                    >
                        <Input 
                            placeholder="Ingresa la talla" 
                            value={talla} 
                            onChange={(e) => setTalla(e.target.value)} 
                        />
                        <Typography.Link
                            onClick={addTalla}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <PlusOutlined /> Agregar
                        </Typography.Link>
                    </Space>
                    <Divider>Stock</Divider>
                    </div>
                    <div className='col-lg-12 mb-1'>
                        <Alert type='info' description='Para agregar articulos al stock, solo ingrese el número de piezas que decea agregar y seleccione el boton con el signo de mas para agregar.'/>
                    </div>
                    <div className='col-lg-12 mb-1'>
                    <table width={'100%'} className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '20%' }}>Talla</th>
                                <th style={{ width: '30%' }} className='text-center'>Pzas</th>
                                <th style={{ width: '20%' }}></th>
                                <th style={{ width: '20%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {articulo?.tallas?.map(talla => {
                            return (
                                <tr>
                                    <td>{talla?.descripcion}</td>
                                    <td className={talla?.stock === 0 ? 'text-center parpadea text-danger' : 'text-center'}>{talla?.stock || 0}</td>
                                    <td>
                                    <FormtB.Control 
                                        type='number'
                                        size='sm' 
                                        value={talla?.stockAdd || ''}
                                        onChange={(e) => {
                                            setArticuloStore({
                                                ...articulo,
                                                tallas: articulo?.tallas?.map(data => {
                                                    let newData = {...data, stockAdd: data?.stockAdd || ''};
                                                    if (data?.id === talla?.id) {
                                                        newData.stockAdd = Number(e.target.value)
                                                    }
                                                    return newData;
                                                })
                                            });
                                        }}
                                    /> 
                                    </td>
                                    <td className='text-center'>
                                        <Button 
                                            variant='danger'
                                            size='sm'
                                            onClick={() => {
                                                setOpenBorrarTalla(true);
                                                setTalla(talla);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                    <div className='col-lg-12 mt-3'>
                        <ButtonAnt
                            type="primary"
                            shape="round"
                            block
                            onClick={guardarStock}
                        >
                            Guardar
                        </ButtonAnt>
                    </div>
                </div>
                
            </Modal.Body>       
        </Modal>
        
        <ModalAnt
            zIndex={10000}
            open={openBorrarTalla}
            onCancel={() => {
                setOpenBorrarTalla(false);
                setTalla('');
            }}
            onOk={() => {
                deleteTallaStore({ id: talla?.id, articulo_id: talla?.articulo_id });
                setOpenBorrarTalla(false);
                setTalla('');
            }}
            okText={'Aceptar'}
            cancelText={'Cancelar'}
            centered
        >
            <p>¿Esta seguro de eliminar la talla seleccionada <strong>({talla?.descripcion})</strong>?</p>
           
        </ModalAnt>
        </>
    )
}

export default Productos