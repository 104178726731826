import { faCalendar, faCartPlus, faCheckCircle, faTimesCircle, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Badge, Card } from 'react-bootstrap'
import { URL_GRADOS, URL_IMG, URL_IMG_USER, colorEventos, formatearCantidad, formatearFecha, getColorGrado, getImageEvento } from '../../../recursos';
import {
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined, } from '@ant-design/icons';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { Avatar, Empty, List, Tabs } from 'antd';
import { useReSizeWindow } from '../../../Hooks/useReSizeWindow';
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';
import { globalStyles } from '../../../recursos/styles';

const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));




const AlumnoGeneralQR = () => {

    //const params = useParams();
    const { student, getAlumnoQRStore } = useStudentsStore();
    const { screenIsOnLandscape } = useReSizeWindow();
    const { master } = useMaestrosStore();

    const [items, setItems] = useState([]);
    const [data, setData] = useState([]);

    const getDataParticipacion = (participacion) => {
        let newArray = [];
        if (participacion?.p_puntos)
            newArray.push('Pelea Puntos');
        if (participacion?.p_equipo)
            newArray.push('Pelea en Equipo');
        if (participacion?.p_continua)
            newArray.push('Pelea Continua');
        if (participacion?.p_full)
            newArray.push('Fullcontact');
        if (participacion?.f_estricta)
            newArray.push('Forma Estricta');
        if (participacion?.f_estricta_equipo)
            newArray.push('Forma Estructa en Equipo');
        if (participacion?.f_arma)
            newArray.push('Forma con Arma');
        if (participacion?.f_arma_equipo)
            newArray.push('Forma con Arma en Equipo');
        if (participacion?.f_creativa)
            newArray.push('Forma Creativa');
        if (participacion?.f_creativa_equipo)
            newArray.push('Forma Creativa en Equipo');
        return newArray;
    }

    const condicionFecha = (fecha) => {
        if (Date.now() > new Date(fecha)) {
            return true;
        }
        return false;
        
    }
    
    useEffect(() => {
        
        setItems([
            {
            key: '1',
            label: `Torneos`,
            children: <div className='mt-0 p-0'>
            {student?.participaciones?.filter(participacion => (
                participacion?.evento?.tipo_evento_id === 1 ||
                participacion?.evento?.tipo_evento_id === 2 ||
                participacion?.evento?.tipo_evento_id === 5 ||
                participacion?.evento?.tipo_evento_id === 6
            )).length > 0 ? (
            <>
                {student?.participaciones?.map(participacion => {
                    if (
                        (participacion?.evento?.tipo_evento_id === 1 ||
                        participacion?.evento?.tipo_evento_id === 2 ||
                        participacion?.evento?.tipo_evento_id === 5 ||
                        participacion?.evento?.tipo_evento_id === 6 ) && 
                        condicionFecha(participacion?.evento?.fecha_evento || '00-00-0000')
                    )
                    return (
                        <Card className='shadow mb-1' style={{ borderRadius: 0 }}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-2' style={{alignSelf: 'center', opacity: 0.8}}>
                                        <Avatar
                                            shape='circle'
                                            size={screenIsOnLandscape ? 60 : 50}
                                            src={getImageEvento(participacion?.evento?.tipo_evento_id)}
                                        />
                                    </div>
                                    <div className='col-6' style={{ alignSelf: 'center' }}>
                                        <div style={globalStyles.nombre}>{participacion?.evento?.tipo_evento?.descripcion}</div>
                                        <div style={globalStyles.descripcionEvento}>{participacion?.evento?.descripcion}</div>
                                        <div style={{ color: 'brown' }}>
                                        <strong>
                                        {formatearFecha(participacion?.evento?.fecha_evento || '00-00-0000')}
                                        </strong>
                                        </div>
                                    </div>
                                    <div className='col-4 ' style={{ alignSelf: 'center',fontSize: 14 }}>
                                    {getDataParticipacion(participacion).map(data => {
                                        return <li><Badge pill bg='secondary'>{data}</Badge></li>
                                    })}
                                    </div>
                                    
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })}
            </>
            ) : (
            <Empty
                description={
                <span>
                No se econtrarón resultados.
                </span>
                }
            />
            )}
            
            
            </div>,
            },
            {
            key: '2',
            label: `Examenes`,
            children: <>
            {student?.participaciones?.filter(participacion => participacion?.evento?.tipo_evento_id === 3).length > 0 ? (
                student?.participaciones?.map(participacion => {
                    if (
                        participacion?.evento?.tipo_evento_id === 3 && 
                        condicionFecha(participacion?.evento?.fecha_evento || '00-00-0000')
                    )
                    return (
                        <Card className='shadow mb-1' style={{ borderRadius: 0 }}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-2' style={{alignSelf: 'center', opacity: 0.8}}>
                                        <Avatar
                                            shape='circle'
                                            size={screenIsOnLandscape ? 60 : 50}
                                            src={getImageEvento(participacion?.evento?.tipo_evento_id)}
                                        />
                                    </div>
                                    <div className='col-6' style={{ alignSelf: 'center' }}>
                                        <div style={globalStyles.descripcionEvento}>{participacion?.evento?.descripcion}</div>
                                        
                                    </div>
                                    <div className='col-4 ' style={{ alignSelf: 'center',fontSize: 14, color: 'brown' }}>
                                    <strong>
                                        {formatearFecha(participacion?.evento?.fecha_evento || '00-00-0000')}
                                        </strong>
                                    </div>
                                    
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })
            ) : (
            <Empty
                description={
                <span>
                No se econtrarón resultados.
                </span>
                }
            />
            )}
            </>,
            },
            {
            key: '3',
            label: `Seminarios`,
            children: <>
            {student?.participaciones?.filter(participacion => (
                 participacion?.evento?.tipo_evento_id === 4
            ))?.length > 0 ? (
            
                student?.participaciones?.map(participacion => {
                    if (
                        participacion?.evento?.tipo_evento_id === 4 && 
                        condicionFecha(participacion?.evento?.fecha_evento || '00-00-0000')
                    )
                    return (
                        <Card className='shadow mb-1' style={{ borderRadius: 0 }}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-2' style={{alignSelf: 'center', opacity: 0.8}}>
                                        <Avatar
                                            shape='circle'
                                            size={screenIsOnLandscape ? 60 : 50}
                                            src={getImageEvento(participacion?.evento?.tipo_evento_id)}
                                        />
                                    </div>
                                    <div className='col-6' style={{ alignSelf: 'center' }}>
                                        <div style={globalStyles.descripcionEvento}>{participacion?.evento?.descripcion}</div>
                                        
                                    </div>
                                    <div className='col-4 ' style={{ alignSelf: 'center',fontSize: 14, color: 'brown' }}>
                                    <strong>
                                        {formatearFecha(participacion?.evento?.fecha_evento || '00-00-0000')}
                                        </strong>
                                    </div>
                                    
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })
            ) : (
            <Empty
                description={
                <span>
                No se econtrarón resultados.
                </span>
                }
            />
            )}
            </>,
            },
            {
            key: '4',
            label: `Otros`,
            children: <>
            {student?.participaciones?.filter(participacion => (
                 participacion?.evento?.tipo_evento_id === 7
            ))?.length > 0 ? (
            
                student?.participaciones?.map(participacion => {
                    if (
                        participacion?.evento?.tipo_evento_id === 7 && 
                        condicionFecha(participacion?.evento?.fecha_evento || '00-00-0000')
                    )
                    return (
                        <Card className='shadow mb-1' style={{ borderRadius: 0 }}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-2' style={{alignSelf: 'center', opacity: 0.8}}>
                                        <Avatar
                                            shape='circle'
                                            size={screenIsOnLandscape ? 60 : 50}
                                            src={getImageEvento(participacion?.evento?.tipo_evento_id)}
                                        />
                                    </div>
                                    <div className='col-6' style={{ alignSelf: 'center' }}>
                                        <div style={globalStyles.descripcionEvento}>{participacion?.evento?.descripcion}</div>
                                        
                                    </div>
                                    <div className='col-4' style={{ alignSelf: 'center',fontSize: 14, color: 'brown' }}>
                                    <strong>
                                        {formatearFecha(participacion?.evento?.fecha_evento || '00-00-0000')}
                                        </strong>
                                    </div>
                                    
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })
              
            ) : (
            <Empty
                description={
                <span>
                No se econtrarón resultados.
                </span>
                }
            />
            )}
            </>,
            },
        ]);
    }, [])
    

    const total = (concepto) => {
        if (student?.adeudos.length > 0) {
 
            let data = student?.adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return Number(data[0].total);
            } else {
                return 0;
            }
            
        } else {
            return 0;
        }
    };

    const onChange = (key) => {
        //console.log(key);
    };
    
    return (
        <div className="container-fluid">
            <div className='row justify-content-center'>
                {master === null && (
                <div className='col-lg-10'>
                    {screenIsOnLandscape ? (
                    <Card className='shadow p-3 mt-3' style={{ borderWidth: 5, borderColor: getColorGrado(student?.grado_id)/* , background: '#0C1F2E' */, opacity: 0.9}}>
                            <Card.Body>
                            <div className='row text-center'>
                                <div className='col-lg-4' style={{ alignSelf: 'center' }}>
                                    <Avatar
                                        size={screenIsOnLandscape ? 100 : 50}
                                        src={student?.urlimg !== null ? `${URL_IMG}${student?.token}/${student?.urlimg}` : URL_IMG_USER }
                                    />
                                </div>
                                <div className='col-lg-6 text-start' style={{ alignSelf: 'center' }}>
                                    <h3>Alumno</h3>
                                    <h3 style={{ color: '#5FA8D1' }}>{student?.nombre} {student?.apellido_paterno} {student?.apellido_materno}</h3>
                                    <h3 className="mt-3" style={{ color: getColorGrado(student.grado_id) }}>{student?.grado?.color} - {student?.grado?.nombre}</h3>

                                
                                </div>
                                <div className='col-lg-2' style={{ alignSelf: 'center' }}>
                                    <Avatar
                                        shape='square'
                                        size={100}
                                        src={`${URL_GRADOS}${student?.grado_id}-png.png`}
                                    />  
                                </div>
                            </div>
                            </Card.Body>
                    </Card>
                    ) : (
                    <Card className='shadow' style={{borderColor: student?.estatus === 1 ? 'green' : 'red' }}>
                            <div className='row p-2'>
                                <div className='col-3' style={{ alignSelf: 'center' }}>
                                    <Avatar
                                        size={screenIsOnLandscape ? 100 : 80}
                                        src={student?.urlimg !== null ? `${URL_IMG}${student?.token}/${student?.urlimg}` : URL_IMG_USER }
                                    />
                                </div>
                                <div className='col-5' style={{ alignSelf: 'center' }}>
                                    <p style={{...styleNombre, color: student?.estatus === 1 ? '#496491' : 'gray'}}>
                                        {student?.nombre}.
                                    </p>
                                    <p style={styleApellidos}>{student?.apellido_paterno} {student?.apellido_materno}.</p>
                                </div>
                                <div className='col-4 text-center' style={{ alignSelf: 'center' }}>
                                    <img
                                        style={{ ...styleImagen, marginTop: 5,  marginRight: 5, borderRadius: 0,  marginVertical: 0, width: 50, height: 50 }}
                                        src={`${URL_GRADOS}${student?.grado_id}-png.png`}
                                    /><br></br>
                                    <strong>{student?.grado?.color} - {student?.grado?.nombre}</strong>
                                </div>
                            </div>
                    </Card>
                    )}
                </div>
                )}
                <div className='col-lg-12 mt-3 mb-3 p-0'>
                    <Tabs centered defaultActiveKey="1" items={items} onChange={onChange} />
                </div>
        
            </div>
        </div>
    )
};

const styleImagen = {
    width: 60,
    height: 60,
    borderRadius: 100,
};

const styleNombre = {
    color: '#2F2F2F',
    fontSize: 15,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: 2
};

const styleApellidos = {
    fontSize: 17,
    color: '#64748B',
    marginBottom: 2
};


export default AlumnoGeneralQR