import clienteAxios from '../../config/axios';
import { infoToastCenter, swalCenterWarning, warningToast, warningToastCenter } from '../../recursos/Toast';
import { 
    SET_CURRENTUSER,
    SET_LOADINGCURRENTUSER,
    SET_ERRORCURRENTUSER  
} from "../constants/CurrentUserTypes";

export function loginAccess ( username, password) {
    return ( dispatch ) => {
        dispatch( setLoading(true) );
        clienteAxios.post('login',
            { 
                'username': username, 
                'password': password 
            }).then( response => {

            if (response.status === 200) {
                const hoy = Date.now();
                const f = hoy + (response.data.expires_in * 1000);
                
                let currentUser = { 
                    isSignedIn: true,
                    token_type: response.data.token_type,
                    expires_in: response.data.expires_in,
                    dateExpire: f,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                };
           
                dispatch( setCurrentUser (currentUser) );
                window.location.replace('/');
            } 

            

        }).catch( error => {
            swalCenterWarning('Datos de usuario incorrectos.');
        }).finally(final => dispatch( setLoading(false) ) );  

    }
};

export const registrarUsuario = (username, password) => {
    return (dispatch) => {
        clienteAxios.post('users/create-user-app',
            { 
                'email': username, 
                'password': password 
            }).then( response => {

            if (response.data.success) {
                
                let user = { 
                    id: 0,
                    name: '',
                    email: response.data.usuario,
                    psw: response.data.psw,
                    role_id: 0
                };
           
                dispatch( setUsuario (user) );
                window.location.replace('/');
            }
            infoToastCenter(response.data.message);

        }).catch( error => {
            warningToastCenter('Ocurrio un error, intente de nuevo.');
        }).finally(final => dispatch( setLoading(false) ) ); 
    }
}

export const setUsuario = (usuario) => {
    return {
        type: 'SET_USUARIO',
        payload: usuario
    }
}

export const setConfiguracion = (conf) => {
    return {
        type: 'SET_CONFIGURACION',
        payload: conf
    }
}

export const setCurrentUser = (currentUser) => {
    return {
        type: SET_CURRENTUSER,
        payload: currentUser
    }
}

export const reset = () => {
    return {
        type: 'RESET_COURRENTUSER'
    }
}

export const setLoading = (loading) => {
    return {
        type: SET_LOADINGCURRENTUSER,
        payload: loading
    }
}