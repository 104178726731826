import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { formatearCantidad } from '../../../recursos'
import { Button, InputNumber, Card, Form } from 'antd'
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore'
import { swalCenterInfo } from '../../../recursos/Toast'
import ModalBorrarRegistroPago from '../modals/ModalBorrarRegistroPago'

const ArticulosAlumno = () => {

    const [form] = Form.useForm();
    const { student, loading, aplicarPagoStore, setLoadingStore } = useStudentsStore();
    const [idFlag, setIdFlag] = useState(false);
    const [registro, setRegistro] = useState(null);

    const total = (concepto) => {
        if (student.adeudos.length > 0) {
 
            let data = student.adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return Number(data[0].total);
            } else {
                return 0;
            }
            
        } else {
            return 0;
        }
    };

    const aplicarPagoEquipo = (values) => {
        setLoadingStore(true);
        let pagoEquipo = values.pagoEquipo;
        let pago = pagoEquipo;

        if (pagoEquipo > total(2)) {
            pago = total(2);
            aplicarPagoStore( student.id, 2, Number(pago));
            swalCenterInfo('Regresar '+formatearCantidad(pagoEquipo - total(2))+' de cambio');
           /*  setCambio(pagoEquipo - total(2)); */
        } else {
            aplicarPagoStore(student.id, 2, Number(pagoEquipo));
        } 
        form.resetFields();
    }


    return (
        <>
        <Card className='shadow' title={
            <div className='col-lg-12 h5 text-center' style={{color: 'orange'}} onClick={() => setIdFlag(!idFlag)}>
                <FontAwesomeIcon icon={faCartPlus}/> Equipo 
            </div>
            }
        >
            <div className='row'>
                <div className='col-lg-12'>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                            <th scope="col">Desc</th>
                            <th scope="col">Precio</th>
                            <th scope="col" className='text-center'>Cant</th>
                            <th scope="col">Total</th>
                            <th scope="col">Abonado</th>
                            </tr>
                        </thead>
                        <tbody>
                        {student?.compras?.map((compra, index) => {
                            if(compra?.pagado < compra?.total && compra?.concepto_id === 2 && compra?.cantidad > 0){
                                return (
                                    <tr key={index} onClick={() => idFlag ? setRegistro(compra) : ' '}>
                                        <td className={idFlag ? 'text-primary' : ''}>
                                        {compra.descripcion}
                                        </td>
                                        <td>{formatearCantidad(compra.costo)}</td>
                                        <td className='text-center'>{compra.cantidad}</td>
                                        <td>{formatearCantidad(compra.total)}</td>
                                        <td>{formatearCantidad(compra.pagado)}</td>
                                    </tr>
                                )
                                
                            }
                            return null;
                        })}
                        {student?.compras?.filter(com => com.pagado < com.total && com.concepto_id === 2).length === 0 &&
                            <tr>
                                <td className='text-center' colSpan={6}>No se encontrarón pagos pendientes</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-12 text-end mt-2'>
                    <strong>Total a pagar: </strong> {formatearCantidad(total(2))}
                </div>
                
            </div>
            {total(2) > 0 && (
            <div className='row'>
                <div className='col-lg-12 text-end mt-2'>
                    <Form layout="horizontal" onFinish={aplicarPagoEquipo} form={form}>
                        <Form.Item
                            name="pagoEquipo"
                            rules={[{ required: true, message: 'Por favor ingrese una cantidad!' }]}
                        >
                            <InputNumber 
                                min={1} 
                                size="large" 
                                disabled={loading}
                                style={{width: '100%'}}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ background: 'orange' }}
                                shape="round" 
                                size='large'
                                loading={loading}
                            >
                                Abonar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            )}
        </Card>
        <ModalBorrarRegistroPago 
            open={registro !== null ? true : false}
            setOpen={() => setRegistro(null)}
            registro={registro}
        />
        </>
    )
}

export default ArticulosAlumno