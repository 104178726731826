import React, { useEffect, useState } from 'react'
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { Card, Divider } from 'antd';
import { colorEventos, formatearCantidad, formatearFecha } from '../../../recursos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useReSizeWindow } from '../../../Hooks/useReSizeWindow';
import { useCourrentUserStore } from '../../../Hooks/CourrentUser/useCourrentUserStore';
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';

const RecibosQR = () => {

    const { student } = useStudentsStore();
    const [recibosList, setRecibosList] = useState([]);
    const { screenIsOnLandscape } = useReSizeWindow();
    const { master } = useMaestrosStore();

    useEffect(() => {
      if (student) {
        let newArray = [];

        student?.adeudos[0].pagos?.forEach(data => {
            newArray.push({...data, concepto: 'Mensualidad', concepto_id: 1});
        });
        student?.adeudos[1].pagos?.forEach(data => {
            newArray.push({...data, concepto: 'Equipo', concepto_id: 2});
        });
        student?.adeudos[2].pagos?.forEach(data => {
            newArray.push({...data, concepto: 'Evento/Torneo', concepto_id: 3});
        });

        newArray = newArray.sort((a, b) => {
            if (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) {
                return -1;
              }
              if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
                return 1;
              }
          
              return 0;
        })
        setRecibosList(newArray);
        
      }
    }, []);
    

    return (
        <div className="container-fluid">
            <div className='row '>
                {/* <div className='col-lg-12 text-start' hidden={master ? true : false}>
                    <FontAwesomeIcon icon={faCircle} color='green'/> El color verde representa el pago de mensualidades, <br></br>
                    <FontAwesomeIcon icon={faCircle} color='orange'/> El color naranja representa el pago equipo. <br></br>
                    <FontAwesomeIcon icon={faCircle} color={colorEventos}/> El color azul representa pago de eventos o troneos.
                </div>
                <div className='col-lg-12'><Divider/></div> */}
                {recibosList?.map((pago , index) => {
                    return (
                    <div className='col-lg-6 mb-2' key={index}>
                    <Card
                        className={screenIsOnLandscape ? '' : 'shadow'}
                        hoverable
                        style={{ borderLeftWidth: 5, borderLeftColor: pago?.concepto_id === 1 ? 'green' : pago?.concepto_id === 2 ? 'orange' : colorEventos, height: '100%', background: '#F7F7F7' }}
                    >
                        
                        <div className='row' >
                            <div className='col-1' style={{alignSelf: 'center', opacity: 0.2 }}>
                                <img
                                    style={{
                                        width: 120,
                                        height: 110,
                                }}
                                    src={student?.maestro_id === 3 ? '/img/recibologo.png' : '/img/recibologot.png'}
                                />
                                
                            </div>
                            <div className='col-11'>
                                <div className='row'>
                                    <div className='col-3 text-start'>
                                        <strong>Nombre:</strong>
                                    </div>
                                    <div className='col-9 border-bottom border-dark text-start'>{student?.nombre} {student?.apellido_paterno} {student?.apellido_materno}</div>
                                    <div className='col-3 text-start '>
                                        <strong>Concepto:</strong>
                                    </div>
                                    <div className='col-9 border-bottom border-dark text-start'>
                                        {pago?.concepto} {pago?.descripcion !== null && `(${pago?.descripcion})`}
                                    </div>
                                    <div className='col-3 text-start'>
                                        <strong>Monto:</strong>
                                    </div>
                                    <div className='col-9 border-bottom border-dark text-start'>{formatearCantidad(pago?.monto || 0)}</div>
                                    <div className='col-3 text-start'>
                                        <strong>Fecha:</strong>
                                    </div>
                                    <div className='col-9 border-bottom border-dark text-start'>{formatearFecha(pago?.fecha+'T23:00:00')}</div>
                                </div>
                            </div>
                            
                        </div>
                    </Card>
                    </div>
                    )
                })}
               
            </div>
        </div>
    )
}

export default RecibosQR