import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';


const IsAuth = ({children}) => {
    const { isSignedIn } = useSelector(store => store.CourrentUser.courrentUser);
    
    
    if (isSignedIn) {
        return <Navigate to='/inicio'/>;
    } else {
        return children;
    }
}

export default IsAuth