import { createSlice } from "@reduxjs/toolkit";
import { colorAlumnos, colorEventos, colorProductos, colorSaldos } from "../recursos";


const initialState = {
    courrentUser : {
        isSignedIn: false,
        token_type: '',
        expires_in: 0,
        dateExpire: 0,
        access_token: '',
        refresh_token: ''
    },
    isLoading: false,
    error: false,
    usuario: {
        id: 0,
        name: '',
        email: '',
        psw: '',
        role_id: 0
    },
    configuracion: {
        vistaAlumno: 1,
        grafica: 1,
        cardAlumno: {
            totalMensualidad: false,
            totalEquipo: false, 
            totalEventos: false,
            totalGeneral: true,
            grado: true,
            notificacion: false
        },
        colorNavBar: {
            inicio: '#000000',
            alumnos: colorAlumnos,
            maestro: 'purple',
            equipo: colorProductos,
            saldos: colorSaldos,
            eventos: colorEventos,
            tablero: '#000000'
        }
    }
};

export const CourrentUserSlice = createSlice({
    name: 'courrentUser',
    initialState,
    reducers: {
        setUsuario: (state, { payload }) => {
            state.usuario = payload;
        },
        setConfiguracion: (state, { payload }) => {
            state.configuracion = payload;
        },
        setCurrentUser: (state, { payload }) => {
            state.courrentUser = payload;
        },
        setLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        reset: (state) => {
            state.courrentUser  =  {
                isSignedIn: false,
                token_type: '',
                expires_in: 0,
                dateExpire: 0,
                access_token: '',
                refresh_token: ''
            };
            state.isLoading =  false;
            state.error =  false;
            state.usuario =  {
                id: 0,
                name: '',
                email: '',
                psw: '',
                role_id: 0
            };
            state.configuracion =  {
                vistaAlumno: 1,
                grafica: 1,
                cardAlumno: {
                    totalMensualidad: true,
                    totalEquipo: true, 
                    totalEventos: true,
                    totalGeneral: true,
                    grado: true
                },
                colorNavBar: {
                    inicio: '#000000',
                    alumnos: colorAlumnos,
                    maestro: 'purple',
                    equipo: colorProductos,
                    saldos: colorSaldos,
                    eventos: colorEventos,
                    tablero: '#000000'
                }
            };
        },
    }
});

export const {
    setUsuario,
    setConfiguracion,
    setCurrentUser,
    setLoading,
    reset
} = CourrentUserSlice.actions;