import { FloatButton, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import { formatearCantidad } from '../../recursos';
import { Card } from 'react-bootstrap';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { SettingOutlined } from '@ant-design/icons';
import ButtonsPdfSaldos from './buttons/ButtonsPdfSaldos';

const Saldos = () => {

    const [date, setDate] = useState(null);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const [concepto, setConcepto] = useState(0);
    const { saldos, getSaldosStore } = useMaestrosStore();

    useEffect(() => {

        let fecha = new Date(Date.now());
        fecha = fecha.toISOString().split('T')[0];

        let fecha_inicio = fecha;
        fecha_inicio = fecha_inicio.split('-');

        fecha_inicio = `${fecha_inicio[0]}-${fecha_inicio[1]}-01`;

        let fechaLocal = fecha.split('-');
        let fechaUltimo = new Date(fechaLocal[0], fechaLocal[1]);
        fechaUltimo.setDate(fechaUltimo.getDate() - 1);
        fechaUltimo = fechaUltimo.toISOString().split('T')[0];

        getSaldosStore({fecha_inicio: fecha_inicio, fecha_fin: fechaUltimo});

        fechaUltimo = fechaUltimo.split('-');
        setDate(`${fechaUltimo[0]}-${fechaUltimo[1]}`);
       
    }, []);

    const changeFecha = (fecha) => {
        
        if (fecha){
            setDate(fecha);
            let fechaLocal = fecha.split('-');
            let fechaUltimo = new Date(fechaLocal[0], fechaLocal[1]);
            fechaUltimo.setDate(fechaUltimo.getDate() - 1);
            fechaUltimo = fechaUltimo.toISOString().split('T')[0];
    
            let fecha_inicio = fechaUltimo;
            fecha_inicio = fecha_inicio.split('-');
            fecha_inicio = `${fecha_inicio[0]}-${fecha_inicio[1]}-01`;
    
            getSaldosStore( {fecha_inicio: fecha_inicio, fecha_fin: fechaUltimo});
        }
        
        
    };

    const porcentajeTipo = (tipo) => {
        const concepto = saldos !== null ? saldos.concepto : null;

        if (concepto !== null) {
            let total = concepto ? (Number(concepto.mensualidad) + Number(concepto.eventos) + Number(concepto.equipo)) : 0;

            switch(tipo) {
                case 1: 
                    return total !== 0 ? ( ( Number(concepto.mensualidad)/total)*100 ) : 0;
                case 2: 
                    return total !== 0 ? ( ( Number(concepto.equipo)/total )*100 ) : 0;
                case 3: 
                    return total !== 0 ? ( ( Number(concepto.eventos)/total)*100 ) : 0;
            }
        }

        return 0;
        

    }

    const totalGeneral = () => {

        let total = saldos?.concepto?.mensualidad || 0;
        total = total + (saldos?.concepto?.equipo || 0);
        total = total + (saldos?.concepto?.eventos || 0);

        return total;

    }

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Mensualidades', 'Equipo', 'Eventos'],
            datasets: [
                {
                    data: [porcentajeTipo(1).toFixed(2), porcentajeTipo(2).toFixed(2), porcentajeTipo(3).toFixed(2)],
                    
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-600'), 
                        documentStyle.getPropertyValue('--orange-600'), 
                        documentStyle.getPropertyValue('--blue-600')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-400'), 
                        documentStyle.getPropertyValue('--orange-400'), 
                        documentStyle.getPropertyValue('--blue-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '50%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [saldos]);

    return (
        <>
        <div className='row' style={{ marginRight: 3, marginLeft: 3 }}>
            <div className='col-lg-12 h4 text-center mt-2'>
                Reporte Mensual
            </div>
            <div className='col-lg-12 text-center form-group'>
                <input
                    type="month"
                    className="form-control"
                    onChange={e => changeFecha(e.target.value)}
                    value={date || ''}
                />
            </div>
            <div className='col-lg-12 mt-3'>
                <Card className='shadow'>
                    <Card.Body>
                        <Chart type="doughnut" data={chartData} options={chartOptions} />
                        <div className='col-lg-12 mt-3 text-justify'>
                            <strong>Mensualidades:</strong> {formatearCantidad(saldos?.concepto?.mensualidad || 0)}<br></br>
                            <strong>Equipo:</strong> {formatearCantidad(saldos?.concepto?.equipo || 0)}<br></br>
                            <strong>Eventos:</strong> {formatearCantidad(saldos?.concepto?.eventos || 0)}<br></br><br></br>
                            <strong>Total General:</strong> {formatearCantidad(totalGeneral())}
                        </div>
                    </Card.Body>
                </Card>

            </div>
            
        </div>
        <div className='row mt-3' style={{ marginRight: 3, marginLeft: 3 }}>
            <div className='col-lg-12'>
                <table className="table table-sm table-striped table-bordered shadow" >
                    <thead>
                        <tr className="bg-success text-white">
                            <th colSpan={4} className="text-center">Mensualidades</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Alumno</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                    {saldos?.historialConcepto?.mensualidad.map((dato, index) => {
                        return (
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{dato?.adeudo?.alumno?.nombre || ''} {dato?.adeudo?.alumno?.apellido_paterno || ''}</td>
                                <td>{dato.fecha}</td>
                                <td>{formatearCantidad(dato.monto)}</td>
                            </tr>
                        )
                    })}
                    {saldos?.historialConcepto?.mensualidad?.length === 0 &&
                        <tr>
                            <td className='text-center' colSpan={4}>No se encontrarón resultados</td>
                        </tr>
                    }
                    <tr>
                        <td className='text-end' colSpan={3}>
                            <strong>Total: </strong>
                        </td>
                        <td colSpan={1}>
                            {formatearCantidad(saldos?.concepto?.mensualidad || 0)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>    

            <div className='col-lg-12'>
                <table className="table table-sm table-striped table-bordered shadow">
                    <thead>
                        <tr className="bg-danger text-white">
                            <th colSpan={4} className="text-center">Equipo</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Alumno</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                    {saldos?.historialConcepto?.equipo.map((dato, index) => {
                        return (
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{dato?.adeudo?.alumno?.nombre || ''} {dato?.adeudo?.alumno?.apellido_paterno || ''}</td>
                                <td>{dato.fecha}</td>
                                <td>{formatearCantidad(dato.monto)}</td>
                            </tr>
                        )
                    })}
                    {saldos?.historialConcepto?.equipo?.length === 0 &&
                        <tr>
                            <td className='text-center' colSpan={4}>No se encontrarón resultados</td>
                        </tr>
                    }
                    <tr>
                        <td className='text-end' colSpan={3}>
                            <strong>Total: </strong>
                        </td>
                        <td colSpan={1}>
                            {formatearCantidad(saldos?.concepto?.equipo || 0)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className='col-lg-12'>
                <table className="table table-sm table-striped table-bordered shadow" >
                    <thead>
                        <tr className="bg-primary text-white">
                            <th colSpan={4} className="text-center">Eventos</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Alumno</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                    {saldos?.historialConcepto?.eventos.map((dato, index) => {
                        return (
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{dato?.adeudo?.alumno?.nombre || ''} {dato?.adeudo?.alumno?.apellido_paterno || ''}</td>
                                <td>{dato.fecha}</td>
                                <td>{formatearCantidad(dato.monto)}</td>
                            </tr>
                        )
                    })}
                    {saldos?.historialConcepto?.eventos?.length === 0 &&
                        <tr>
                            <td className='text-center' colSpan={4}>No se encontrarón resultados</td>
                        </tr>
                    }
                    <tr>
                        <td className='text-end' colSpan={3}>
                            <strong>Total: </strong>
                        </td>
                        <td colSpan={1}>
                            {formatearCantidad(saldos?.concepto?.eventos || 0)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>   
        </div>
        <FloatButton.Group
            trigger="click"
            type="primary"
            size="large"
            style={{
                right: 24,
                bottom: 24,
            }}
            icon={<SettingOutlined />}
        >
            <ButtonsPdfSaldos/>
        </FloatButton.Group>
        </>
    )
}
 
export default Saldos