import { 
    SET_ARTICULOS, 
    SET_ARTICULO, 
    SET_LOADING_ARTICULO, 
    SET_LOADING_ARTICULOS 
} from "../constants/ArticulosTypes";

export const setArticulosList = (list) => {
    return {
        type: SET_ARTICULOS,
        payload: list
    }
}

export const setLoadingList = (loading) => {
    return {
        type: SET_LOADING_ARTICULOS,
        payload: loading
    }
}

export const setLoading = (loading) => {
    return {
        type: SET_LOADING_ARTICULO,
        payload: loading
    }
}

export const  reset = () => {
    return {
        types: 'RESET_ARTICULOS_ALL'
    }
}