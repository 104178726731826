import clienteAxios from "../config/axios";

export const getGradosService = async (token) => {
    return await clienteAxios.get('catalogos/grados',
        {
            headers: { 'Authorization' : token }
        });
};

export const getEstadosService = async (token) => {
    return await clienteAxios.get('catalogos/estados',
        {
            headers: {
                'Authorization' : token
            }
        });
};

export const getMunicipiosService = async (token) => {
    return await clienteAxios.get('catalogos/municipios',
        {
            headers: {
                'Authorization' : token
            }
        });
};

export const getTipoEventosService = async (token) => {
    return await clienteAxios.get('catalogos/tipo-eventos',
        {
            headers: {
                'Authorization' : token
            }
        });
}