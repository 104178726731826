import { SET_MASTER, SET_LOADINGMASTER, SET_ERRORMASTER } from "../constants/MasterTypes";

export const setSaldos = (saldos) => {
    return {
        type: 'SET_SALDOS',
        payload: saldos
    }
}

export const setMaster = (masterData) => {
    return {
        type: SET_MASTER,
        payload: masterData
    }
}

export const setLoading = (flag) => {
    return {
        type: SET_LOADINGMASTER,
        payload: flag
    }
}

export const setError = (flag) => {
    return {
        type: SET_ERRORMASTER,
        payload: flag
    }
}

export const reset = () => {
    return {
        type: 'RESET_MASTER'
    }
}

export const setEstiloGrafica = (estilo) => {
    return {
        type: 'SET_GRAFICA_ESTILO',
        payload: estilo
    }
}

export const setSelEst = (set) => {
    return {
        type: 'SET_EST',
        payload: set
    }
}