import { Button, FloatButton, Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect } from 'react'
import { useCatalogosStore } from '../../Hooks/Catalogos/useCatalogosStore';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { Card } from 'react-bootstrap';
import { SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditMaestro = () => {

    const [form] = Form.useForm();

    const { gradosList, getGradosStore } = useCatalogosStore();
    const { master, crearMaestroStore, actualizarMaestroStore } = useMaestrosStore();

    useEffect(() => {
        if (master) {
            form.setFieldValue('nombre', master?.nombre || '');
            form.setFieldValue('apellidoPaterno', master?.apellido_paterno || '');
            form.setFieldValue('apellidoMaterno', master?.apellido_materno || '');
            form.setFieldValue('curp', master?.curp || '');
            form.setFieldValue('telefono', master?.telefono || '');
            form.setFieldValue('genero_id', master?.genero_id || null);
            form.setFieldValue('escolaridad_id', master?.escolaridad_id?.toString() || null);
            form.setFieldValue('grado_id', master?.grado_id || null);
            form.setFieldValue('monto', master?.pago?.monto || '');
            form.setFieldValue('montoAdd', master?.pago?.monto_add || '');
            form.setFieldValue('diaLimite', master?.pago?.dia_limite || '');
            form.setFieldValue('escuela', master?.escuela?.nombre || '');
        }
        
        getGradosStore();
    }, [])
    

    const saveMasterData = (values) => {
        const params = {
            id: master?.id || null,
            nombre: values.nombre,
            apellido_paterno: values.apellidoPaterno,
            monto: values.monto,
            dia_limite: values.diaLimite,
            monto_add: values.montoAdd,
            escuela: values.escuela,
            apellido_materno: values.apellidoMaterno,
            curp: values.curp,
            telefono: values.telefono,
            genero_id: values.genero_id,
            escolaridad_id: values.escolaridad_id,
            grado_id: values.grado_id
        };
  
        if (master === null) {
            crearMaestroStore(params);
        } else {
            actualizarMaestroStore(params);
        }
       
        
       
    }
    

    return (
        <>
        <div className='container-fluid'>
            <div className='row'>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={saveMasterData}
                >
                <div className='col-lg-12 mt-3'>
                    <Card className='shadow'>
                        <Card.Header>
                            <strong>Datos del Maestro</strong>
                        </Card.Header>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Nombre del maestro"
                                        name="nombre"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese nombre del maestro!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Input
                                            placeholder="Ingrese el nombre del maestro"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Apellido Paterno"
                                        name="apellidoPaterno"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese el apellido paterno del maestro!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Input
                                            placeholder="Ingrese el apellido paterno del maestro"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Apellido Materno"
                                        name="apellidoMaterno"
                                    >
                                        <Input
                                            placeholder="Ingrese el apellido materno del maestro"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="CURP"
                                        name="curp"
                                    >
                                        <Input
                                            placeholder="Ingrese la curp del maestro"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Télefono"
                                        name="telefono"
                                    >
                                        <Input
                                            placeholder="Ingrese el telefono del maestro"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="genero_id"
                                    label="Genero"
                                >
                                    <Select placeholder="Selecciona el genero." size="large">
                                        <Option value={1}>Masculio</Option>
                                        <Option value={2}>Femenino</Option>
                                        <Option value={3}>Otro</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="escolaridad_id"
                                    label="Escolaridad"
                                >
                                    <Select placeholder="Selecciona el genero." size="large">
                                        <Option value="1">Preescolar</Option>
                                        <Option value="2">Primaria</Option>
                                        <Option value="3">Secundaria</Option>
                                        <Option value="4">Media superior</Option>
                                        <Option value="5">Superior</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="grado_id"
                                    label="Grado"
                                >
                                    <Select placeholder="Selecciona un grado." size="large">
                                    {gradosList.map((grado, index) => (
                                        <Option key={index} value={grado.id}>{`${grado.color}-${grado.nombre}`}</Option>
                                    ) )}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Card.Body>
                    </Card>
                          {/*   <Form.Item>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    className="login-form-button" 
                                    shape="round" 
                                    block
                                >
                                Guardar
                                </Button>
                            </Form.Item> */}
                        
                </div>
                <div className='col-lg-12 mb-3 mt-3'>
                    <Card className='shadow'>
                        <Card.Header>
                            <strong>Datos de la Escuela</strong>
                        </Card.Header>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-12 mb-2'>
                                    <Form.Item 
                                        label="Nombre de la Escuela"
                                        name="escuela"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese el nombre de la escuela!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Input
                                            placeholder="Ingrese el nombre de la escuela"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Día"
                                        name="diaLimite"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese el día en que cobra mensualidad!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <InputNumber
                                            placeholder="Día en que cobra mensualidad"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Monto mensualidad"
                                        name="monto"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese el monto de la mensualidad!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <InputNumber
                                            placeholder="Ingresa el monto de la mensualidad"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-12'>
                                    <Form.Item 
                                        label="Descuento Promoción"
                                        name="montoAdd"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Por favor ingrese el descuento de la promoción!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <InputNumber
                                            placeholder="Ingresa el descuento de la promoción"
                                            style={{ width: '100%' }} 
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                                
                                
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <FloatButton
                    type="primary"
                    onClick={() => form.submit()}
                    //htmlType="submit"
                    tooltip='Guardar'
                    style={{
                        right: 24, 
                        bottom: 30,
                    }}
                    icon={<SaveOutlined />}
                />
                </Form>
            </div>
        </div>
        
        </>
    )
}

export default EditMaestro;