import React from 'react';
import { Avatar, Divider } from 'antd';
import { URL_GRADOS, URL_IMG, URL_IMG_USER } from '../../../recursos';
import { globalStyles } from '../../../recursos/styles';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCartPlus, faDashboard, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';

const ModalMenuOpciones = ({ open, setOpen }) => {

    const navigate = useNavigate();

    const { 
        student,
        siguienteAlumno,
        anteriorAlumno
    } = useStudentsStore();

    return (
        <Modal 
            show={open}
            onHide={() => setOpen(false)}
            centered
        >
            <Modal.Body className='p-0' style={{ background: '#F8F9FA', borderRadius: 10}}>
            <div className='row p-3 justify-content-center'>
                <div className='col-3' style={{alignSelf: 'center' }} >
                    <Avatar
                        size={60}
                        src={student?.urlimg !== null ? `${URL_IMG}${student?.token}/${student?.urlimg}` : URL_IMG_USER }
                    />
                </div>
                <div className='col-6' style={{alignSelf: 'center' }}>
                    <p style={{...globalStyles.nombre, color: student?.estatus === 1 ? '#496491' : 'gray'}}>
                        {student?.nombre}.
                    </p>
                    <p style={{...globalStyles.apellidos}}>{student?.apellido_paterno} {student?.apellido_materno}.</p>
                    
                </div>
                <div className='col-3'>
                    <Avatar
                        shape='square'
                        size={60}
                        src={`${URL_GRADOS}${student?.grado_id}-png.png`}
                    />
                </div>
                
            </div>
            <div className='col-12'>
                <Divider style={{ marginBottom: 0, marginTop: 0 }}></Divider>
            </div>
            <div className="d-grid gap-2">
                
                <Button size='lg' variant='light' style={{ textAlign: 'left', borderRadius: 0 }} onClick={() => navigate('/info-alumno')} className='shadow-sm'>
                    <FontAwesomeIcon icon={faInfoCircle}/> Información y Pagos
                </Button>
                <Button size='lg' variant='light' style={{ textAlign: 'left', borderRadius: 0 }} onClick={() => navigate('/carrito')} className='shadow-sm'>
                    <FontAwesomeIcon icon={faCartPlus}/> Ir al Carrito de Compras
                </Button>
                {/* <Button size='lg' variant='light' style={{ textAlign: 'left', borderRadius: 0 }} onClick={() => navigate('/estadisticas')} className='shadow-sm'>
                    <FontAwesomeIcon icon={faDashboard}/> Estadisticas
                </Button> */}
                
            </div>
            {/* <div className='col-lg-12'>
                <Divider style={{ marginTop: 1, marginBottom: 0 }}></Divider>
            </div> */}
            </Modal.Body>
            <Modal.Footer style={{ background: '#F8F9FA' }}>
                <div className='col-12'>
                    <Button variant='light' onClick={() => anteriorAlumno()} style={{ float: 'left', borderRadius: 100 }}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                    </Button>
                    <Button variant='light' onClick={() => siguienteAlumno()} style={{ float: 'right', borderRadius: 100 }}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalMenuOpciones