import clienteAxios from '../config/axios';

export const getArticulosListService = async (token) => {
    return await clienteAxios.get('articulos',
    {
        headers: { 'Authorization' : token }
    });
}

export const editArticuloService = async  (params, token) => {
    return await clienteAxios.post('articulos/edit', params,
        {
            headers: { 'Authorization' : token }
        });
}

export const addCompraService = async (params, token) => {
    return await clienteAxios.post('articulos/compra', params,
        { 
            headers: { 'Authorization': token } 
        });
} 

export const addAdeudoArticuloService = async  (params, token) => {
    return await clienteAxios.post('articulos/add-adeudo-articulo', params, 
        { 
            headers: { 'Authorization': token } 
    });
} 

export const nuevaTallaArticuloService = async  (params, token) => {
    return await clienteAxios.post('articulos/add-talla-articulo', params, 
        { 
            headers: { 'Authorization': token } 
    });
} 

export const nuevoPedidoService = async  (token) => {
    return await clienteAxios.post('articulos/nuevo-pedido', {}, 
        { 
            headers: { 'Authorization': token } 
    });
} 

export const setEstatusPedidoService = async  (params, token) => {
    return await clienteAxios.post('articulos/set-estatus-pedido', params, 
        { 
            headers: { 'Authorization': token } 
    });
} 

export const getPedidosListService = async  (token) => {
    return await clienteAxios.get('articulos/pedidos', 
        { 
            headers: { 'Authorization': token } 
    });
}

export const addStockService = async  (params, token) => {
    return await clienteAxios.post('articulos/add-stock', params, 
        { 
            headers: { 'Authorization': token } 
    });
} 

export const deleteTallaService = async  (params, token) => {
    let paramsUrl = `id=${params?.id}&articulo_id=${params?.articulo_id}`;
    return await clienteAxios.delete('articulos/delete-talla?'+paramsUrl, 
        { 
            headers: { 'Authorization': token } 
    });
} 