import { colorEventos, colorProductos, colorSecundarioAlumnos } from ".";

export const btnFlotante = {
    backgroundColor: 'dark', 
    borderColor: 'dark',
    position: 'fixed', 
    bottom: 70, 
    right: 10, 
    borderRadius: 100, 
    width: 55, height: 55, 
    alignItems: 'center',
    opacity: 0.8
    /* shadowColor: "#000", */
    /* shadowOffset: {
        width: 0,
        height: 4,
    }, 
    shadowOpacity: 0.30,
    shadowRadius: 4.65,
    elevation: 8,
     */
};

export const globalStyles = {
    imagen: {
        width: 70,
        height: 70,
        marginRight: 20,
        borderRadius: 100,
        borderWidth: 2
    },
    nombre: {
        color: '#2F2F2F',
        fontSize: 15,
        fontWeight: '700',
        textTransform: 'uppercase',
        marginBottom: 2
    },
    apellidos: {
        fontSize: 16,
        color: '#64748B',
        marginBottom: 2
    },
    fecha: {
        fontWeight: '700',
        color: '#DB2777'//DB2777
    },
    cantidad: {
        fontSize: 15,
        fontWeight: '700',
        color: 'orange' 
    },
    descripcionEvento: {
        fontSize: 15,
        color: '#64748B',
        marginBottom: 2
    },
    alumnosRegistrados: {
        fontWeight: '700',
        color: 'brown'
    },
    btnFlotante: {
        backgroundColor: 'dark', 
        borderColor: 'dark',
        position: 'fixed', 
        bottom: 70, 
        right: 10, 
        borderRadius: 100, 
        width: 55, height: 55, 
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        }, 
        shadowOpacity: 0.30,
        shadowRadius: 4.65,
        elevation: 8
    },
    barraInferiorFlotante: { position: 'fixed', bottom: 0, padding: 0 },
    btnAddEventos: {
        ...btnFlotante,
        backgroundColor: colorEventos, 
        borderColor: colorEventos
    },
    btnAddProductos: {
        ...btnFlotante,
        backgroundColor: colorProductos, 
        borderColor: colorProductos,
        bottom: 10
    },
    encabezadoModalTop: {
        position: 'fixed', 
        top: 0, 
        zIndex: 1, 
        width: '100%'
    }
};
