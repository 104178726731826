
import { useDispatch, useSelector } from 'react-redux';
import { useCourrentUserStore } from '../CourrentUser/useCourrentUserStore';
import { 
    setSaldos,
    setMaster,
    setLoading,
    setError,
    reset,
    setEstiloGrafica,
    setSelEst
 } from '../../store/MasterSlice';
import { actualizarMaestroService, actualizarPagosService, changeEstatusMensualidadService, createMasterService, getDataMasterService, getSaldosService, manageTokenService } from '../../Services/MaestrosServices';
import { dangerToast, swalCenterInfo, swalCenterSuccess, swalCenterWarning, warningToast, warningToastCenter } from '../../recursos/Toast';
import { useStudentsStore } from '../Students/useStudentsStore';
import { useEventosStore } from '../Eventos/useEventosStore';

export const useMaestrosStore = () => {

    const dispatch = useDispatch();

    const { 
        master,
        loading,
        error,
        graficaEstilo,
        selEst,
        saldos
    } = useSelector(store => store.Master);

    const setSaldosStore = (data) => {
        dispatch( setSaldos(data) );
    }
    const setMaestroStore = (data) => {
        dispatch( setMaster(data) );
    }
    const setLoadingStore = (data) => {
        dispatch( setLoading(data) );
    }
    const setErrorStore = (data) => {
        dispatch( setError(data) );
    }
    const resetMaestrosStore = () => {
        dispatch( reset() );
    }
    const setEstiloGraficaStore = (data) => {
        dispatch( setEstiloGrafica(data) );
    }
    const setSelEstStore = (data) => {
        dispatch( setSelEst(data) );
    }

    const { getEventosNotificacionesStore } = useEventosStore();
    const { getStudentsListStore } = useStudentsStore();
    
    const {
        courrentUser
    } = useSelector(store => store.CourrentUser);

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    const crearMaestroStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingStore(true);
        createMasterService(params, token)
        .then( response => {
            if (response?.status === 200) {
                swalCenterSuccess(response?.data?.message);
                getDatosMaestroStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            setErrorStore(true); 
        }).finally(finallly => {
            setLoadingStore(false);
        }); 
    };

    const actualizarMaestroStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingStore(true);
        actualizarMaestroService(params, token)
        .then( response => {
            if (response?.status === 200) {
                swalCenterSuccess(response?.data?.message);
                getDatosMaestroStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
            
        }).catch( error => {
            setErrorStore(true); 
        }).finally(finallly => {
            setLoadingStore(false);
        });
    };

    const getDatosMaestroStore = (tokenLocal = token) => {

        if (!navigator.onLine) {
            return ;
        }
        
        setLoadingStore(true);
        getDataMasterService(tokenLocal)
        .then( response => {
            if (response?.status === 200) {
                if(response?.data?.nombre)
                setMaestroStore(response?.data);
            } else {
                warningToast('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos.');
            setErrorStore(true); 
        }).finally(finallly => {
            setLoadingStore(false);
        }); 
    };

    const manageTokenStore = (accion) => {

        if (!navigator.onLine) {
            return ;
        }
        
            manageTokenService(accion, token)
            .then(response => {
                if (response?.status === 200) {
                    swalCenterSuccess(response?.data?.message);
                    getDatosMaestroStore();
                } else {
                    warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
                }
            })
            .catch(error => {
                dangerToast('Algo ocurrio, intenta de nuevo.');
            });
    };

    const changeEstatusMensualidadStore = () => {

        if (!navigator.onLine) {
            return ;
        }
        
        changeEstatusMensualidadService(token)
        .then(response => {
            if (response?.status === 200) {
                swalCenterInfo(response?.data?.message);
                getDatosMaestroStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        })
        .catch(error => {
            dangerToast('Algo ocurrio, intenta de nuevo.');
        });
    };

    const actualizarPagosStore = () => {

        if (!navigator.onLine) {
            return ;
        }
        
        actualizarPagosService(token)
        .then(response => {
            if (response.status === 200) {
                getStudentsListStore()
                getEventosNotificacionesStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
        })
        .catch(error => {
            dangerToast('Algo ocurrio al ejecutar la actualización de pagos, intenta de nuevo.');
        })
    };

    const getSaldosStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }
        
        getSaldosService(params, token)
        .then( response => {
            if (response?.status === 200) {
                setSaldosStore(response?.data);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos, intentelo nuevamente.');
        });
    }
  
    return {
        /* parametros */
        master,
        loading,
        error,
        graficaEstilo,
        selEst,
        saldos,
        /* funciones */
        setSaldosStore,
        setMaestroStore,
        setLoadingStore,
        setErrorStore,
        resetMaestrosStore,
        setEstiloGraficaStore,
        setSelEstStore,
        crearMaestroStore,
        actualizarMaestroStore,
        getDatosMaestroStore,
        manageTokenStore,
        changeEstatusMensualidadStore,
        actualizarPagosStore,
        getSaldosStore,
    };
}
