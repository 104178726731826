import { FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';
import { URL_API, URL_IMG } from '../../../recursos';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import XDate from "xdate";

export const centrarTextoPDF = (doc, texto) => {
  let fontSize = doc.internal.getFontSize();
  let anchoDocumento = doc.internal.pageSize.width;
  let txtWidth =
    (doc.getStringUnitWidth(texto) * fontSize) / doc.internal.scaleFactor;
  let x = (anchoDocumento - txtWidth) / 2;
  return x;
};

const ButtonPDFReporteAlumnos = () => {

  const {
    master
  } = useMaestrosStore();

  const { 
      studentsList
  } = useStudentsStore();


  const getEscolaridad = (key) => {

    switch (key) {
      case 1:
        return 'Preescolar';
      case 2:
        return 'Primaria';
      case 3:
        return 'Secundaria';
      case 4:
        return 'Media superior';
      case 5:
        return 'Superior';
      default:
        return '';
    }
  }

  const exportarDocumento = () => {

    const doc = new jsPDF('landscape');
    let x = centrarTextoPDF(doc, "");
    doc.setFontSize(8);
    doc.setFont("", "bold");
    doc.setFontSize(12);
  
    autoTable(doc, {
      //cabecero
      didDrawPage: (data) => {
        data.doc.addImage(
          `/img/logo.png`,
          260, //izq
          6, //arri
          20, //anch
          20 //alto
        );
        data.doc.addImage(`/img/logo.png`, 10, 6, 20, 20);
        data.table.width = data.settings.tableWidth;
        
        data.doc.setFontSize(14);
        x = centrarTextoPDF(doc, master?.escuela?.nombre?.toUpperCase());
        data.doc.text(master?.escuela?.nombre?.toUpperCase(), x, 12);
        
        x = centrarTextoPDF(doc, 'REPORTE DE ALUMNOS');
        data.doc.text('REPORTE DE ALUMNOS', x, 17);
        data.doc.setFontSize(9);
        x = centrarTextoPDF(doc, `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`);
        data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, x, 22);
      },
    });

    let arrayDataPdf = [];

    studentsList?.forEach(data => {
      if (data?.estatus === 1)
        arrayDataPdf.push(data);
    });
    console.log(arrayDataPdf);
    autoTable(doc, {
      startY: 35,
      styles: {
        fontSize: 6,
      },
      head: [[
        'Curp',
        'Nombre',
        'Apellido Paterno',
        'Apellido Materno',
        'Género',
        'Escolaridad',
        'Estado',
        'Municipio',
        'Colonia',
        'Calle',
        'Número Exterior',
        'Número Interior',
        'Correo',
        'Télefono',
        'Grado'
      ]],
      body: [ ...arrayDataPdf?.map(data => {
        if (data?.estatus === 1)
        return [
          { content: data?.curp || '' },
          { content: data?.nombre || '' },
          { content: data?.apellido_paterno || '' },
          { content: data?.apellido_materno || '' },
          { content: data?.genero_id === 1 ? 'Masculino' : data?.genero_id === 2 ? 'Femenino' : 'Otro' },
          { content: getEscolaridad(data?.escolaridad_id) },
          { content: data?.direccion?.estado?.nombre || '' },
          { content: data?.direccion?.municipio?.nombre || '' },
          { content: data?.direccion?.colonia || '' },
          { content: data?.direccion?.calle || '' },
          { content: data?.direccion?.no_ext || 'N/A'},
          { content: data?.direccion?.no_int || 'N/A' },
          { content: data?.correo || '' },
          { content: data?.telefono || '' },
          { content: data?.grado?.nombre || ''}
        ]
      }) ]
      /* [content: newData?.documento,] */,
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
        `${String(i)}/${pageCount}`,
        doc.internal.pageSize.width - 10,
        287,
        { align: "right" }
        );
    }

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setTextColor('gray');
      doc.text(
      `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`,
      10,
      287,
      { align: "left" }
      );
    }
  
    doc.save(`Reporte Alumnos ${XDate().toString("dd-MM-yyyy HH.mm.ss")}.pdf`);
  }

  return (
    <FloatButton 
      tooltip={<div>Imprimir Reporte PDF</div>} icon={<PrinterOutlined />} 
      onClick={exportarDocumento}
    />
  )
}

export default ButtonPDFReporteAlumnos