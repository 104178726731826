import React from 'react'
import { reset as resetArticulos } from '../../redux/actions/Articulos.actions';
import { reset as resetEventos} from '../../redux/actions/Eventos.actions';
import { reset as resetMaster } from '../../redux/actions/Master.actions';
import { reset as resetStudents } from '../../redux/actions/Student.actions';
import { reset as resetCourrentUser } from '../../redux/actions/CurrentUser.actions';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resetAll = () => {
        dispatch( resetMaster() );
        dispatch( resetCourrentUser() );
        dispatch( resetArticulos() );
        dispatch( resetEventos() );
        dispatch( resetStudents() );
        
    }

    return (
        <Modal show centered>
            <Modal.Header>
                Cerrar Sesión
            </Modal.Header>
            <Modal.Body className='text-center'>
            ¿Estas seguro de cerrar sesión?
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={() => navigate(-1)}>
                   <FontAwesomeIcon icon={faTimes}/> Cancelar
                </Button>
                <Button variant='primary' onClick={() => resetAll()}>
                    <FontAwesomeIcon icon={faSignOut} /> Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Logout