import { PrinterOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';
import { colorEventos, formatearCantidad, formatearFecha } from '../../../recursos';
import XDate from 'xdate';


export const centrarTextoPDF = (doc, texto) => {
  let fontSize = doc.internal.getFontSize();
  let anchoDocumento = doc.internal.pageSize.width;
  let txtWidth =
    (doc.getStringUnitWidth(texto) * fontSize) / doc.internal.scaleFactor;
  let x = (anchoDocumento - txtWidth) / 2;
  return x;
};

const ButtonsPdfSaldos = () => {

    const {
        master,
        saldos
    } = useMaestrosStore();

    const exportarDocumento = () => {

      const doc = new jsPDF();
      let x = centrarTextoPDF(doc, "");
      doc.setFontSize(8);
      doc.setFont("", "bold");
      doc.setFontSize(12);
    
      autoTable(doc, {
        //cabecero
        didDrawPage: (data) => {
          data.doc.addImage(
            `/img/logo.png`,
            185, //izq
            6, //arri
            20, //anch
            20 //alto
          );
          data.doc.addImage(`/img/logo.png`, 10, 6, 20, 20);
          data.table.width = data.settings.tableWidth;
          //data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, 5, 287);
          data.doc.setFontSize(14);
          x = centrarTextoPDF(doc, master?.escuela?.nombre?.toUpperCase());
          data.doc.text(master?.escuela?.nombre?.toUpperCase(), x, 12);

          x = centrarTextoPDF(doc, 'RESUMEN MENSUAL DE INGRESOS');
          data.doc.text('RESUMEN MENSUAL DE INGRESOS', x, 17);
          data.doc.setFontSize(9);
          /* x = centrarTextoPDF(doc, `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`);
          data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, x, 22); */
        },
      });
      let mensualidades = saldos?.historialConcepto?.mensualidad || [];
      let equipo = saldos?.historialConcepto?.equipo || [];
      let eventos = saldos?.historialConcepto?.eventos || [];
      

      autoTable(doc, {
        startY: 35,
        headStyles: { 
            fillColor: 'green'
        },
        styles: {
          fontSize: 12,
        },
        head: [[
          '#',
          'Alumno',
          'Fecha',
          'Concepto',
          'Monto',
        ]],
        body: [ ...mensualidades?.map((data, index) => {
                let nombre = `${data?.adeudo?.alumno?.nombre || ''} ${data?.adeudo?.alumno?.apellido_paterno || ''} ${data?.adeudo?.alumno?.apellido_materno || ''}`;
                
                return [
                    { content: index+1 },
                    { content: nombre },
                    { content: data?.fecha ? formatearFecha(data?.fecha) : '' },
                    { content: 'Mensualidad' },
                    { content: formatearCantidad(data?.monto || 0) },
                ]
            }),
            [
                { content: '' },
                { content: '' },
                { content: '' },
                { content: 'Total', styles: { bold: true } },
                { content: formatearCantidad(
                    (saldos?.concepto?.mensualidad || 0)
                ) }, 
            ] 
        ]
      });

      autoTable(doc, {
        headStyles: { 
            fillColor: 'orange'
        },
        styles: {
          fontSize: 12,
        },
        head: [[
          '#',
          'Alumno',
          'Fecha',
          'Concepto',
          'Monto',
        ]],
        body: [ 
            ...equipo?.map((data, index) => {
                let nombre = `${data?.adeudo?.alumno?.nombre || ''} ${data?.adeudo?.alumno?.apellido_paterno || ''} ${data?.adeudo?.alumno?.apellido_materno || ''}`;
                
                return [
                    { content: index+1 },
                    { content: nombre },
                    { content: data?.fecha ? formatearFecha(data?.fecha) : '' },
                    { content: 'Equipo' },
                    { content: formatearCantidad(data?.monto || 0) },
                ]
            }),
            [
                { content: '' },
                { content: '' },
                { content: '' },
                { content: 'Total', styles: { bold: true } },
                { content: formatearCantidad(
                    (saldos?.concepto?.equipo || 0)
                ) }, 
            ] 
        ]
      });

      autoTable(doc, {
        headStyles: { 
            fillColor: colorEventos
        },
        styles: {
          fontSize: 12,
        },
        head: [
          [
          '#',
          'Alumno',
          'Fecha',
          'Concepto',
          'Monto',
        ]],
        body: [ 
            ...eventos?.map((data, index) => {
                let nombre = `${data?.adeudo?.alumno?.nombre || ''} ${data?.adeudo?.alumno?.apellido_paterno || ''} ${data?.adeudo?.alumno?.apellido_materno || ''}`;
                return [
                    { content: index+1 },
                    { content: nombre },
                    { content: data?.fecha ? formatearFecha(data?.fecha) : '' },
                    { content: 'Evento' },
                    { content: formatearCantidad(data?.monto || 0) },
                ]
            }),
            [
                { content: '' },
                { content: '' },
                { content: '' },
                { content: 'Total', styles: { bold: true } },
                { content: formatearCantidad(
                    (saldos?.concepto?.eventos || 0)
                ) }, 
            ] 
        ]
      });

      autoTable(doc, {
        headStyles: { 
            fillColor: 'black'
        },
        styles: {
          fontSize: 12,
        },
        body: [ 
            [
                { content: 'TOTAL GENERAL '+formatearCantidad(
                  (saldos?.concepto?.eventos || 0) +
                  (saldos?.concepto?.mensualidad || 0) +
                  (saldos?.concepto?.equipo || 0) )
                , styles: { bold: true, halign: 'right' } },
                
            ] 
        ]
      });

      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
          `${String(i)}/${pageCount}`,
          doc.internal.pageSize.width - 10,
          287,
          { align: "right" }
          );
      }

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setTextColor('gray');
        doc.text(
        `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`,
        10,
        287,
        { align: "left" }
        );
      }
    
      doc.save(`Reporte Saldos ${XDate().toString("dd-MM-yyyy HH.mm.ss")}.pdf`);
    }

    return (
        <FloatButton 
            tooltip={<div>Imprimir Reporte PDF</div>} icon={<PrinterOutlined />} 
            onClick={exportarDocumento}
        />
    )
}

export default ButtonsPdfSaldos