
import { useDispatch, useSelector } from 'react-redux';
import { 
    setStudents,
    setStudent,
    setLoading,
    setError,
    reset, 
    setAlumnosFiltroList,
    setFiltro
 } from '../../store/StudentSlice';
import { addEstadisticaService, aplicarPagoService, changeStatusService, editAsistenciaService, editConocimientoService, editEstadisticaService, eliminarPagoService, getAlumnoQRService, getDatosAlumnoService, getStudentsListService, modificarAlumnoService, nuevoAlumnoService } from '../../Services/StudentsServices';
import { dangerToast, swalCenterInfo, swalCenterSuccess, warningToastCenter } from '../../recursos/Toast';
import { useEventosStore } from '../Eventos/useEventosStore';

export const useStudentsStore = () => {

    const dispatch = useDispatch();

    const { 
        studentsList,
        student,
        loading,
        error,
        alumnosFiltroList,
        filtro
    } = useSelector(store => store.Student);

    const { getEventosNotificacionesStore } = useEventosStore();

    const {
        courrentUser
    } = useSelector(store => store.CourrentUser);

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    const setStudentsStore = (data) => {
        dispatch( setStudents(data) );
    }

    const setFiltroStore = (data) => {
        dispatch( setFiltro(data) );
    }

    const setFiltroAlumnosStore = (list) => {
        dispatch( setAlumnosFiltroList(list) );
    } 

    const setStudentStore = (data) => {
        dispatch( setStudent(data) );
    }

    const setLoadingStore = (data) => {
        dispatch( setLoading(data) );
    }

    const setErrorStore = (data) => {
        dispatch( setError(data) );
    }

    const resetStudentsStore = () => {
        dispatch( reset() );
    }

    const getStudentsListStore = () => {

        if (!navigator.onLine) {
            return ;
        }

        /* if (navigator.onLine) { */
            setLoadingStore(true);
            getStudentsListService(token).then( response => {
                if (response?.status === 200) {
                    setStudentsStore(response?.data);
                } else {
                    warningToastCenter('Verifique su conexión a internet. Respuesta: '+response?.status);
                }
            }).catch( error => {
                dangerToast('Ocurrio un error al cargar los datos.');
                setErrorStore(false); 
            }).finally(finallly => {
                setLoadingStore(false);
            }); 
       /*  } */
    };

    const nuevoAlumnoStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingStore(true);
        nuevoAlumnoService(params).then( response => {
            if (response?.status === 200) {
                setStudentsStore([...studentsList, response?.data]);
                swalCenterInfo('Se registro con exito la información del alumno.');
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta: '+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos.');
            setErrorStore(false); 
        }).finally(finallly => {
            setLoadingStore(false);
        }); 
    };

    const getDatosAlumnoStore = (id) => {

        if (!navigator.onLine) {
            return ;
        }
        
        setLoadingStore(true);
        getDatosAlumnoService(id, token).then( response => {
            if (response?.status === 200) {

                setStudentStore(response?.data);

                let datoAlumno = response?.data;

                setStudentsStore(
                    studentsList.map(alumno => {
                        if (alumno.id === datoAlumno.id) {
                            alumno = datoAlumno;
                        }
                        return alumno;
                    })
                );

            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta: '+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos.');
            setErrorStore(false); 
        }).finally(finallly => {
            setLoadingStore(false);
        }); 
    };

    const getAlumnoQRStore = (tokenAlumno) => {

        if (!navigator.onLine) {
            return ;
        }
    
        setLoadingStore(true);
        getAlumnoQRService(tokenAlumno, token)
        .then( response => {
            if (response?.status === 200) {
                if (response?.data?.id) {
                    setStudentStore(response?.data);
                }
               
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al cargar los datos.');
            setErrorStore(true); 
        }).finally(finallly => {
            setLoadingStore(false);
        }); 
    };

    const changeStatusStore = (alumno_id) => {

        if (!navigator.onLine) {
            return ;
        }

        changeStatusService(alumno_id, token)    
        .then( response => {
            if (response?.status === 200) {
                //getStudentsListStore();
                getDatosAlumnoStore(alumno_id);
                swalCenterSuccess(response?.data?.message);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al dar de baja al alumno, intentelo nuevamente.');
            setErrorStore(true); 
        });
    };

    const aplicarPagoStore = (alumno_id, concepto_id, monto, comprasPago) => {

        if (!navigator.onLine) {
            return ;
        }

        aplicarPagoService({alumno_id, concepto_id, monto, comprasPago}, token)
        .then( response => {
            if (response?.status === 200) {
                getEventosNotificacionesStore();
                getDatosAlumnoStore(alumno_id);
                //swalCenterSuccess(response?.data?.message);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al efectuar el pago, intentelo nuevamente.');
            setErrorStore(true); 
        });
    };

    const eliminarPagoStore = (id, psw, alumno_id, arrayCompras) => {

        if (!navigator.onLine) {
            return ;
        }

        eliminarPagoService({id, psw, arrayCompras, alumno_id}, token)
        .then( response => {
            if (response?.status === 200) {
                getDatosAlumnoStore(alumno_id);
                swalCenterSuccess(response?.data?.message);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al efectuar el pago, intentelo nuevamente.');
            setErrorStore(true); 
        });
    };

    const modificarAlumnoStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        modificarAlumnoService(params, token)
        .then( response => {
            if (response?.status === 200) {
                //getStudentsListStore();
                getDatosAlumnoStore(params.alumno_id)
                swalCenterSuccess(response?.data?.message);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al guardar, intentelo nuevamente.');
            setErrorStore(true); 
        });
    };

    const addEstadisticaStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        addEstadisticaService(params, token)
        .then( response => {
            if (response?.status === 200) {
                getStudentsListStore();
                getDatosAlumnoStore(params.alumno_id)
                swalCenterSuccess(response?.data?.message);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al guardar, intentelo nuevamente.');
            setErrorStore(true);
        });
    };

    const editEstadisticaStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        editEstadisticaService(params, token)
        .then( response => {
            if (response?.status === 200) {
                //getStudentsListStore();
                getDatosAlumnoStore(params.alumno_id)
                //swalCenterSuccess('Se modificaron los datos del parametro con exito.');
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al guardar, intentelo nuevamente.');
            setError(true); 
        });
    };

    const editConocimientoStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        editConocimientoService(params, token)
        .then( response => {
            if (response?.status === 200) {
                //getStudentsListStore();
                getDatosAlumnoStore(params.alumno_id)
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al guardar, intentelo nuevamente.');
            setError(true); 
        });
    };

    const editAsistenciaStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }
        
        editAsistenciaService(params, token)
        .then( response => {
            if (response?.status === 200) {
                getDatosAlumnoStore(params.alumno_id)
                //getStudentsListStore();
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            dangerToast('Ocurrio un error al guardar, intentelo nuevamente.');
            setError(true);
        });
    }

    const siguienteAlumno = () => {
        let siguiente = false;
        let nextAlumno = null;
        alumnosFiltroList.forEach((alumno, index) => {
            if (siguiente) {
                nextAlumno = alumno;
                siguiente = false;
            }
            if (student.id === alumno.id) {
                if ((index+1) !== alumnosFiltroList.length) {
                    siguiente = true;
                } else {
                    nextAlumno = alumnosFiltroList[0];
                }
                
            }
        });

        if (nextAlumno) {
            setStudentStore(nextAlumno);
        }
    }

    const anteriorAlumno = () => {
        let anterior = false;
        let prevAlumno = null;
        let alumnoAnterior = null;
        alumnosFiltroList.forEach((alumno, index) => {
            if (anterior) {
                prevAlumno = alumnoAnterior;
                anterior = false;
            }
            if (student.id === alumno.id) {
                if ((index+1) === 1) {
                    alumnoAnterior = alumnosFiltroList[alumnosFiltroList.length - 1];
                }
                if ((index+1) === alumnosFiltroList.length) {
                    prevAlumno = alumnoAnterior;
                }
                anterior = true;  
            } else {
                alumnoAnterior = alumno;
            } 
        });
        if (prevAlumno) {
            setStudentStore(prevAlumno);
        }
    }

    const aplicarFiltro = () => {
        let data = [];
        
        if (
            filtro?.mensualidad || 
            filtro?.equipo ||
            filtro?.eventos || 
            filtro?.grado !== null || 
            filtro?.nombre !== "" ||
            filtro?.evento !== "" 
        ) {
            studentsList?.forEach(alumno => {
                let bandera = false;
                if (filtro?.mensualidad) {
                    if (alumno?.adeudos.length > 0) {
                        let adeudoMensualidad = alumno?.adeudos[0];
                        if (adeudoMensualidad.total > 0) {
                            bandera = true;
                        } else {
                            bandera = false;
                        }
                    } else {
                        bandera = false;
                    } 
                }  
                if (filtro?.equipo) {
                    if (alumno?.adeudos.length > 0) {
                        let adeudoEquipo = alumno?.adeudos[1];
                        if (adeudoEquipo.total > 0) {
                            bandera = true;
                        } else {
                            bandera = false;
                        }
                    } else {
                        bandera = false;
                    }
                    
                }  
                if (filtro?.eventos) {
                    if (alumno?.adeudos.length > 0) {
                        let adeudoEventos = alumno?.adeudos[2];
                        if (adeudoEventos.total > 0) {
                            bandera = true;
                        } else {
                            bandera = false;
                        }
                    } else {
                        bandera = false;
                    }
                    
                } 
    
                if (filtro?.nombre !== '' && alumno?.nombre?.toUpperCase() === filtro?.nombre?.toUpperCase()) {
                    bandera = true;
                } 
                
                if (filtro?.evento !== '') {
                    alumno?.compras?.forEach(compra => {
                        if (compra?.total > compra?.pagado) {
                            if (compra?.descripcion?.toUpperCase() === filtro?.evento?.toUpperCase() && compra?.concepto_id === 3) {
                                bandera = true;
                            }
                        }
                    });
                }
    
                if (filtro?.grado !== null && alumno?.grado_id === filtro?.grado) {
                    bandera = true;
                }
                
                if (bandera)
                    data.push(alumno);
            });
        } else {
            data = studentsList;
        }
        
        setFiltroAlumnosStore(data);
    }


  
    return {
        /* parametros */
        studentsList,
        student,
        loading,
        error,
        alumnosFiltroList,
        filtro,
        /* funciones */
        setStudentsStore,
        setStudentStore,
        setLoadingStore,
        setErrorStore,
        resetStudentsStore,
        getStudentsListStore,
        getAlumnoQRStore,
        changeStatusStore,
        aplicarPagoStore,
        modificarAlumnoStore,
        addEstadisticaStore,
        editEstadisticaStore,
        editConocimientoStore,
        editAsistenciaStore,
        getDatosAlumnoStore,
        nuevoAlumnoStore,
        setFiltroAlumnosStore,
        setFiltroStore,
        siguienteAlumno,
        anteriorAlumno,
        aplicarFiltro,
        eliminarPagoStore
    }
}