import { ClockCircleOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Select, Avatar, Typography, Upload, message, Button, DatePicker, TimePicker, List, Watermark, Card as CardAnt, Tooltip, Spin, Timeline, Alert } from 'antd';
import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import { URL_IMG, URL_IMG_USER, formatearFecha } from '../../recursos';
import { useCatalogosStore } from '../../Hooks/Catalogos/useCatalogosStore';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';
import { useState } from 'react';
import moment from 'moment/moment';
import { useForm } from 'antd/es/form/Form';

const AlumnosRegistro = () => {

    const params = useParams();
    const [form] = Form.useForm()
    const {
        gradosList,
        estadosList,
        municipiosList,
        getGradosStore,
        getEstadosStore,
        getMunicipiosStore
    } = useCatalogosStore();

    const { loading, nuevoAlumnoStore, studentsList, setStudentsStore } = useStudentsStore();

    const [img, setImg] = useState([]);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);

    useEffect(() => {
        getGradosStore();
        getEstadosStore();
        getMunicipiosStore();
        setStudentsStore([]);
        return () => {
            //setStudentsStore([]);
        }
    }, [])
    

    const beforeUpload = file => {
        const isPNG = file.type === 'image/png';
        const isJPG = file.type === 'image/jpeg'
        const valida = false;
        if (!isPNG && !isJPG) {
          message.error(`${file.name} no es una imagen permitida.`);
          valida = true;
        }
  
        return false;
    }

    const normFilePortada = (e) => {
      
        if (Array.isArray(e)) {
          return ;
        }
  
        const isPNG = e.file.type === 'image/png';
        const isJPG = e.file.type === 'image/jpeg';
        if (!isPNG && !isJPG) {
            setImg([]);
          //dispatch( setCapacitacion({...capacitacion, img: [] }) );
        } else {
            setImg(e.fileList);
          //dispatch( setCapacitacion({...capacitacion, img: e.fileList}) );
        }
        return e && e.fileList;
    };

    const onChangeDataPicker = (date, dateString) => {
        setFechaNacimiento(dateString);
    }
    
    const guardarDatos = (values) => {
        let formData  = new FormData();
        formData.append('urlimg', img[0]?.originFileObj);
        formData.append('nombre', values.nombre);
        formData.append('apellido_paterno', values.apellido_paterno);
        formData.append('apellido_materno', values.apellido_materno);
        formData.append('fecha_nacimiento', fechaNacimiento);
        formData.append('curp', values.curp);
        formData.append('correo', values.email);
        formData.append('telefono', values.telefono);
        formData.append('genero_id', values.genero_id);
        formData.append('grado_id', values.grado_id);
        formData.append('escolaridad_id', values.escolaridad_id);
        formData.append('colonia', values.colonia);
        formData.append('calle', values.calle);
        formData.append('no_int', values?.no_int || '');
        formData.append('no_ext', values.no_ext);
        formData.append('estado_id', values.estado_id);
        formData.append('municipio_id', values.municipio_id);
        formData.append('token', params?.token);
        nuevoAlumnoStore(formData);
        form.resetFields()

    }

    return (
        <>
        <Watermark
            height={50}
            width={50}
            zIndex={-1}
            gap={[5,5]}
            image={'/img/logo.png'}
       >
        <div className='container' >
            <div className='row'>
                
                <div className='col-lg-8 mt-3 mb-3'>
                <Card className='shadow'>
                    <Card.Header>
                        <strong>Registrar Alumno</strong>
                    </Card.Header>
                    <Card.Body>
                        <Spin tip="Guardando información, por favor espere..." spinning={loading}>
                        <Form
                            layout='vertical'
                            onFinish={guardarDatos}
                            form={form}
                        >
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <Form.Item
                                        name="img"
                                        label="Imagen"
                                        valuePropName="fileList"
                                        rules={[
                                            {
                                              required: true,
                                              message: 'Por favor ingresa una del alumno!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                        getValueFromEvent={normFilePortada}
                                    >
                                    
                                        <Upload 
                                        name="img"
                                        listType="picture" 
                                        maxCount={1}
                                        beforeUpload={beforeUpload}
                                        >
                                            <Button icon={<UploadOutlined />}>Haga clic para cargar.</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="Nombre"
                                        name="nombre"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el nombre de la capacitación!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa un nombre"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="Apellido Paterno"
                                        name="apellido_paterno"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el apellido paterno!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa el apellido paterno"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="Apellido Materno"
                                        name="apellido_materno"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el apellido materno!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa el apellido materno "
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Fecha de Nacimiento"
                                        name="fecha_nacimiento"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecciona una fecha!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="Selecciona la fecha"
                                            style={{ width: '100%' }}
                                            onChange={onChangeDataPicker}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="CURP"
                                        name="curp"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa la curp!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa la curp"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el email!'
                                            },
                                            {
                                                type: 'email',
                                                message: 'Por favor ingresa un email valido!'
                                            }
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa el email"
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-4'>
                                    <Form.Item 
                                        label="Teléfono"
                                        name="telefono"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el teléfono!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa el teléfono"
                                        />
                                    </Form.Item>
                                    
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Genero"
                                        name="genero_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecciona el genero!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Select
                                            placeholder="Selecciona el genero."
                                        >
                                        
                                            <Select.Option value={1}>{'Masculino'}</Select.Option>
                                            <Select.Option value={2}>{'Femenino'}</Select.Option>
                                            <Select.Option value={3}>{'Otro'}</Select.Option>
                                       
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Grado"
                                        name="grado_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecciona el grado!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Select
                                            placeholder="Selecciona el grado."
                                        >
                                        {gradosList.map((item, index) => (
                                            <Select.Option key={item.id} value={item.id}>{item.color} - {item.nombre}</Select.Option>
                                        ))}
                                        </Select>
                                    </Form.Item>  
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Escolaridad"
                                        name="escolaridad_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecciona la escolaridad!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Select
                                            placeholder="Selecciona la escolaridad."
                                        >
                            
                                            <Select.Option  value={1}>{'Educación Preescolar'}</Select.Option>
                                            <Select.Option  value={2}>{'Educación Primaria'}</Select.Option>
                                            <Select.Option  value={3}>{'Educación Secundaria'}</Select.Option>
                                            <Select.Option  value={4}>{'Educación media superior'}</Select.Option>
                                            <Select.Option  value={5}>{'Educación Superior'}</Select.Option>
                                        
                                        </Select>
                                    </Form.Item>  
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'> 
                                    <Divider>Dirección</Divider>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Item 
                                        label="Colonia"
                                        name="colonia"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa la colonia!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa la colonia"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Item 
                                        label="Calle"
                                        name="calle"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa la calle!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa la calle"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Form.Item 
                                        label="No. Int"
                                        name="no_int"
                                    >
                                        <Input
                                            placeholder="Ingresa el número interior"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-2'>
                                    <Form.Item 
                                        label="No. Ext"
                                        name="no_ext"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor ingresa el número exterior!'
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido."
                                    >
                                        <Input
                                            placeholder="Ingresa el número exterior"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Estado"
                                        name="estado_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor el estado!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Select
                                            placeholder="Selecciona el estado."
                                            //onChange={(value) => dispatch( setCapacitacion({...capacitacion, dependencia_area_id: value}) ) }
                                        >
                                        {estadosList.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>{item.nombre}</Select.Option>
                                        ))}
                                        </Select>
                                    </Form.Item>  
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Item
                                        label="Municipio"
                                        name="municipio_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor el municipio!',
                                            },
                                        ]}
                                        tooltip="Este es un campo requerido"
                                    >
                                        <Select
                                            placeholder="Selecciona el municipio."
                                        >
                                        {municipiosList.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>{item.nombre}</Select.Option>
                                        ))}
                                        </Select>
                                    </Form.Item> 
                                </div>
                            </div>
                            
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                style={{ float: 'right' }}
                                icon={<SaveOutlined/>}
                            >
                                Guardar
                            </Button>
                        </Form>
                        </Spin>
                    </Card.Body>
                </Card>
                </div>
                <div className='col-lg-4 mb-3 mt-3'>
                    <Card className='shadow'>
                        <Card.Header>
                            <strong>Registros Recientes</strong>
                        </Card.Header>
                        <Card.Body >
                            <Alert showIcon message="Información" description='La información de este apartado es temporal, solamente informativa, muestra una lista de los alumnos que vas registrando, indicando que el registro fue exitoso.' type="info" />
                            <List
                                itemLayout="horizontal"
                                dataSource={studentsList}
                                renderItem={(data, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                    avatar={<Avatar
                                        size={40}
                                        src={data?.urlimg !== null ? `${URL_IMG}${data?.token}/${data?.urlimg}` : URL_IMG_USER }
                                    />}
                                    title={<strong>{data?.nombre} {data?.apellido_paterno} {data?.apellido_materno}</strong>}
                                    description={`${formatearFecha(data?.created_at)}`}
                                    />
                                </List.Item>
                                )}
                            />
                            {/* <Timeline
                                
                                mode="left"
                                
                            >
                                <Timeline.Item>
                                   <Avatar/> Sample
                                </Timeline.Item>
                                <Timeline.Item>Sample</Timeline.Item>
                                <Timeline.Item>Sample</Timeline.Item>
                                <Timeline.Item>Sample</Timeline.Item>
                            </Timeline> */}
                        </Card.Body>
                    </Card>
                    
                </div>
            </div>
            
        </div>
        </Watermark>
        </>
    )
}

export default AlumnosRegistro