import { 
    SET_STUDENTSLIST, 
    SET_LOADINGSTUDENTS, 
    SET_ERRORSTUDENTS, 
    RESET_STUDENTS,
    SET_STUDENT
} from "../constants/StudentsTypes";

export const setStudents = (studentsList) => {
    return {
        type: SET_STUDENTSLIST,
        payload: studentsList
    }
}

export const setStudent = (student) => {
    return {
        type: SET_STUDENT,
        payload: student
    }
}

export const setLoading = (flag) => {
    return {
        type: SET_LOADINGSTUDENTS,
        payload: flag
    }
}

export const setError = (flag) => {
    return {
        type: SET_ERRORSTUDENTS,
        payload: flag
    }
}

export const reset = () => {
    return {
        type: RESET_STUDENTS
    }
}
