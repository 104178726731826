import { faChartBar, faChartPie, faDashboard, faEye, faEyeSlash, faListCheck, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Slider, Button as ButtonAnt, Checkbox, Divider, FloatButton, Alert } from 'antd';
import React, { useEffect, useState } from 'react'
import { Accordion, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { getColorGrado } from '../../recursos';
import { Chart } from 'primereact/chart';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { swalCenterInfo } from '../../recursos/Toast';
import HeaderAlumno from './info/HeaderAlumno';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';
import ModalInfo from './modals/ModalInfoEstadisticas';

const AlumnoEstadisticas = () => {

    const navigate = useNavigate();
    
    const { 
        student,
        editEstadisticaStore,
        editConocimientoStore,
        addEstadisticaStore,
        anteriorAlumno,
        siguienteAlumno
    } = useStudentsStore();

    const { 
        configuracion, 
        setConfiguracionStore 
    } = useCourrentUserStore();

    const [chartData, setChartData] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartOptions2, setChartOptions2] = useState({});
    const [edit, setEdit] = useState(null);
    const [paramEdit, setParamEdit] = useState(false);
    const [New, setNew] = useState(false);
    const [newParametro, setNewParametro] = useState('');
    const [all, setAll] = useState(1);
    const [index, setIndex] = useState(1);
    const [defaultKeys, setDefaultKeys] = useState([]);
    const [catalogoTecnicas, setCatalogoTecnicas] = useState([
        { id: 1, titulo: 'Combinaciones de Pateo', tecnicas: [], bandera: false },
        { id: 2, titulo: 'Defensa Personal', tecnicas: [], bandera: false },
        { id: 3, titulo: 'Técnicas Básicas', tecnicas: [], bandera: false },
        { id: 4, titulo: 'Combinaciones de Golpes', tecnicas: [], bandera: false },
        { id: 5, titulo: 'Técnicas Simples', tecnicas: [], bandera: false },
        { id: 6, titulo: 'Técnicas Dobles', tecnicas: [], bandera: false },
        { id: 7, titulo: 'Técnicas Triples', tecnicas: [], bandera: false },
        { id: 8, titulo: 'Tecnicas Complejas', tecnicas: [], bandera: false }
    ]);
    const [modalInfo, setModalInfo] = useState(false);

    useEffect(() => {
        let catalogo = catalogoTecnicas;
        
        catalogo = catalogo?.map(cat => {
            if (cat.tecnicas) {
                cat.tecnicas = student?.conocimientos?.filter(con => con?.tecnica?.c_tecnica_id === cat.id);
            }
            
            return cat;
        });
        setCatalogoTecnicas(catalogo);
    }, []);

    useEffect(() => {
        const labels = student?.estadisticas?.map(est => {
            return est.descripcion.toUpperCase();
        });
        const datos = student?.estadisticas?.map(est => {
            return est.porcentaje
        });
        const datosDeceado = student?.estadisticas?.map(est => {
            return 100;
        });
        const colores = student?.estadisticas?.map(est => {
            return est.color
        });

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'HABILIDAD',
                    borderColor: getColorGrado(Number(student?.grado_id)),
                    pointBackgroundColor: getColorGrado(Number(student?.grado_id)),
                    pointBorderColor: getColorGrado(Number(student?.grado_id)),
                    pointHoverBackgroundColor: 'gray',
                    pointHoverBorderColor: 'gray',
                    data: datos,
                    
                },
                {
                    label: 'NIVEL DECEADO',
                    borderColor: '#A1A7AC',
                    pointBackgroundColor: '#A1A7AC',
                    pointBorderColor: '#A1A7AC',
                    pointHoverBackgroundColor: '#A1A7AC',
                    pointHoverBorderColor: '#A1A7AC',
                    data: datosDeceado,
                    fill: 1
                },
            ]
        };
        
        const options = {
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                },
                
            },
            scales: {
                r: {
                    grid: {
                        color: textColorSecondary
                    },
                }
            },
        };

        const data2 = {
            labels: labels,
            datasets: [
                {
                    label: 'HABILIDAD',
                    backgroundColor: getColorGrado(Number(student?.grado_id)),
                    borderColor: getColorGrado(Number(student?.grado_id)),
                    data: datos,
                },
                {
                    label: 'NIVEL DECEADO',
                    backgroundColor: documentStyle.getPropertyValue('--gray-300'),
                    borderColor: documentStyle.getPropertyValue('--gray-300'),
                    data: datosDeceado,
                },
            ]
        };

        const options2 = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                        
                    }
                },
                
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartData2(data2);
        setChartOptions(options);
        setChartOptions2(options2);

    }, [student]);

   

    const randomHexColor = () => {
        return "#000000".replace(/0/g, function() {
          return (~~(Math.random() * 16)).toString(16);
        });
    };
    
    const guardarParametro = () => {
        const params = {
            descripcion: newParametro,
            color: randomHexColor(),
            alumno_id: student.id,
            all: all
        }

        if ( params.descripcion === '' ) {
            swalCenterInfo('Ingresa el nombre del parametro.');
        } else {

           addEstadisticaStore(params);

            setNew(false);
            setNewParametro(null);
        }
    }

    const guardarConocimiento = (id, dominio) => {

        let catalogo = catalogoTecnicas;
        
        catalogo = catalogo.map(cat => {
            
            cat.tecnicas = cat.tecnicas.map(tec => {
                if (tec.id === id) {
                    tec.dominio = !tec.dominio;
                }
                return tec;
            });
            
            return cat;

        });

        setCatalogoTecnicas(catalogo);

        editConocimientoStore({id: id, dominio: dominio});
    }

    const buttonGroup = (
        <ButtonGroup style={{ width: '100%'  }}>
            <Button 
                className='text-end'
                style={{ background: configuracion?.colorNavBar?.alumnos,  borderRadius: 0, borderColor: configuracion?.colorNavBar?.alumnos }}
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faTimes}/> Cerrar
            </Button>
        </ButtonGroup>
    );

    return (
        <>
        <Modal 
            show
            onHide={() => navigate(-1)} 
            fullscreen
            scrollable
        >
            <Modal.Body className="p-0">
                <HeaderAlumno buttonGroup={buttonGroup}/>
                
                {index === 1 && (
                <>
                <div className='col-lg-12 p-1 text-center' style={{ marginTop: '12em' }}>
                    <ButtonGroup>
                        
                        <Button
                            size='large'
                            variant={configuracion?.grafica === 1 ? 'secondary' : 'outline-secondary'}
                            onClick={() => setConfiguracionStore({...configuracion, grafica: 1}) }
                        >
                            <FontAwesomeIcon icon={faChartPie}/>
                        </Button>
                        
                        <Button
                            size='large'
                            variant={configuracion?.grafica === 2 ? 'secondary' : 'outline-secondary'}
                            onClick={() => setConfiguracionStore({...configuracion, grafica: 2}) }
                        >
                            <FontAwesomeIcon icon={faChartBar}/>
                        </Button>
                        
                    </ButtonGroup>
                </div>
                <div className='col-lg-12 p-1' >
                    
                    <div className="card flex justify-content-center"> 
                        {configuracion?.grafica === 1 && (
                            <Chart type="radar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                        ) }
                        {configuracion?.grafica === 2 && (
                            <Chart type="bar" data={chartData2} options={chartOptions2} />
                        )}
                    </div>
                    
                </div>
                
                <div className='col-lg-12 p-1 d-grid gap-2'>
                    <ButtonGroup>
                        <Button variant='light' onClick={() => setNew(true)}>
                            <FontAwesomeIcon icon={faPlus}/> 
                        </Button>
                        <Button variant='light' onClick={() => setParamEdit(!paramEdit)}>
                            <FontAwesomeIcon icon={!paramEdit ? faEye : faEyeSlash}/>
                        </Button>
                    </ButtonGroup>          
                </div>
                {paramEdit && <Divider>Habilidades</Divider>}
                {paramEdit && student?.estadisticas?.map((est, index) => {
                    return (
                        <div className='col-lg-12 p-3' key={index}>
                            
                            <label>{est.descripcion}</label>
                            
                            {(edit?.id === est.id) ? (
                                <ButtonAnt
                                    type="primary"
                                    danger
                                    icon={<CloseOutlined />}
                                    style={{ float: 'right',marginLeft: 10, }}
                                    onClick={() => setEdit(null)}
                                />
                            ) : (
                                <ButtonAnt
                                    type="primary"
                                    icon={<EditOutlined />}
                                    style={{ float: 'right', background: 'orange' }}
                                    onClick={() => setEdit(est)}
                                /> 
                            )}
                            
                            <Slider 
                                disabled={edit?.id === est.id ? false : true} 
                                defaultValue={est.porcentaje} 
                                marks={{0: '0%', 100: '100%'}} 
                                onAfterChange={(value) => editEstadisticaStore({...est, porcentaje: value})}
                            />
                            
                        </div>
                    )
                })}
                <FloatButton
                    shape="circle"
                    tooltip='Información'
                    style={{ 
                        right: 20,
                        bottom: 70, 
                        zIndex: 100,
                        opacity: 0.8
                    }}
                    icon={<InfoCircleOutlined />}
                    onClick={() => setModalInfo(true)}
                />
                </>
                )}
                {index === 2 && (
                <>
                <div style={{ marginTop: '12em' }} className='col-lg-12 text-center mb-1 d-grid gap-2'>
                </div>
                <div className='col-lg-12 p-1'>
                <Accordion defaultActiveKey={[]}  alwaysOpen>
                {catalogoTecnicas.map((catalogo, index) => {
                    return <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>{catalogo.titulo}</Accordion.Header>
                        <Accordion.Body>
                        <table className="table ">
                        <thead>
                            <tr>
                            <th scope="col" style={{ width: '60%' }}></th>
                            <th scope="col" style={{ width: '40%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {catalogo.tecnicas.map((data) => {
                            return <tr key={data.id}>
                                <td>{data.tecnica.descripcion}</td>
                                <td className='text-center'>
                                    <Checkbox 
                                        checked={data.dominio}
                                        onChange={() => guardarConocimiento(data.id, !data.dominio)}
                                    />
                                </td>
                            </tr>
                        })}
                        </tbody>
                        </table>
                        
                            
                        </Accordion.Body>
                        </Accordion.Item>
                })}
                        
                
                        
                </Accordion>
                
                </div>
                </> 
                )}  
                  
                
                <div style={{ marginBottom: '7em' }}></div>
                <ButtonGroup style={{ position: 'fixed', bottom: 0, padding: 0, width: '100%', zIndex: 10 }}>
                        
                    <Button
                        size='large'
                        style={{ borderRadius: 0, background:  index === 1 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(1)}
                    >
                        <FontAwesomeIcon icon={faDashboard}/> <br></br>Habilidades
                    </Button>
                    
                    <Button
                        size='large'
                        style={{ borderRadius: 0, background: index === 2 ? `${configuracion?.colorNavBar?.alumnos}e5` : configuracion?.colorNavBar?.alumnos, borderColor: configuracion?.colorNavBar?.alumnos }}
                        onClick={() => setIndex(2)}
                    >
                        <FontAwesomeIcon icon={faListCheck}/> <br></br>Conocimientos   
                    </Button>
                    
                </ButtonGroup>

                <FloatButton 
                    icon={<ArrowLeftOutlined/>} 
                    type="" 
                    style={{ left: 24, top: '14em', opacity: 0.2 }} 
                    onClick={anteriorAlumno}                
                /> 
                <FloatButton 
                    icon={<ArrowRightOutlined/>} 
                    style={{ right: 24, top: '14em', opacity: 0.2 }} 
                    onClick={siguienteAlumno}                
                />   

                <Modal
                    show={New}
                    centered    
                    onHide={() => setNew(false)}
                    fullscreen
                >
                    <Modal.Header className='text-center' closeButton>
                        Agregar Habilidades.
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-lg-12 mt-3 form-group'>
                            <strong className="form-label">Nombre:</strong>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder='Nombre de la Habilidad'
                                onChange={e => setNewParametro(e.target.value)}
                                value={newParametro}
                            />
                        </div>
                        <div className='col-lg-12 mt-3 d-grid gap-2'>
                            <ButtonGroup>
                                <Button 
                                    variant={all ? 'secondary' : 'outline-secondary'}
                                    onClick={() => setAll(1)}
                                >
                                    Todos
                                </Button>
                                <Button 
                                    variant={all ? 'outline-secondary' : 'secondary'}
                                    onClick={() => setAll(0)}
                                >
                                    Individual
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className='col-lg-12 mt-3'>
                            <Alert showIcon  type='info' message='Información' description={<div>Si selecciona la opción <strong>Todos</strong> la habilidad se agregara a todos los alumnos, si selecciona la opción <strong>Individual</strong> solo se agregara al alumno que selecciono.</div>} />
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant='primary' onClick={() => guardarParametro()}> 
                            <FontAwesomeIcon icon={faSave}/> Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                
            </Modal.Body>
        </Modal>
        <ModalInfo open={modalInfo} setOpen={setModalInfo}/>
        </>
    )
}

export default AlumnoEstadisticas