import { faChartBar, faChartPie, faEdit, faEye, faEyeSlash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Slider } from 'antd'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';

const ModalInfo = ({open, setOpen}) => {
    const [disabled, setDisabled] = useState(true);
    return (
        <Modal 
            show={open}
            onHide={() => setOpen(false)}
            fullscreen
        >
            <Modal.Header closeButton className='bg-primary text-white'>
                <strong>INFORMACIÓN</strong>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                <div className='col-12 mb-2'>
                    <Card className='shadow-sm' title='Graficas'>
                        <p>Al seleccionar el boton con el siguiente icono <strong><FontAwesomeIcon icon={faChartPie}/></strong> mostrara una grafica de poligonal indicando las habilidades y porcentajes del alumno.</p>
                        <p>Al seleccionar el boton con el siguiente icono <strong><FontAwesomeIcon icon={faChartBar}/></strong> mostrara una grafica de barras indicando las habilidades y porcentajes del alumno.</p>

                    </Card>
                </div>
                <div className='col-12 mb-2'>
                    <Card className='shadow-sm' title='Agregar Habilidades'>
                        Para agregar habilidades seleccione la opcion marcada con el siguiente icono <strong><FontAwesomeIcon icon={faPlus}/></strong>.
                    </Card>
                </div>
                <div className='col-12 mb-2'>
                    <Card className='shadow-sm' title='Ver Habilidades Para Modificar'>
                    <p>Para ver las habilidades del alumno selecciona la opción con el siguiente icono <strong><FontAwesomeIcon icon={faEye}/></strong>.</p>
                    <p>Las habilidades del alumno se mostraran en la parte inferior de los botones, para ocultar la información selecciona opcion con el siguiente icono <FontAwesomeIcon icon={faEyeSlash}/></p>
                    </Card>
                </div>
                <div className='col-12 mb-2'>
                    <Card className='shadow-sm' title='Modificar porcentaje de habilidades'>
                    <p>Previo a Ver Habilidades, para modificar el porcentaje de cada una de las habilidades del alumno selecciona la opción con el siguiente icono <strong><FontAwesomeIcon icon={faEdit}/></strong> de la habilidad que decea modificar.</p>
                    <p>
                        <div className='col-lg-12 p-3'>
                            <label>Elasticidad.</label>
                            {!disabled ? (
                            <Button
                                type="primary"
                                danger
                                icon={<CloseOutlined />}
                                style={{ float: 'right',marginLeft: 10, }}
                                onClick={() => setDisabled(true)}
                            />
                            ) : (
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                style={{ float: 'right', background: 'orange' }}
                                onClick={() => setDisabled(false)}
                            /> 
                            )}
                            <Slider
                                disabled={disabled}
                                marks={{0: '0%', 100: '100%'}}
                            />   
                        </div>
                    </p>
                    <p>Despues de mover el pequeño circulo hacia el porcentaje deceado, al soltarlo se modificara automaticamente el registro.</p>
                    </Card>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfo