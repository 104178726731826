import React from 'react';
import { Checkbox, Input, Modal, Select } from 'antd';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { useCatalogosStore } from '../../../Hooks/Catalogos/useCatalogosStore';
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select;

const ModalFiltroAlumnos = ({ open, setOpen, aplicarFiltro }) => {

    const {
        filtro,
        setFiltroStore
    } = useStudentsStore();

    const { 
        gradosList
    } = useCatalogosStore();

    return (
        <Modal 
            open={open}
            onCancel={() => {
                setOpen(false);
            }} 
            onOk={() => {
                aplicarFiltro();
            }}
            cancelText='Salir'
            okText='Aceptar'
            centered
        >
            <div className='row'>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={filtro?.mensualidad} onChange={e => setFiltroStore({...filtro, mensualidad: e.target.checked })}> Con Adeudo en mensualidades.</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={filtro?.equipo} onChange={e => setFiltroStore({...filtro, equipo: e.target.checked })}> Con adeudo en equipo.</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <Checkbox checked={filtro?.eventos} onChange={e => setFiltroStore({...filtro, eventos: e.target.checked })}> Con adeudo en Eventos/Torneos</Checkbox>
                </div>
                <div className='col-lg-12 mb-3'>
                <strong>Nombre</strong>
                <Input
                    placeholder="Nombre"
                    value={filtro?.nombre}
                    onChange={e => setFiltroStore({...filtro, nombre: e.target.value })}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    
                />
                </div>
                <div className='col-lg-12 mb-3'>
                <strong>Grado</strong>
                <Select 
                    onChange={value => setFiltroStore({...filtro, grado: value})}
                    value={filtro?.grado}
                    placeholder="Selecciona un grado."
                    style={{ width: '100%' }}
                    allowClear
                >
                {gradosList.map((grado, index) => (
                    <Option key={index} value={grado.id}>{`${grado.color}-${grado.nombre}`}</Option>
                ) )}
                </Select>
                </div>
            </div>
        </Modal>
    )
}

export default ModalFiltroAlumnos;