import { HistoryOutlined } from '@ant-design/icons';
import { faChevronDown, faChevronUp, faClock, faHistory, faLock, faLockOpen, faMobileScreen, faPause, faPhoneFlip, faPhoneSlash, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Divider, FloatButton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useWakeLock } from 'react-screen-wake-lock';
import { SocketContext } from '../../context/SocketContext';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';

const stylesDigits = {
  fontSize: '3rem',
  //color:  '#f5f5f5'
};

const song = new Audio('/Campana.mp3');

const TableroPuntos = () => {

  //const { socket } = useContext( SocketContext );

  const [Rojo, setRojo] = useState(0);
  const [Negro, setNegro] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);//60000
  const [Fijar, setFijar] = useState(false);
  const audioRef = useRef();

  const { master } = useMaestrosStore();

  const { isSupported, released, request, release } = useWakeLock();

  useEffect(() => {
    let interval = null;
  
    if (isActive && isPaused === false) {
        
        interval = setInterval(() => {
          setTime((time) => time - 10);
        }, 10);
    
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    if (time === 0 && !isPaused) {
      if (isActive) {
        song.play();
      }
      setTime(0);
      setIsActive(false);
      setIsPaused(true);
      setFijar(false); 
      
    }
  }, [time]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    if (released === false) {
      release();
    }
    
  };
  
  const handlePauseResume = () => {
    setIsPaused(!isPaused);
  };
  
  const handleReset = () => {
    setRojo(0);
    setNegro(0);
    setIsActive(false);
    setTime(0);
    setFijar(false);
    setTablero(0,0);
    if (released !== false && released !== true)
      request();
  };

  const fijarTiempo = () => {
    if (time !== 0) {
      setFijar(true);
      if (released !== false && released !== true)
        request();    
    }
  }

  const StartButton = (
    Fijar ? (
      <Button variant='primary' onClick={handleStart} style={{ borderRadius: 0 }}>
        <FontAwesomeIcon icon={faPlay}/> Iniciar
      </Button>
    ) : (
      <>
      <ButtonGroup>
      <Button variant='secondary' onClick={fijarTiempo} style={{ borderRadius: 0 }}>
        <FontAwesomeIcon icon={faClock}/> Fijar Tiempo
      </Button>
      <Button variant='danger' onClick={handleReset} style={{ borderRadius: 0 }}>
      <FontAwesomeIcon icon={faHistory}/> Reiniciar
      </Button>
      </ButtonGroup>
      
      </>
    )
    
  );
  const ActiveButtons = (
    <ButtonGroup>
      <Button variant='outline-danger' onClick={handleReset} style={{ borderRadius: 0 }}>
      <FontAwesomeIcon icon={faHistory}/> Reiniciar
      </Button>
      <Button variant='danger' onClick={handlePauseResume} style={{ borderRadius: 0 }}>
      <FontAwesomeIcon icon={isPaused ? faPlay : faPause}/> {isPaused ? "Resume" : "Pause"}
      </Button>
    </ButtonGroup>
    
  );

  const setMinutos = (operacion) => {
    let newTime = (operacion === 1 ? (time + 60000) : (time - 60000) );
    if (newTime >= 0) {
      setTime(newTime);
    }
    
  }

  const setSegundos = (operacion) => {
    let newTime = (operacion === 1 ? (time + 1000) : (time - 1000) );
    if (newTime >= 0) {
      setTime(newTime);
    }
    
  }

  const setTablero = (rojo, negro) => {
    setRojo(rojo);
    setNegro(negro);
    //socket.emit('set-tablero-usuario', {token: master?.token, rojo: rojo, negro: negro});
  }

  return (
    <>
    <div className='cotainer-fluid'>   
      <div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
        
          {!Fijar && <div className='col-12 bg-light'>
            <div className='text-center'>
              <span style={{...stylesDigits, marginRight: 20, marginBottom: 0}}>
                <Button variant='light' onClick={() => setMinutos(1)}>
                  <FontAwesomeIcon icon={faChevronUp}/>
                </Button>
              </span>
              <span style={{...stylesDigits, marginRight: 20, marginBottom: 0}}>
                <Button variant='light' onClick={() => setSegundos(1)}>
                  <FontAwesomeIcon icon={faChevronUp}/>
                </Button>
              </span>
            </div>
          </div>}
          <div className='col-12 bg-light'>
            <div className='text-center mb-0'>
              <span style={stylesDigits}>
                {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
              </span>
              <span style={stylesDigits}>
                {("0" + Math.floor((time / 1000) % 60)).slice(-2)}.
              </span>
              <span style={{...stylesDigits, color: '#e42a2a'}}>
                {("0" + ((time / 10) % 100)).slice(-2)}
              </span>
            </div>
          </div>
          {!Fijar && <div className='col-12 bg-light'>
            <div className='text-center'>
              <span style={{...stylesDigits, marginRight: 20, marginTop: 0}}>
                <Button variant='light' onClick={() => setMinutos(0)}>
                  <FontAwesomeIcon icon={faChevronDown}/>
                </Button>
              </span>
              <span style={{...stylesDigits, marginRight: 20, marginTop: 0}}>
                <Button variant='light' onClick={() => setSegundos(0)}>
                  <FontAwesomeIcon icon={faChevronDown}/>
                </Button>
              </span>
            </div>
          </div>}
          <div className='col-12 bg-light'>
            <div className='d-grid gap-2 mb-2'>{isActive ? ActiveButtons : StartButton}</div>
          </div>
          <div 
            className='col-6 p-0 mb-1 bg-danger text-center text-white' 
            style={{ height:'100vh', alignSelf: 'center', fontSize: 70 }}
            onClick={() => setTablero(Rojo+1, Negro)}
          >
            <strong>{Rojo}</strong>
          </div>
          <div 
            className='col-6 p-0 mb-1 bg-dark text-center text-white' 
            style={{ height:'100vh', alignSelf: 'center', fontSize: 70 }}
            onClick={() => setTablero(Rojo, Negro+1)}
          >
            <strong>{Negro}</strong>
          </div>
      </div>
    </div>
    <FloatButton onClick={() => { setTablero(0, 0) }} icon={<HistoryOutlined/>} />
    </>
  )
}



export default TableroPuntos