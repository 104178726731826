import { PrinterOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useMaestrosStore } from '../../../Hooks/Maestros/useMaestrosStore';
import XDate from 'xdate';
import { useEventosStore } from '../../../Hooks/Eventos/useEventosStore';
import { colorEventos, getImageEventoLocal } from '../../../recursos';

export const centrarTextoPDF = (doc, texto) => {
  let fontSize = doc.internal.getFontSize();
  let anchoDocumento = doc.internal.pageSize.width;
  let txtWidth =
    (doc.getStringUnitWidth(texto) * fontSize) / doc.internal.scaleFactor;
  let x = (anchoDocumento - txtWidth) / 2;
  return x;
};

const ButtonPDFAlumnosEvento = () => {

  const {
      master,
      saldos
  } = useMaestrosStore();
  const { evento, eventoAlumnos } = useEventosStore();

  const exportarDocumento = () => {

    const doc = new jsPDF();
    let x = centrarTextoPDF(doc, "");
    doc.setFontSize(8);
    doc.setFont("", "bold");
    doc.setFontSize(12);
  
    autoTable(doc, {
      //cabecero
      didDrawPage: (data) => {
        data.doc.addImage(
          getImageEventoLocal(evento.tipo_evento_id),
          185, //izq
          6, //arri
          20, //anch
          20 //alto
        );
        data.doc.addImage(`/img/logo.png`, 10, 6, 20, 20);
        data.table.width = data.settings.tableWidth;
        //data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, 5, 287);
        data.doc.setFontSize(14);
        x = centrarTextoPDF(doc, master?.escuela?.nombre?.toUpperCase());
        data.doc.text(master?.escuela?.nombre?.toUpperCase(), x, 12);

        x = centrarTextoPDF(doc, evento?.tipo_evento?.descripcion?.toUpperCase());
        data.doc.text(evento?.tipo_evento?.descripcion?.toUpperCase(), x, 17);
        
         x = centrarTextoPDF(doc, evento?.descripcion?.toUpperCase());
        data.doc.text(evento?.descripcion?.toUpperCase(), x, 22);
        
        data.doc.setFontSize(9);
       /*  x = centrarTextoPDF(doc, `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`);
        data.doc.text(`${XDate().toString("dd/MM/yyyy HH:mm:ss")}`, x, 27); */
      },
    });

    let arrayColumns = ['#','Alumno','No. Catergorias','Categorias'];
    
    if (evento.tipo_evento_id === 7 || evento.tipo_evento_id === 3 || evento.tipo_evento_id === 4) {
        arrayColumns = ['#','Alumno'];
    }
    
    let eventosList = eventoAlumnos?.length > 0 ? eventoAlumnos.filter(data => data?.participaciones?.filter(ev => ev.evento_id === evento.id)?.length > 0) :  [];

    autoTable(doc, {
      startY: 35,
      headStyles: { 
          fillColor: colorEventos
      },
      styles: {
        fontSize: 12,
      },
      head: [arrayColumns],
      body: [ 
          ...eventosList?.map((data, index) => {
              let nombre = `${data?.nombre || ''} ${data?.apellido_paterno || ''} ${data?.apellido_materno || ''}`;
              let part = data?.participaciones?.find(ev => ev.evento_id === evento.id);
              let countCategorias = (part.p_puntos ? 1 : 0) +
                (part.p_continua ? 1 : 0) + 
                (part.p_full ? 1 : 0) +
                (part.f_estricta ? 1 : 0) +
                (part.f_arma ? 1 : 0) +
                (part.f_creativa ? 1 : 0) +
                (part.f_creativa_equipo ? 1 : 0) +
                (part.f_arma_equipo ? 1 : 0) + 
                (part.f_estricta_equipo ? 1 : 0) +
                (part.p_equipo ? 1 : 0);

                let listCategorias = '';

                if (part.p_puntos)
                  listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Pelea Puntos`;
                if (part.p_continua) 
                  listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Pelea Continua`;
                if (part.p_full)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Pelea FullContact`;
                if (part.f_estricta)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma Estricta`;
                if (part.f_arma)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma con Arma`;
                if (part.f_creativa)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma Creativa`;
                if (part.f_creativa_equipo)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma Creativa en Equipo`;
                if (part.f_arma_equipo) 
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma con Arma en Equipo`;
                if (part.f_estricta_equipo)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Forma Estricta Equipo`;
                if (part.p_equipo)
                listCategorias = `${listCategorias.length > 0 ? listCategorias+'\n' : ''}Pela en Equipo`;
              
                if (evento.tipo_evento_id === 7 || evento.tipo_evento_id === 3 || evento.tipo_evento_id === 4) {
                  return [
                    { content: index+1 },
                    { content: nombre },
                  ]
                } else {
                  return [
                    { content: index+1 },
                    { content: nombre },
                    { content: countCategorias, styles: {  halign: "center" } },
                    { content: listCategorias}
                  ]
                  
                }
                
          })
      ]
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
        `${String(i)}/${pageCount}`,
        doc.internal.pageSize.width - 10,
        287,
        { align: "right" }
        );
    }

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setTextColor('gray');
      doc.text(
      `${XDate().toString("dd/MM/yyyy HH:mm:ss")}`,
      10,
      287,
      { align: "left" }
      );
    }

    doc.save(`ALUMNOS LISTA ${evento?.tipo_evento?.descripcion?.toUpperCase()}  ${evento?.descripcion?.toUpperCase()} ${XDate().toString("dd-MM-yyyy HH.mm.ss")}.pdf`);
  }

  return (
    <FloatButton 
        tooltip={<div>Imprimir Reporte PDF</div>} icon={<PrinterOutlined/>} 
        onClick={exportarDocumento}
    />
  )
}

export default ButtonPDFAlumnosEvento