import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    articulosList: [],
    articulo: null,
    loadingList: false,
    loading: false,
    pedidosList: [],
    pedido: null,
    loadingPedidosList: false,
    loadingPedido: false
};

export const ArticulosSlice = createSlice({
    name: 'articulos',
    initialState,
    reducers: {
        setArticulosList: (state, { payload }) => {
            state.articulosList = payload;
        },
        setLoadingList: (state, { payload }) => {
            state.loadingList = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setPedidosList: (state, { payload }) => {
            state.pedidosList = payload;
        },
        setPedido: (state, { payload }) => {
            state.pedido = payload;
        },
        setLoadingPedidosList: (state, { payload }) => {
            state.loadingPedidosList = payload;
        },
        setLoadingPedido: (state, { payload }) => {
            state.loadingPedido = payload;
        },
        setArticulo: (state, { payload }) => {
            state.articulo = payload;
        },
        reset: (state) => {
            state.articulosList = [];
            state.articulo =  null;
            state.loadingList =  false;
            state.loading =  false;
            state.pedidosList = [];
            state.pedido = null;
            state.loadingPedidosList = false;
            state.loadingPedido = false;
        },
    }
});

export const {
    setArticulosList,
    setArticulo,
    setLoadingList,
    setLoading,
    reset,
    setPedidosList,
    setPedido,
    setLoadingPedidosList,
    setLoadingPedido
} = ArticulosSlice.actions;