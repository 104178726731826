
import { useDispatch, useSelector } from 'react-redux'
import {
    setArticulosList,
    setLoadingList,
    setLoading,
    reset,
    setPedidosList,
    setPedido,
    setLoadingPedidosList,
    setLoadingPedido,
    setArticulo
} from '../../store/ArticulosSlice';
import { dangerToast, infoToastCenter, swalCenterSuccess, warningToastCenter } from '../../recursos/Toast';
import { 
    addAdeudoArticuloService, 
    addCompraService, 
    addStockService, 
    deleteTallaService, 
    editArticuloService, 
    getArticulosListService, 
    getPedidosListService, 
    nuevaTallaArticuloService, 
    nuevoPedidoService,
    setEstatusPedidoService
} from '../../Services/ArticulosServices';
import { useEventosStore } from '../Eventos/useEventosStore';
import { useStudentsStore } from '../Students/useStudentsStore';

export const useArticulosStore = () => {

    const dispatch = useDispatch();

    const { 
        articulosList,
        articulo,
        loadingList,
        loading, 
        pedidosList,
        pedido,
        loadingPedidosList,
        loadingPedido
    } = useSelector(store => store.Articulos);

    const {
        courrentUser
    } = useSelector(store => store.CourrentUser);

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    const { getEventosNotificacionesStore } = useEventosStore();
    const { getStudentsListStore, getDatosAlumnoStore } = useStudentsStore();

    const setPedidosListStore = (data) => {
        dispatch( setPedidosList(data) );
    }
    const setPedidoStore = (data) => {
        dispatch( setPedido(data)  );
    }
    const setLoadingPedidosListStore = (data) => {
        dispatch( setLoadingPedidosList(data) );
    }
    const setLoadingPedidoStore = (data) => {
        dispatch( setLoadingPedido(data) );
    }

    const setArticulosListStore = (list) => {
        dispatch( setArticulosList(list) );
    }

    const setArticuloStore = (articulo) => {
        dispatch( setArticulo(articulo) );
    }

    const setLoadingListStore = (loadingList) => {
        dispatch( setLoadingList(loadingList) );
    }

    const setLoadingStore = (loading) => {
        dispatch( setLoading(loading) );
    }

    const resetArticulosStore = () => {
        dispatch( reset() );
    }

    const getArticulosListStore = () => {

        if (!navigator.onLine) {
            return ;
        }

        dispatch( setLoadingList(true) );
        getArticulosListService(token).then( response => {

            if (response?.status === 200) {
                setArticulosListStore(response?.data);
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:' + response?.status);
            }

        }).catch( error => {

            dangerToast('Ocurrio un error al cargar la lista, intentelo nuevamente.');

        }).finally(final => dispatch(setLoadingList(false)));

    };

    const editArticuloStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        dispatch( setLoading(true) );
        editArticuloService(params,token).then( response => {
            
            if (response?.data?.success) {
                swalCenterSuccess(response?.data?.message);
                getArticulosListStore();
            } else {
                warningToastCenter('No se pudo guardar la información');
            }
    
        }).catch( error => {
    
            dangerToast('Ocurrio un error al cargar la lista, intentelo nuevamente.');
    
        }).finally(final => dispatch(setLoading(false)));

    };

    const addCompraStore = (list, alumno_id) => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingListStore(true);
        
        addCompraService({ listCompra: list },token).then( response => {
            
            if (response?.status === 200) {
                getDatosAlumnoStore(alumno_id);
                swalCenterSuccess('Se agrego la compra con éxito.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingListStore(false))
        
    };

    const addAdeudoArticuloStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingListStore(true)
        addAdeudoArticuloService(params, token).then( response => {
            
            if (response?.status === 200) {
                getDatosAlumnoStore(params.alumno_id);
                //getEventosNotificacionesStore();
                swalCenterSuccess('Se agregaron los articulos con exito.');
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error, intentelo nuevamente.');
        }).finally(final => setLoadingListStore(false))
        
    };

    const getPedidosListStore = () => {

        if (!navigator.onLine) {
            return ;
        }

        setLoadingPedidosListStore(true);
        
        getPedidosListService(token).then( response => {
            
            if (response?.status === 200) {
                setPedidosListStore(response?.data);
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingPedidosListStore(false))
        
    };

    const nuevoPedidoStore = () => {
        if (!navigator.onLine) {
            return ;
        }

        setLoadingPedidoStore(true);
        
        nuevoPedidoService(token).then( response => {
            
            if (response?.status === 200) {
                getPedidosListStore();
                swalCenterSuccess('Se registro el nuevo pedido con exito.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingPedidoStore(false))
        
    };

    const setEstatusPedidoStore = (params) => {
        if (!navigator.onLine) {
            return ;
        }

        setLoadingPedidoStore(true);
        setEstatusPedidoService(params, token).then( response => {
            if (response?.status === 200) {
                getPedidosListStore();
                swalCenterSuccess('Se cambio el estatus del pedido con exito.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingPedidoStore(false))
        
    };

    const nuevaTallaArticuloStore = (params) => {
        if (!navigator.onLine) {
            return ;
        }

        //setLoadingPedidoStore(true);
        nuevaTallaArticuloService(params, token).then( response => {
            if (response?.status === 200) {
                setArticuloStore(response?.data);
                setArticulosListStore(articulosList.map(articulo => {
                    if (articulo?.id === response?.data?.id) {
                        articulo = response?.data;
                    }
                    return articulo;
                }));

                swalCenterSuccess('Se agrego la talla con exito.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingPedidoStore(false))
        
    };

    const addStockStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }
        
        addStockService({ tallas: params }, token).then( response => {
            if (response?.status === 200) {
                setArticuloStore(response?.data);
                setArticulosListStore(articulosList.map(articulo => {
                    if (articulo?.id === response?.data?.id) {
                        articulo = response?.data;
                    }
                    return articulo;
                }));
                swalCenterSuccess('Se agregaron los articulos al stock.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingPedidoStore(false))
        
    };

    const deleteTallaStore = (params) => {

        if (!navigator.onLine) {
            return ;
        }
        setLoadingStore(true);
        deleteTallaService(params, token).then( response => {
            if (response?.status === 200) {
                setArticuloStore(response?.data);
                setArticulosListStore(articulosList.map(articulo => {
                    if (articulo?.id === response?.data?.id) {
                        articulo = response?.data;
                    }
                    return articulo;
                }));
                infoToastCenter('Se elimino el registro de la talla con exito.');
                //getEventosNotificacionesStore(); 
            } else {
                warningToastCenter('Verifique su conexión a internet. Respuesta:'+response.status);
            }
            
        }).catch( error => {
            dangerToast('Ocurrio un error al generar la compra, intentelo nuevamente.');
    
        }).finally(final => setLoadingStore(false))
        
    };

  
    return {
        /* parametros */
        articulosList,
        articulo,
        loadingList,
        loading,
        pedidosList,
        pedido,
        loadingPedidosList,
        loadingPedido,
        /* funciones */
        getArticulosListStore,
        editArticuloStore,
        addCompraStore,
        addAdeudoArticuloStore,
        setArticulosListStore,
        setLoadingListStore,
        setLoadingStore,
        resetArticulosStore,
        getPedidosListStore,
        nuevoPedidoStore,
        setPedidoStore,
        setEstatusPedidoStore,
        nuevaTallaArticuloStore,
        setArticuloStore,
        addStockStore,
        deleteTallaStore
    };
}