import React, { useEffect, useState } from 'react';
import { globalStyles as css, globalStyles } from '../../../recursos/styles';
import { Button, ButtonGroup, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faDoorClosed, faDoorOpen, faEye, faFolderPlus, faList12, faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Empty, FloatButton } from 'antd';
import { FolderAddOutlined, FolderAddTwoTone, InfoCircleOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useArticulosStore } from '../../../Hooks/Articulos/useArticulosStore';
import { colorProductos, formatearCantidad, formatearFecha, formatearFechaMA } from '../../../recursos';
import ButtonPDFArticulosPedido from '../buttons/ButtonPDFArticulosPedido';


const Pedidos = () => {

  const navigate = useNavigate();
  const { pedidosList, pedido, setPedidoStore, nuevoPedidoStore, setEstatusPedidoStore } = useArticulosStore();

  const [openNew, setOpenNew] = useState(false);
  const [openEstatus, setOpenEstatus] = useState(false);
  const [openComprasPedido, setOpenComprasPedido] = useState(false);
  const [articulosReporteList, setArticulosReporteList] = useState([]);

  const onClick = ({ key }) => {
    switch (Number(key)) {
        case 1:
            setOpenComprasPedido(true);
            //navigate('/info-alumno');
            break;
        case 2: 
            setOpenEstatus(true);
            break;
        case 3: 
            //navigate('/carrito');
            break;
        case 4: 
            //navigate('/estadisticas');
            break;
        default:
            break;
    }
  };


  const items = [
    {
      label: 'Ver Pedido',
      key: '1',
      icon: <FontAwesomeIcon icon={faEye}/>
    },
    {
      label: pedido?.estatus === 'CERRADO' ? 'Abrir Pedido' : 'Cerrar Pedido',
      key: '2',
      icon: <FontAwesomeIcon icon={faDoorClosed}/> 
    },
    /* {
      label: 'Abrir Pedido',
      key: '3',
      icon: <FontAwesomeIcon icon={faDoorOpen}/>
    }, */
    /* {
      label: 'Eliminar pedido',
      key: '3',
      icon: <FontAwesomeIcon icon={faTrash}/>
    }, */
  ];

  const selectPedito = (data) => {
    setPedidoStore(data);
    let articulos = [];
    data?.compras?.forEach(element => {
      
      let articulo = articulos.find(art => (art?.articulo_id === element?.articulo_id && art?.talla_id === element?.talla_id));
      
      if (articulo) {
        
        
        let articulosOld = articulos.filter(data  => data !== articulo)
        articulos = [
          ...articulosOld, 
          {
            ...articulo,
            cantidad: articulo.cantidad + element.cantidad,
            total: articulo.total + element.total
          }
        ] ;
        /* articulos?.map(artNew => {
          
          if (artNew.articulo_id === articulo.articulo_id && artNew.talla_id === articulo.talla_id) {
            artNew = {
              ...artNew, 
              cantidad: artNew?.cantidad + cantidad, 
              total: artNew?.total + total}
          }
          
          return artNew;

        });
 */
      } else {
        articulos.push(element);
      }
    });
    setArticulosReporteList(articulos);
  } 

  return (
    <>
    <div className='row' style={{ marginLeft: 2, marginRight: 2, marginBottom: 70 }}>
      {pedidosList.length === 0 && (
      <div style={{ marginTop: '5em' }}>
          <Empty description={<span>No se econtrarón resultados.</span>} />
      </div>
      )}
      {pedidosList?.map((data, index) => {
        return (
        <div className='col-lg-12 p-0' key={index}>
          <Card className='shadow-sm' style={{ borderRadius: 0 }}>
              <Card.Body>
                  <div className='row'>
                    <Dropdown
                        menu={{
                            items,
                            onClick 
                        }}
                        arrow={{
                            pointAtCenter: true,
                        }}
                        onClick={() => selectPedito(data)}
                        placement="bottom"
                    >
                      <div className='col-10'>
                          <p style={{...css.nombre, fontSize: 13}}>
                            <strong> NO. PEDIDO: </strong> <label className='text-muted'>{data.no_pedido}</label>
                          </p>
                          <p style={{...css.nombre, fontSize: 13}}>
                            <strong>ESTATUS: </strong> <span className={data?.estatus === 'ABIERTO' ? 'text-success' : 'text-danger'}>{data.estatus}</span>
                          </p>
                          <p className='text-primary' style={{...css.nombre, fontSize: 13}}>
                            <strong>NO. DE ARTICULOS : </strong> {data?.compras?.length}
                          </p>
                          <p  style={{...css.nombre, fontSize: 13}}>
                            <strong className='text-muted'>APERTURA PEDIDO : </strong> <span>{formatearFecha(data?.created_at)}</span>
                          </p>
                          {data?.estatus === 'CERRADO' && (
                          <p  style={{...css.nombre, fontSize: 13}}>
                            <strong className='text-muted'>CIERRE DE PEDIDO : </strong> <span style={{...css.fecha}}>{formatearFecha(data?.updated_at)}</span>
                          </p>
                          )}
                      </div>
                    </Dropdown>
                  </div>
              </Card.Body>
          </Card>
        </div>
        )
      })}
      
    </div>
    <FloatButton.Group
        trigger="click"
        type="primary"
        size="large"
        style={{
            right: 20,
            bottom: 70,
        }}
        icon={<SettingOutlined />}
    >
        <FloatButton 
          tooltip={<div>Nuevo Pedido</div>} 
          icon={<FolderAddOutlined />} 
          onClick={() => setOpenNew(true)}
        />
        
    </FloatButton.Group>
    
    <Modal show={openNew} onHide={() => setOpenNew(false)} centered>
      <Modal.Body>
        <p className='text-center'>
          <strong>Si decea abrir un nuevo pedido por favor confirme.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={() => setOpenNew(false)}>
          <FontAwesomeIcon icon={faTimes}/> Cancelar
        </Button>
        <Button variant='success' onClick={() => { nuevoPedidoStore(); setOpenNew(false); }}>
          <FontAwesomeIcon icon={faSave}/> Aceptar
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={openEstatus} onHide={() => setOpenEstatus(false)} centered>
      <Modal.Body>
        <p className='text-center'>
          <strong>¿ESTA SEGURO DE {pedido?.estatus === 'CERRADO' ? 'ABRIR' : 'CERRAR'} EL PEDIDO {pedido?.no_pedido || ''}?</strong>
        </p>
        <p className='text-center'>
          <strong>{pedido?.estatus === 'CERRADO' ? 'AHORA PODRA' : 'YA NO PODRA'} AGREGAR ARTICULOS A ESTE PEDIDO.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={() => setOpenEstatus(false)}>
          <FontAwesomeIcon icon={faTimes}/> Cancelar
        </Button>
        <Button variant='success' onClick={() => { setEstatusPedidoStore({ id: pedido.id }); setOpenEstatus(false); }}>
          <FontAwesomeIcon icon={faSave}/> Aceptar
        </Button>
      </Modal.Footer>
    </Modal>


    <Modal show={openComprasPedido} onHide={() => setOpenComprasPedido(false)} fullscreen>

      <Modal.Body className="p-0">
        <div style={{position: 'fixed', top: 0, zIndex: 1, width: '100%'}}>
            <div className='col-lg-12 p-0'>
                <ButtonGroup style={{ width: '100%'  }}>
                    <Button 
                        className='text-start'
                        style={{ background: colorProductos, borderColor: colorProductos, borderRadius: 0 }}
                    >
                        {pedido?.no_pedido}
                    </Button>
                    <Button 
                        className='text-end'
                        style={{ background: colorProductos, borderColor: colorProductos, borderRadius: 0 }}
                        onClick={() => setOpenComprasPedido(false)}
                    >
                        <FontAwesomeIcon icon={faTimes}/> Cerrar
                    </Button>
                </ButtonGroup>
            </div>
            <div className='col-12 mt-3 p-1'>
              <table className="table table-sm table-striped table-bordered " >
                    <thead>
                        <tr class="bg-light">
                            <th colSpan={4} className="text-center">Articulos</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                          <th scope="col">Articulo</th>
                          <th scope="col">Pzas</th>
                          <th scope="col">Talla</th>
                          <th scope="col">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                    {articulosReporteList?.map((compra, index) => {
                      return (
                        <tr key={index}>
                            <th scope="row">{compra?.descripcion}</th>
                            <td className='text-center'>{compra?.cantidad}</td>
                            <td className='text-center'>{compra?.talla?.descripcion || 'N/A'}</td>
                            <td className='text-center'>{formatearCantidad(compra?.total)}</td>
                        </tr>
                      )
                    })}
                    {pedido?.compras?.length === 0 &&
                        <tr>
                            <td className='text-center' colSpan={4}>No se encontrarón resultados</td>
                        </tr>
                    }
                    </tbody>
              </table>
            </div>
            
        </div>
        <FloatButton.Group
            trigger="click"
            type="primary"
            size="large"
            style={{
                right: 24,
                bottom: 24,
                opacity: 0.8,
            }}
            icon={<SettingOutlined />}
        >
          <ButtonPDFArticulosPedido articulos={articulosReporteList}/>
        </FloatButton.Group>
      </Modal.Body>
      
    </Modal>
    </>
  )
}

export default Pedidos