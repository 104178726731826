import clienteAxios from '../config/axios';

export const loginAccessService = async (username, password) => {
    return await clienteAxios.post('/login', { 
        'username': username, 
        'password': password 
    });
} 

export const registrarUsuarioService = async (username, password) => {
    return await clienteAxios.post('/users/create-user-app',  { 
        'email': username, 
        'password': password 
    });
} 