import React from 'react'
import { Card } from 'react-bootstrap'
import { globalStyles } from '../../../recursos/styles'
import { URL_GRADOS, URL_IMG_USER, formatearCantidad, getColorGrado, getImageGuia } from '../../../recursos'
import { Avatar, Badge, Divider, Dropdown, Image, Tour } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faCartPlus, faDashboard, faInfoCircle, faQrcode, faSackDollar, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react';
import { useCourrentUserStore } from '../../../Hooks/CourrentUser/useCourrentUserStore'

const AlumnoCardGuia = ({openGuia, setOpenGuia}) => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);

    const { configuracion } = useCourrentUserStore();

    const items = [
        {
          label: 'Información y Pagos',
          key: '1',
          icon: <FontAwesomeIcon icon={faInfoCircle}/>
        },
        {
          label: 'Ir al Carrito',
          key: '3',
          icon: <FontAwesomeIcon icon={faCartPlus}/> 
        },
        {
            label: 'Estadisticas',
            key: '4',
            icon: <FontAwesomeIcon icon={faDashboard}/>
          },
      ];

    const steps = [
        {
          title: 'Información del Alumno ',
          description: 'La tarjeta muestra la información general del alumno.',
          target: () => ref1.current,
          style: { width: '90%' },
        },
        {
          title: 'Fotografia',
          //description: 'El pequeño circulo morado indica en cuantos tipos de adeudos tiene pendientes el alumno, ya sea en mensualidades, equipo y/o eventos/torneos',
          target: () => ref2.current,
          style: { width: '90%', alignSelf: 'center' }
        },
        {
            title: 'Area de click',
            description: 'Al dar click en esta área se mostrara el ménu.',
            target: () => ref9.current,
            style: { width: '90%', alignSelf: 'center' },
            /* cover: (
                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <img
                            alt="tour.png"
                            src={getImageGuia(1)}
                        />
                    </div>
                </div>
            ) */
          },
        {
          title: 'Nombre del Alumno',
          description: '',
          target: () => ref3.current,
          style: { width: '90%' }
        },
        {
            title: 'Apellidos del Alumno',
            description: '',
            target: () => ref4.current,
            style: { width: '90%' },
            
        },
        {
            title: 'Grado del Alumo ',
            description: '',
            target: () => ref5.current,
            style: { width: '90%' },
        },
        {
            title: 'Adeudo en Mensualidades',
            description: 'Muestra total de las mensualidades que debe el Alumno.',
            target: () => ref6.current,
            style: { width: '90%' },
        },
        {
            title: 'Adeudo en Articulos ',
            description: 'Muestra el monto total que debe el alumno en Articulos. ',
            target: () => ref7.current,
            style: { width: '90%' },
        },
        {
            title: 'Adeudo en Eventos',
            description: 'Muestra el monto total que debe el alumno en Eventos.',
            target: () => ref8.current,
            style: { width: '90%' },
        },
        {
            title: 'Total',
            description: 'Muestra el monto total resultado de cada adeudo mensualidades, equipo y eventos/torneos.',
            target: () => ref10.current,
            style: { width: '90%' },
        }
      ];

    const getGradoConfiguracion = () => {
        if (
            !configuracion?.cardAlumno?.totalMensualidad &&
            !configuracion?.cardAlumno?.totalEquipo && 
            !configuracion?.cardAlumno?.totalEventos && 
            !configuracion?.cardAlumno?.totalGeneral
        ) { 
            return true;
        }
        return false;
    }

    return (
        <>
        {configuracion?.vistaAlumno === 1 && (
            <div className='col-12 p-0'>
            
                <Card ref={ref1} className='shadow-sm animated fadeIn fast' style={{ borderRadius: 0 }}>
                    <Card.Body>
                        <div className='row' >
                            <div className='col-3' style={{alignSelf: 'center' }} ref={ref2}>
                                
                                <Badge style={{ background: 'red' }}  count={configuracion?.cardAlumno?.notificacion ? 3 : 0} >
                                    <Avatar
                                        size={70}
                                        src={URL_IMG_USER }
                                    />
                                </Badge>
                            </div>
                            <div className='col-5' ref={ref9}>
                            <Dropdown
                                menu={{
                                    items,
                                    /* onClick  */
                                }}
                                arrow={{
                                    pointAtCenter: true,
                                }}
                                //onClick={() => setStudentStore(alumno)}
                                placement="bottom"
                            >
                                <div>
                                    <p style={{...globalStyles.nombre, color: '#496491' }} ref={ref3}>
                                        Alfonso 
                                    </p>
                                    <p style={globalStyles.apellidos} ref={ref4}>Díaz Reyes.</p>
                                    
                                    {configuracion?.cardAlumno?.grado && (
                                        <strong style={{ color: getColorGrado(3) }} ref={ref5}>
                                        <FontAwesomeIcon icon={faQrcode}/> Lua
                                        </strong>
                                    )}
                                
                                </div>
                            </Dropdown>
                                
                            </div>
                            
                            <div className='col-4 text-end' >
                                {configuracion?.cardAlumno?.totalMensualidad && (
                                <>
                                <strong style={{...globalStyles.cantidad, color: 'green', fontSize: 13 }} ref={ref6}>
                                    {formatearCantidad( 250 )} <FontAwesomeIcon icon={faCalendarAlt}/>  
                                </strong>
                                <br></br>
                                </>
                                )}
                                {configuracion?.cardAlumno?.totalEquipo && (
                                <>
                                <strong style={{...globalStyles.cantidad, color: 'orange' , fontSize: 13}} ref={ref7}>
                                    {formatearCantidad( 450 )} <FontAwesomeIcon icon={faCartPlus}/> 
                                </strong>
                                <br></br>
                                </>
                                )}
                                {configuracion?.cardAlumno?.totalEventos && (
                                <>
                                <strong style={{...globalStyles.cantidad, color: '#496491', fontSize: 13}} ref={ref8}>
                                    {formatearCantidad( 450 )} <FontAwesomeIcon icon={faTrophy} />
                                </strong>
                                <br></br>
                                </>
                                )}
                                {configuracion?.cardAlumno?.totalGeneral && (
                                <>
                                <strong style={{...globalStyles.cantidad, color: 'purple', fontSize: 13}} ref={ref10}>
                                    {formatearCantidad( 1150 )} <FontAwesomeIcon icon={faSackDollar}/>
                                </strong>
                                </>  
                                )}
                                {getGradoConfiguracion() && (
                                    <Avatar
                                        shape='square'
                                        size={70}
                                        src={`${URL_GRADOS}${3}-png.png`}
                                    />
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            
            </div>
        )}
        {configuracion?.vistaAlumno === 2 && (
        <div className='col-6 p-0' >
            <Card ref={ref1} className='shadow-sm animated fadeIn fast' style={{ borderRadius: 0, height: '100%' }}>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12 text-center mb-1' style={{alignSelf: 'center' }}  ref={ref2}>
                        <Image
                            style={{...globalStyles.imagen, background: '#000000'}}
                            src={URL_IMG_USER}
                        />
                        </div>
                        <div className='col-12 text-center' ref={ref9}>
                        <Dropdown
                            menu={{
                                items,
                            }}
                            arrow={{
                                pointAtCenter: true,
                            }}
                            
                        >
                            <div>
                            <p style={{...globalStyles.nombre, color: '#496491'}} ref={ref3}>
                                Alfonso.
                            </p>
                            <p style={globalStyles.apellidos} ref={ref4}>Díaz Reyes.</p>
                            <strong style={{ color: getColorGrado(3) }} ref={ref5}>
                            <FontAwesomeIcon icon={faQrcode}/> Lua
                            </strong>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }}></Divider>
                            </div>
                        </Dropdown>
                        </div>
                        <div className='col-6 '>
                            <strong style={{...globalStyles.cantidad, color: 'green', fontSize: 12 }} ref={ref6}>
                                <FontAwesomeIcon icon={faCalendarAlt} /> {formatearCantidad( 450 )}
                            </strong>
                        </div>
                        <div className='col-6 '>
                            <strong style={{...globalStyles.cantidad, color: 'orange', fontSize: 12}} ref={ref7}>
                                <FontAwesomeIcon icon={faCartPlus}/>  {formatearCantidad( 450 )}
                            </strong>
                        </div>
                        <div className='col-12 '>
                            <strong style={{...globalStyles.cantidad, color: '#496491', fontSize: 12}} ref={ref8}>
                                <FontAwesomeIcon icon={faTrophy} /> {formatearCantidad( 450 )} 
                            </strong>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
        )}
            <Tour 
                open={openGuia} 
                onClose={() => { setOpenGuia(false); }} 
                steps={steps} 
            />
        </>
    )
}

export default AlumnoCardGuia