import { Avatar, Card } from 'antd'
import React from 'react'
import { URL_GRADOS, URL_IMG, URL_IMG_USER } from '../../../recursos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore'

const HeaderAlumno = ({ buttonGroup }) => {

    const { student } = useStudentsStore();

    return (
        <>
        <div className='col-lg-12 p-0' style={{position: 'fixed', top: 0, zIndex: 10, width: '100%'}}>
            {buttonGroup}
            <Card className='shadow-sm' style={{borderColor: student?.estatus === 1 ? 'green' : 'red', borderRadius: 0}}>
                    <div className='row'>
                        <div className='col-3' style={{ alignSelf:'center' }}>
                            <Avatar
                                size={70}
                                src={student?.urlimg !== null ? `${URL_IMG}${student?.token}/${student?.urlimg}` : URL_IMG_USER }
                            />
                        </div>
                        <div className='col-5' style={{ alignSelf:'center' }}>
                            <p style={{...styleNombre, color: student?.estatus === 1 ? '#496491' : 'gray'}}>
                                {student?.nombre}.
                            </p>
                            <p style={styleApellidos}>{student?.apellido_paterno} {student?.apellido_materno}.</p>
                            
                            <FontAwesomeIcon icon={student?.estatus ? faCheckCircle : faTimesCircle} color={student?.estatus ? 'green' : 'red'} style={{ textAlign: 'center' }}/> {student?.estatus ? 'Activo' : 'Inactivo'}
                            
                        </div>
                        <div className='col-4 text-center' style={{ alignSelf:'center' }}>
                            <img
                                style={{ ...styleImagen, marginTop: 5,  marginRight: 5, borderRadius: 0,  marginVertical: 0, width: 50, height: 50 }}
                                src={`${URL_GRADOS}${student?.grado_id}-png.png`}
                            /><br></br>
                            <strong>{student?.grado?.color} - {student?.grado?.nombre}</strong>
                        </div>
                    </div>
            </Card>
        </div>
        </>
    )
}

const styleImagen = {
    width: 60,
    height: 60,
    borderRadius: 100,
};

const styleNombre = {
    color: '#2F2F2F',
    fontSize: 15,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: 2
};

const styleApellidos = {
    fontSize: 17,
    color: '#64748B',
    marginBottom: 2
};

export default HeaderAlumno