import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore'

const ModalBorrarRegistroPago = ({ alumno_id, open, setOpen, arrayCompras, setComprasArray }) => {

    const { eliminarPagoStore } = useStudentsStore();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        eliminarPagoStore(
            null,
            values?.clave, 
            alumno_id, 
            arrayCompras
        );
        form.setFieldValue('clave', '');
        setComprasArray([]);
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onCancel={()=> setOpen(false)}
            centered
            footer={[]}
            title='Borrar Registro'
        >
            <div className='row mt-3'>
                <div className='col-12 mb-2'>
                    Para condonar los registros seleccionados ingrese la clave de confirmación.
                </div>
                <div className='col-12'>
                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="clave"
                            rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa la clave!',
                            },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Clave"
                                size="large"
                                maxLength={4}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                className="login-form-button" 
                                shape="round" 
                                block
                            >
                            {'Aceptar'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default ModalBorrarRegistroPago