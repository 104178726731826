import { Empty, Button as ButtonAnd, FloatButton, Spin, Tour, Avatar, Modal as ModalAnt } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import EditMaestro from '../maestros/EditMaestro';
import { colorEventos, colorProductos, formatearFecha, getImageBanner, getImageEvento, URL_API, URL_LOCAL } from '../../recursos';
import { CalendarOutlined, DisconnectOutlined, InfoCircleOutlined, LinkOutlined, Loading3QuartersOutlined, SettingOutlined, ShareAltOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { infoToastCenter, swalCenterInfo } from '../../recursos/Toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Card, Modal } from 'react-bootstrap';
import { useMaestrosStore } from '../../Hooks/Maestros/useMaestrosStore';
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore';
import { Element, scroller  } from 'react-scroll';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useStudentsStore } from '../../Hooks/Students/useStudentsStore';


const Home = () => {

    const navigate = useNavigate();

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const [openGuia, setOpenGuia] = useState(false);

    const [settings, setSettings] = useState(false);    

    const steps = [
        {
          title: 'Información del Maestro y Escuela',
          description: 'Este apartado muestra el mensaje de bienvenida con el nombre del Maestro y la Escuela.',
          target: () => ref1.current,
          style: { width: '90%' },
        },
        {
          title: 'Mensualidades Pendientes',
          description: 'Esta notificación indicara el número de alumnos que tienen pagos pendientes en sus mensualidades.',
          target: () => ref2.current,
          style: { width: '90%', alignSelf: 'center' }
        },
        {
          title: 'Pago de Articulos Pendiente',
          description: 'Indica el numero de alumnos que tienen adeudo en articulos por pagar.',
          target: () => ref3.current,
          style: { width: '90%' }
        },
        {
            title: 'Notificación de Eventos',
            description: 'La notificaion indica los días restantes para el evento, el nombre del evento, el número de alumnos que aún no pagan la inscripción del evento y la fecha del evento.',
            target: () => ref4.current,
            style: { width: '90%' },
            
        },
        {
            title: 'Iconos de Notificaciones',
            description: 'El icono que aparesca dependera del tipo de evento',
            target: () => ref5.current,
            style: { width: '90%' },
            cover: (
                <div className='row'>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(1)}
                        />
                    </div>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(2)}
                        />
                    </div>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(3)}
                        />
                    </div>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(4)}
                        />
                    </div>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(5)}
                        />
                    </div>
                    <div className='col-2 mb-3'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(6)}
                        />
                    </div>
                    <div className='col-2'>
                        <img
                        alt="tour.png"
                        src={getImageEvento(7)}
                        />
                    </div>
                </div>
            )
        },
    ];

    const {
        master,
        getDatosMaestroStore,
        actualizarPagosStore,
        changeEstatusMensualidadStore,
        manageTokenStore,
        loading
    } = useMaestrosStore();

    const {
        eventoNotificaciones,
        getEventosNotificacionesStore
    } = useEventosStore();

    const { filtro, setFiltroStore, studentsList } = useStudentsStore();

    useEffect(() => {
        getDatosMaestroStore();
        getEventosNotificacionesStore();
        actualizarPagosStore();
    }, []);
    

    const handleCopy = () => {
        swalCenterInfo(`Se copio el link de registro en el portapapeles.`);
    } 

    const styleImg = {
        width: 50,
        height: 50,
        marginRight: 20,
        borderRadius: 50,
    };

    const consultarAdeudoAlumnos = (tipo, evento = '') => {

        switch (Number(tipo)) {
            case 99:
                setFiltroStore({...filtro, 
                    mensualidad: true,
                    equipo: false,
                    eventos: false,
                    nombre: '',
                    grado: null,
                    evento: ''
                });
                break;
            case 98:
                setFiltroStore({...filtro,
                    mensualidad: false,
                    equipo: true,
                    eventos: false,
                    nombre: '',
                    grado: null,
                    evento: ''
                });
                break;
            default:
                setFiltroStore({...filtro,
                    mensualidad: false,
                    equipo: false,
                    eventos: false,
                    nombre: '',
                    grado: null,
                    evento: evento
                });
                let existe = false;
                studentsList?.forEach(alumno => {
                    if (filtro?.evento !== '') {
                        alumno?.compras?.forEach(compra => {
                            if (compra?.total > compra?.pagado) {
                                if (compra?.descripcion?.toUpperCase() === evento?.toUpperCase() && compra?.concepto_id === 3) {
                                    existe = true;
                                }
                            }
                        });
                    }
                });
                if (!existe) {
                    swalCenterInfo('No hay pagos pendientes para el evento seleccionado.');
                    return;
                }
                break;
        }
        
        navigate('/alumnos');
    }


    return (
        <>
        <Spin tip='Cargando'  spinning={master === null ? loading : false}  style={{ color: 'black', marginTop: master !== null ? 0 : 190  }} indicator={<Loading3QuartersOutlined spin/>}>
        {master !== null && (
        <div className='container-fluid' >
            <Tour 
                open={openGuia} 
                onClose={() => { setOpenGuia(false); }} 
                steps={steps}
            />
            <div ref={ref1} className='row shadow-sm' style={{ backgroundImage: `url(${getImageBanner()})`, backgroundSize: '100%', position: 'fixed', top: 56, zIndex: 1, width: '100%'}}>
                <div className='col-lg-12 text-center mt-3'>
                    <strong style={{ background: '#FFF', padding: 5, opacity: 0.9 }}>Bienvenid@</strong>
                </div>
                <div className='col-lg-12 text-center mt-3'>
                    <strong style={{ background: '#FFF', padding: 5, opacity: 0.9 }}>
                    {master.nombre} {master.apellido_paterno} {master.apellido_materno}
                    </strong>
                </div> 
                <div className='col-lg-12 text-center mt-3 mb-3'>
                    <strong style={{ background: '#FFF', padding: 5, opacity: 0.9 }}>
                    {master.escuela && master.escuela.nombre}
                    </strong>
                </div>
            </div>
            
            <div className='row' style={{ marginTop: '13em' }}>
            
            </div>
            <Element name="notificaciones"></Element>
            <div className='row mt-1'>
            {(eventoNotificaciones.filter(data => data.no_pagado === 0 && (data.tipo == 99)).length > 0 && openGuia) && (
                <div className={`col-6 p-1`}>
                    <Card ref={ref2} className='shadow-sm animated fadeIn' style={{ height: '100%' }}>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <FontAwesomeIcon icon={faCircleDollarToSlot} size="2x" color='green'/>
                                </div>
                                <div className='col-12 text-center'>
                                    <div className='row'>
                                        <div className='col-12 text-uppercase' style={{fontWeight: '700', color: 'green', fontSize: 13}}>
                                            Mensualidades
                                        </div>
                                        
                                        <div className='col-12' style={{ color: '#CB6000', fontSize: 13 }}>
                                        5 alumno(s) tienen pagos pendientes.
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            ) }
            {(eventoNotificaciones.filter(data => data.no_pagado === 0 && (data.tipo == 98)).length > 0 && openGuia) && (
                <div className={`col-6 p-1`}>
                    <Card ref={ref3} className='shadow-sm animated fadeIn' style={{ height: '100%' }} >
                        <Card.Body>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <FontAwesomeIcon icon={faCartShopping} size="2x" color={colorProductos}/>
                                </div>
                                <div className='col-12 text-center'>
                                    <div className='row'>
                                        <div className='col-12 text-uppercase' style={{fontWeight: '700', color: colorProductos, fontSize: 13}}>
                                            Articulos
                                        </div>
                                        
                                        <div className='col-12' style={{ color: '#CB6000', fontSize: 13 }}>
                                        5 alumno(s) tienen pagos pendientes.
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            ) }
            {eventoNotificaciones.map((noti, index) => { 
                let count = eventoNotificaciones.filter(data => data.no_pagado !== 0 && (data.tipo == 99 || data.tipo === 98));
                if (noti.no_pagado !== 0 && (noti.tipo == 99 || noti.tipo === 98)) {
                    return (
                        <div className={`col-${count.length > 1 ? '6' : '12'} p-1`} key={index}>
                            <Card ref={noti.tipo === 99 ? ref2 : ref3} className='shadow-sm animated fadeIn' style={{ height: '100%' }} onClick={() => consultarAdeudoAlumnos(noti.tipo)}>
                                <Card.Body>
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            {noti.tipo === 99 && 
                                            <FontAwesomeIcon icon={faCircleDollarToSlot} size="2x" color='green'/>
                                            }
                                            {noti.tipo === 98 && 
                                                <FontAwesomeIcon icon={faCartShopping} size="2x" color={colorProductos}/>
                                            }
                                        </div>
                                        <div className='col-12 text-center'>
                                            <div className='row'>
                                                <div className='col-12 text-uppercase' style={{fontWeight: '700', color: noti.tipo === 99 ? 'green' : colorProductos, fontSize: 13}}>
                                                    {noti.descripcion}
                                                </div>
                                                {( (noti.no_pagado) > 0 ) ? (
                                                    <div className='col-12' style={{ color: '#CB6000', fontSize: 13 }}>
                                                    {noti.no_pagado} alumno(s) tienen pagos pendientes.
                                                    </div>
                                                ) : (
                                                    <div className='col-12' style={{ color: 'green', fontSize: 13 }}>
                                                    Todos los alumnos estan al corriente con su pago.
                                                    </div>
                                                )}
                                                {noti.fecha !== '' && (
                                                    <div className='col-12' style={{ color: 'purple', fontWeight: 'bold', fontSize: 13 }}>
                                                    {formatearFecha(noti.fecha)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    );
                }
            })}
            
            {openGuia && (
            <div className='col-12 p-0' >
                <Card ref={ref4} className='shadow-sm animated fadeIn' style={{ borderRadius: 0 }}>
                    <Card.Body>
                        <div className='row'>
                            <div className='col-2 text-center' style={{alignSelf: 'center' }} ref={ref5}>
                                <img src={URL_API+'/img/eventos/estatal.jpg'} style={{...styleImg}}></img>
                            </div>
                            <div className='col-10'>
                                <div className='row'>
                                    <div className='col-12' style={{fontWeight: '700', color: 'black', fontSize: 13}}>
                                    10 dias para Torneo Estatal
                                    </div>
                                    <div className='col-12' style={{ color: colorEventos, fontWeight: 'bold', fontSize: 13 }}>
                                    Estatal Zacatecas
                                    </div>
                                    
                                    <div className='col-12' style={{ color: 'orange', fontSize: 13 }}>
                                        3 de 10 de  alumno(s) tienen pagos pendientes.
                                    </div>
                                    
                                    <div className="col-12" style={{ color: 'purple', fontWeight: 'bold', fontSize: 13 }}>12 de junio del 2023</div>

                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            )}

            {eventoNotificaciones.map((noti, index) => {
                if (noti.tipo >= 1 && noti.tipo <= 7) {
                    return (
                    <div className='col-12 p-0' key={index}>
                        <Card className='shadow-sm animated fadeIn' style={{ borderRadius: 0 }} onClick={() => consultarAdeudoAlumnos(noti.tipo, noti.titulo)}>
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-2 text-center' style={{alignSelf: 'center' }}>
                                        <Avatar
                                            shape="square"
                                            size={50}
                                            src={getImageEvento(noti.tipo)}
                                        />
                                    </div>
                                    <div className='col-10'>
                                        <div className='row'>
                                            <div className='col-12' style={{fontWeight: '700', color: 'black', fontSize: 13}}>
                                            {noti.descripcion}
                                            </div>
                                            <div className='col-12' style={{ color: colorEventos, fontWeight: 'bold', fontSize: 13 }}>
                                            {noti.titulo}
                                            </div>
                                            {( (noti.no_pagado) > 0 ) ? (
                                            <div className='col-12' style={{ color: 'orange', fontSize: 13 }}>{noti.no_pagado} de {noti.no_participantes} alumno(s) tienen pagos pendientes.</div>
                                            ) : (
                                            <div className='col-12' style={{ color: 'green', fontSize: 13 }}>No hay pagos pendientes.</div>
                                            )}
                                            {noti.fecha !== '' && (
                                            <div className="col-12" style={{ color: 'purple', fontWeight: 'bold', fontSize: 13 }}>{formatearFecha(noti.fecha) }</div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>)
                }
            })}
            {eventoNotificaciones.filter(data => data.id !== 999 && data.id !== 998).length === 0 && (
                <div className='col-12 text-center h4 animated fadeIn'>
                    <Empty
                    description={
                        <span>
                        No se econtrarón notificaciones pendientes.
                        </span>
                    }
                    />
                </div>
            )}
                <div style={{ marginBottom: '5em' }}></div>
                <div className='col-lg-12'>
                    <Modal
                        show={settings}
                        onHide={() => setSettings(false)}
                        centered
                    >
                        <Modal.Body>
                            <p style={{ fontSize: 19, textAlign: 'center', marginBottom: 10, color: 'black'}}>
                                {master.pago.estatus === 1 ? 'Deshabilitar cobro de mensualidades.' : 'Habilitar cobro de mensualidades.'}
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button 
                                style={{ color: 'white', width: '100%' }}
                                variant={master.pago.estatus === 1 ? 'warning' : 'success'}
                                onClick={() => {changeEstatusMensualidadStore(); setSettings(false)}} 
                            >
                            {master.pago.estatus === 1 ? 'Deshabilitar' : 'Habilitar'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                
            </div>
            <FloatButton.Group
                trigger="click"
                type="primary"
                size="large"
                style={{
                    right: 24,
                    bottom: 24,
                }}
                icon={<SettingOutlined />}
            >
                {master !== null && master.token !== null && (
                    <CopyToClipboard text={`${URL_LOCAL}/registro-de-alumnos/${master && master.token}`}>
                        <FloatButton tooltip={<div>Compartir liga de registro</div>} icon={<ShareAltOutlined />} onClick={handleCopy} />
                    </CopyToClipboard>
                )}
                {master !== null && master.token === null && (
                    <FloatButton tooltip={<div>Abrir Registro</div>} icon={<LinkOutlined />} onClick={() => manageTokenStore(1)} />
                )}
                {master !== null && master.token !== null && (
                    <FloatButton tooltip={<div>Cerrar Registro</div>} icon={<DisconnectOutlined />} onClick={() => manageTokenStore(0)} />
                )}
                
                <FloatButton tooltip={<div>Mensualidades</div>} icon={<CalendarOutlined />} onClick={() => setSettings(true) } />
                {/* <FloatButton tooltip={<div>Información</div>} icon={<InfoCircleOutlined />} onClick={() => { scroller.scrollTo("notificaciones", {duration: 0,delay: 0, smooth: true, offset: -190}); setOpenGuia(true);  }} /> */}
            </FloatButton.Group>
        </div>
        )}

        {!loading && master === null && (
            <EditMaestro/>
        )}
        </Spin>

        
        </>
        
    )
}

export default Home;