import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setTipoEventosList,
    setGradosList,
    setEstadosList,
    setMunicipiosList
} from '../../store/CatalogosSlice';
import { getEstadosService, getGradosService, getMunicipiosService, getTipoEventosService } from '../../Services/CatalogosServices';
import { swalCenterWarning, warningToast } from '../../recursos/Toast';

export const useCatalogosStore = () => {
    
    const dispatch = useDispatch();

    const {
        gradosList,
        estadosList,
        municipiosList,
        tipoEventosList
    } = useSelector(store => store.Catalogos);

    const {
        courrentUser
    } = useSelector(store => store.CourrentUser);

    const token = `${courrentUser?.token_type} ${courrentUser?.access_token}`;

    const setTipoEventosListStore = (data) => {
        dispatch( setTipoEventosList(data) );
    }

    const setGradosListStore = (data) => {
        dispatch( setGradosList(data) );
    }

    const setEstadosListStore = (data) => {
        dispatch( setEstadosList(data) );
    }

    const setMunicipiosListStore = (data) => {
        dispatch( setMunicipiosList(data) );
    }

    const getGradosStore = () => {

        if (!navigator.onLine) {
            return ;
        }

        getGradosService(token)
        .then( response => {
                dispatch( setGradosList(response?.data) );
        }).catch( error => {
            warningToast('Ocurrio un error al cargar los grados.');
        })

    };

    const getEstadosStore = () => {

        if (!navigator.onLine) {
            return ;
        }

        getEstadosService(token)
        .then( response => {
            if (response?.status === 200) {
                dispatch( setEstadosList(response?.data) );
            } else {
                swalCenterWarning('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
        }).catch( error => {
            warningToast('Ocurrio un error al cargar los estados.');
        });

    };

    const getMunicipiosStore = () => {

        if (!navigator.onLine) {
            return ;
        }
        
        getMunicipiosService(token)
        .then( response => {
    
            if (response?.status === 200) {
                dispatch( setMunicipiosList(response?.data) );
            } else {
                swalCenterWarning('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
                
        }).catch( error => {
            warningToast('Ocurrio un error al cargar los municipios.');
        });
        
    };

    const getTipoEventosStore = () => {

        if (!navigator.onLine) {
            return ;
        }
       
        getTipoEventosService(token)
        .then( response => {
    
            if (response?.status === 200) {
                dispatch( setTipoEventosList(response?.data) );
            } else {
                swalCenterWarning('Verifique su conexión a internet. Respuesta:'+response?.status);
            }
                
        }).catch( error => {
            warningToast('Ocurrio un error al cargar los tipos de eventos.');
        });
    } 



    return {
        /* parametros */
        gradosList,
        estadosList,
        municipiosList,
        tipoEventosList,
        /* funciones */
        setTipoEventosListStore,
        setGradosListStore,
        setEstadosListStore,
        setMunicipiosListStore,
        getGradosStore,
        getEstadosStore,
        getMunicipiosStore,
        getTipoEventosStore,
    }
}