import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Form, Modal } from 'react-bootstrap';
import { formatearCantidad, URL_IMG, URL_IMG_USER } from '../../recursos';
import { globalStyles as css } from '../../recursos/styles';
import { Image } from 'antd';
import { faCancel, faCheckCircle, faCheckToSlot, faFaceSadCry, faFaceSmile, faSave, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore';

const EventoAlumno = (props) => {

    const {
        alumno, 
        banderaFecha 
    } = props;

    const { evento, editParticipacionStore, cancelarParticipacionStore } = useEventosStore();

    const { nombre, apellido_materno, apellido_paterno, urlimg, token } = alumno;
    const [costo, setCosto] = useState('');
    const [banderaEntrar, setBanderaEntrar] = useState(false);
    const [openRegistro, setOpenRegistro] = useState(false);
    const [participacion, setParticipacion] = useState({
        id: null,
        costo: null,
        p_puntos: false,
        p_equipo: false,
        p_continua: false,
        p_full: false,
        f_estricta: false,
        f_estricta_equipo: false,
        f_creativa: false,
        f_creativa_equipo: false,
        f_arma: false,
        f_arma_equipo: false,
        grado_id: null,
        evento_id: null,
        alumno_id: null
    });

    useEffect(() => {
        if(alumno.participaciones.length > 0) {
            let part = alumno.participaciones.filter(data => data.evento_id === evento.id);
            if(part.length > 0) {
                let countCategorias = (part[0].p_puntos ? 1 : 0) +
                (part[0].p_continua ? 1 : 0) + 
                (part[0].p_full ? 1 : 0) +
                (part[0].f_estricta ? 1 : 0) +
                (part[0].f_arma ? 1 : 0) +
                (part[0].f_creativa ? 1 : 0) +
                (part[0].f_creativa_equipo ? 1 : 0) +
                (part[0].f_arma_equipo ? 1 : 0) + 
                (part[0].f_estricta_equipo ? 1 : 0) +
                (part[0].p_equipo ? 1 : 0);

                let costo = evento?.costo;
                let cantidadCatExt = ( (countCategorias - evento.no_categorias) * (evento?.extra?.monto || 0) );
        
                if (  cantidadCatExt > 0) {
                    costo = costo + cantidadCatExt;
                }

                setParticipacion({
                    ...part[0],
                    costo: costo
                }); 
                
            } else {
                setParticipacion({
                    ...participacion,
                    costo: evento.costo,
                    evento_id: evento.id,
                    alumno_id: alumno.id,
                    grado_id: alumno.grado_id
                }); 
            }
            
        } else {
            setParticipacion({
                ...participacion,
                costo: evento.costo,
                evento_id: evento.id,
                alumno_id: alumno.id,
                grado_id: alumno.grado_id
            }); 
        }
    },[]);

    useEffect(() => {
        if(alumno.participaciones.length > 0) {
            let part = alumno.participaciones.filter(data => data.evento_id === evento.id);
            if(part.length > 0) {
                let countCategorias = (part[0].p_puntos ? 1 : 0) +
                (part[0].p_continua ? 1 : 0) + 
                (part[0].p_full ? 1 : 0) +
                (part[0].f_estricta ? 1 : 0) +
                (part[0].f_arma ? 1 : 0) +
                (part[0].f_creativa ? 1 : 0) +
                (part[0].f_creativa_equipo ? 1 : 0) +
                (part[0].f_arma_equipo ? 1 : 0) + 
                (part[0].f_estricta_equipo ? 1 : 0) +
                (part[0].p_equipo ? 1 : 0);

                let costo = evento?.costo;
                let cantidadCatExt = ( (countCategorias - evento.no_categorias) * (evento?.extra?.monto || 0) );
        
                if (  cantidadCatExt > 0) {
                    
                    let extra = ((countCategorias - evento.no_categorias) * (evento?.extra?.monto || 0) );
                    costo = costo + extra;
                }
                
                setParticipacion({
                    ...part[0],
                    costo: costo
                }); 
                
            } else {
                setParticipacion({
                    ...participacion,
                    costo: evento.costo,
                    evento_id: evento.id,
                    alumno_id: alumno.id,
                    grado_id: alumno.grado_id
                }); 
            }
            
        } else {
            setParticipacion({
                ...participacion,
                costo: evento.costo,
                evento_id: evento.id,
                alumno_id: alumno.id,
                grado_id: alumno.grado_id
            }); 
        }
    },[alumno]);

    const numeroCatergoriasSeleccionadas = () => {
        let count = (participacion.p_puntos ? 1 : 0) +
        (participacion.p_continua ? 1 : 0) + 
        (participacion.p_full ? 1 : 0) +
        (participacion.f_estricta ? 1 : 0) +
        (participacion.f_arma ? 1 : 0) +
        (participacion.f_creativa ? 1 : 0) +
        (participacion.f_creativa_equipo ? 1 : 0) +
        (participacion.f_arma_equipo ? 1 : 0) + 
        (participacion.f_estricta_equipo ? 1 : 0) +
        (participacion.p_equipo ? 1 : 0);
        
        return count;
    }

    const conuntCategorias = (name, flag) => {
        let operacion = 1; /* 1 suma 2 resta */
        let count = (participacion.p_puntos ? 1 : 0) +
            (participacion.p_continua ? 1 : 0) + 
            (participacion.p_full ? 1 : 0) +
            (participacion.f_estricta ? 1 : 0) +
            (participacion.f_arma ? 1 : 0) +
            (participacion.f_creativa ? 1 : 0) +
            (participacion.f_creativa_equipo ? 1 : 0) +
            (participacion.f_arma_equipo ? 1 : 0) + 
            (participacion.f_estricta_equipo ? 1 : 0) +
            (participacion.p_equipo ? 1 : 0);
        
            if(flag) {
                count = count + 1;  
                operacion = 1;
            } else {
                count = count - 1;
                operacion = 2;
            }
        
        if (Number(count) > Number(evento.no_categorias)) {
            
            if (operacion === 1){
                setParticipacion({
                    ...participacion,
                    [name]: flag,
                    costo: Number(participacion.costo) + Number(evento.extra ? evento.extra.monto : 0)
                });
            } else {
                setParticipacion({
                    ...participacion,
                    [name]: flag,
                    costo: Number(participacion.costo) - Number(evento.extra ? evento.extra.monto : 0)
                });
            }
            

        } else {
            if (Number(count) === Number(evento.no_categorias)) {

                    if (operacion === 1){
                        setParticipacion({
                            ...participacion,
                            [name]: flag,
                            costo: Number(participacion.costo)
                        });
                    } 
                    if (operacion === 2){
                        setParticipacion({
                            ...participacion,
                            [name]: flag,
                            costo: Number(participacion.costo) - Number(evento.extra ? evento.extra.monto : 0)
                        });
                    }
                    
                
            } else {
                setParticipacion({
                    ...participacion,
                    [name]: flag
                });
            }
           
        }
        
    }

    const guardarDatos = () => {

        editParticipacionStore(participacion);
        setOpenRegistro(false);

    }

    const cancelarParticipacion = () => {
        cancelarParticipacionStore({ evento_id: evento.id, alumno_id: alumno.id, participacion_id: participacion.id });
    }

    return (
        <div className='col-12 p-0'>
            <Card className='shadow-sm' style={{ borderRadius: 0 }} onClick={() => setOpenRegistro(!openRegistro)}>
                <Card.Body>
                    <div className='row'>
                        <div className='col-3'>
                            <Image
                                style={{...css.imagen, background: '#000000'}}
                                src={urlimg !== null ? `${URL_IMG}${token}/${urlimg}` : URL_IMG_USER }
                            />
                        </div>
                        <div className='col-5'>
                            <p style={{...css.nombre}}>
                                {nombre}.
                            </p>
                            <p style={css.apellidos}>{apellido_paterno} {apellido_materno}.</p>
                        </div>
                        
                        <div className='col-4 text-center' style={{ alignSelf: 'center' }}>
                            <FontAwesomeIcon 
                                icon={participacion.id !== null ? faCheckToSlot : faTimesCircle} 
                                size="2x" 
                                color={participacion.id !== null ? 'blue' : 'gray'}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={openRegistro} onHide={() => setOpenRegistro(false)} scrollable centered>
                <Modal.Header className='bg-dark text-white'>
                    {alumno.nombre} {alumno.apellido_paterno} {alumno.apellido_materno}
                </Modal.Header>
                <Modal.Body>
                {evento !== null && (evento.tipo_evento_id === 1 || evento.tipo_evento_id === 2 || evento.tipo_evento_id === 5 || evento.tipo_evento_id === 6) && (
                    <div>
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>PELEA CONTINUA</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.p_continua ? true : false}
                                    onChange={() => conuntCategorias('p_continua', !participacion.p_continua)}
                                />
                            </div>
                        </div>
                        {(evento.tipo_evento_id === 5 || evento.tipo_evento_id === 6) && (
                            <div className='row mb-3'>
                                <div className='col-9 fw-bold'>PELEA CONTINUA EN EQUIPO</div>
                                <div className='col-3 text-center'>
                                    <Form.Check 
                                        disabled={banderaFecha}
                                        type="switch"
                                        checked={participacion.p_equipo ? true : false}
                                        onChange={() => conuntCategorias('p_equipo', !participacion.p_equipo)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>PELEA PUNTOS</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.p_puntos ? true : false}
                                    onChange={() => conuntCategorias('p_puntos', !participacion.p_puntos)}
                                />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>PELEA FULL CONTACT</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.p_full ? true : false}
                                    onChange={() => conuntCategorias('p_full', !participacion.p_full)}
                                />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>FORMA ESTRICTA</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.f_estricta ? true : false}
                                    onChange={() => conuntCategorias('f_estricta', !participacion.f_estricta)}
                                />
                            </div>
                        </div>
                        {(evento.tipo_evento_id === 5 || evento.tipo_evento_id === 6) && (
                            <div className='row mb-3'>
                                <div className='col-9 fw-bold'>FORMA ESTRICTA EN EQUIPO</div>
                                <div className='col-3 text-center'>
                                    <Form.Check 
                                        disabled={banderaFecha}
                                        type="switch"
                                        checked={participacion.f_estricta_equipo ? true : false}
                                        onChange={() => conuntCategorias('f_estricta_equipo', !participacion.f_estricta_equipo)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>FORMA CREATIVA</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.f_creativa ? true : false}
                                    onChange={() => conuntCategorias('f_creativa', !participacion.f_creativa)}
                                />
                            </div>
                        </div>
                        {(evento.tipo_evento_id === 5 || evento.tipo_evento_id === 6) && (
                            <div className='row mb-3'>
                                <div className='col-9 fw-bold'>FORMA CREATIVA EN EQUIPO</div>
                                <div className='col-3 text-center'>
                                    <Form.Check 
                                        disabled={banderaFecha}
                                        type="switch"
                                        checked={participacion.f_creativa_equipo ? true : false}
                                        onChange={() => conuntCategorias('f_creativa_equipo', !participacion.f_creativa_equipo)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>FORMA CON ARMA</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.f_arma ? true : false}
                                    onChange={() => conuntCategorias('f_arma', !participacion.f_arma)}
                                />
                            </div>
                        </div>
                        {(evento.tipo_evento_id === 5 || evento.tipo_evento_id === 6) && (
                        <div className='row mb-3'>
                            <div className='col-9 fw-bold'>FORMA CON ARMA EN EQUIPO</div>
                            <div className='col-3 text-center'>
                                <Form.Check 
                                    disabled={banderaFecha}
                                    type="switch"
                                    checked={participacion.f_arma_equipo ? true : false}
                                    onChange={() => conuntCategorias('f_arma_equipo', !participacion.f_arma_equipo)}
                                />
                            </div>
                        </div>
                        )}
                        
                    </div>
                )}
                {evento !== null && (evento.tipo_evento_id !== 1 && evento.tipo_evento_id !== 2 && evento.tipo_evento_id !== 6 && evento.tipo_evento_id !== 5 ) &&
                <div>
                    <div className='row mb-3'>
                        <div className='col-9 fw-bold'>REGISTRAR</div>
                        <div className='col-3 text-center'>
                            <Form.Check 
                                disabled={banderaFecha}
                                type="switch"
                                checked={banderaEntrar}
                                che
                                onChange={() => setBanderaEntrar(!banderaEntrar)}
                            />
                        </div>
                    </div>
                    {(banderaEntrar && evento.tipo_evento_id === 3) && (
                        <div className='row mb-3'>
                            <div className='col-6 fw-bold'>Costo</div>
                            <div className='col-6 text-center'>
                                <Form.Control  
                                    type="number" 
                                    placeholder="Ingrese el costo"
                                    value={costo}
                                    onChange={e => {setCosto(e.target.value); setParticipacion({...participacion, costo: e.target.value})}}
                                />
                            </div>
                        </div>
                    )}
                    
                </div>}
                <div className='row mb-3'>
                    <hr></hr>
                    <div className='col-6 h5 fw-bold'>Total:</div>
                    <div className='col-6 h5 text-center fw-bold'>
                    {formatearCantidad(participacion.costo !== null ? participacion.costo : 0)}
                    </div>
                </div>
                {banderaEntrar && (
                    <ButtonGroup style={{ width: '100%' }}>
                        <Button variant="danger" onClick={() => setOpenRegistro(false)}>
                            <FontAwesomeIcon icon={faTimes}/> Salir
                        </Button>
                        <Button variant="success" onClick={guardarDatos}>
                            <FontAwesomeIcon icon={faSave}/> Guardar
                        </Button>
                    </ButtonGroup>
                )}
                
                {(evento.no_categorias !== null && numeroCatergoriasSeleccionadas() >= evento.no_categorias) && (
                    <>
                    <ButtonGroup style={{ width: '100%' }}>
                        <Button variant="danger" onClick={() => setOpenRegistro(false)}>
                            <FontAwesomeIcon icon={faTimes}/> Salir
                        </Button>
                        <Button variant="success" disabled={banderaFecha}  onClick={guardarDatos}>
                            <FontAwesomeIcon icon={faSave}/> Guardar
                        </Button>
                        
                    </ButtonGroup>
                    
                    </>
                )}
                {participacion?.id !== null && (
                <Button style={{ marginTop: 10, width: '100%' }} variant="warning" disabled={banderaFecha}  onClick={cancelarParticipacion}>
                    <FontAwesomeIcon icon={faCancel}/> Cancelar Registro
                </Button>
                )}
               
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EventoAlumno