import { faTimes, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { colorEventos, formatearFecha, getImageEvento } from '../../recursos';
import EventoAlumno from './EventoAlumno';
import { useEventosStore } from '../../Hooks/Eventos/useEventosStore';
import { FloatButton } from 'antd';
import ButtonPDFAlumnosEvento from './buttons/ButtonPDFAlumnosEvento';
import { SettingOutlined } from '@ant-design/icons';
import { useCourrentUserStore } from '../../Hooks/CourrentUser/useCourrentUserStore';

const EventosRegistro = () => {

    const navigate = useNavigate();

    const { evento, eventoAlumnos, setEventoStore } = useEventosStore();
    const { configuracion } = useCourrentUserStore();
    
    return (
        <>
        <Modal
            onHide={() => {
                navigate(-1);
            }}
            fullscreen
            show
            scrollable
        >
            <Modal.Body className='p-0'>
                <div style={{position: 'fixed', top: 0, zIndex: 1, width: '100%'}}>
                    <div className='col-12 p-0'>
                        <ButtonGroup style={{ width: '100%'  }}>
                            <Button 
                                className='text-start'
                                style={{ background: configuracion?.colorNavBar?.eventos, borderColor: configuracion?.colorNavBar?.eventos ,borderRadius: 0 }}
                            >
                                <FontAwesomeIcon icon={faTrophy}/> Registro de Alumnos
                            </Button>
                            <Button 
                                className='text-end'
                                style={{ background: configuracion?.colorNavBar?.eventos, borderColor: configuracion?.colorNavBar?.eventos, borderRadius: 0 }}
                                onClick={() => navigate(-1)}
                            >
                                <FontAwesomeIcon icon={faTimes}/> Cerrar
                            </Button>
                        </ButtonGroup>
                    </div>
                    {(evento !== null && evento !== undefined) && (
                    <div className='bg-white shadow-sm' style={{ backgroundImage: `url(${getImageEvento(evento.tipo_evento_id)})`, padding: 5}}>
                        <div className='col-12 text-center'>
                            <strong style={{ background: '#FFF', padding: 2, opacity: 0.9 }}>
                            {evento.tipo_evento && evento.tipo_evento.descripcion}
                            </strong>
                        </div>
                        <div className='col-12 text-center mt-3 '>
                            <strong style={{ background: '#FFF', padding: 5, opacity: 0.9 }}>
                            {evento.descripcion}
                            </strong>
                        </div>
                        <div className='col-12 text-center mt-3 mb-3'>
                            <strong style={{ background: '#FFF', padding: 5, opacity: 0.9 }}>
                            {formatearFecha(evento.fecha_evento)}
                            </strong>
                        </div>
                    </div>
                    )}
                </div>
                
                <div className="row" style={{ marginTop: 160 }}>
                {eventoAlumnos && eventoAlumnos.map((alumno, index) => {
                    return (
                        <EventoAlumno
                            key={index}
                            alumno={alumno}
                            banderaFecha={Date.now() > (evento ? (new Date(evento.fecha_evento)) : new Date()) ? true : false} 
                        />
                    )
                })}
                </div>
                <FloatButton.Group
                    zIndex={1000}
                    trigger="click"
                    type="primary"
                    size="large"
                    style={{
                        right: 24,
                        bottom: 24,
                        opacity: 0.8,
                    }}
                    icon={<SettingOutlined />}
                >
                    <ButtonPDFAlumnosEvento/>
                </FloatButton.Group>
            </Modal.Body>
        </Modal>
        
        </>
    )
}

export default EventosRegistro;