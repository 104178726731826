import React, { useState } from 'react'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Form, InputNumber } from 'antd'
import { colorEventos, formatearCantidad } from '../../../recursos'
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore'
import { swalCenterInfo } from '../../../recursos/Toast'
import ModalBorrarRegistroPago from '../modals/ModalBorrarRegistroPago'

const EventosAlumno = () => {

    const [form] = Form.useForm();
    const { student, loading, aplicarPagoStore, setLoadingStore } = useStudentsStore();
    const [idFlag, setIdFlag] = useState(false);
    const [registro, setRegistro] = useState(null);

    const total = (concepto) => {
        if (student?.adeudos?.length > 0) {
 
            let data = student?.adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return Number(data[0].total);
            } else {
                return 0;
            }
            
        } else {
            return 0;
        }
    };

    const aplicarPagoEvento = (values) => {
        setLoadingStore(true);
        let pagoEvento = values.pagoEvento;
        let pago = pagoEvento;

        if (pagoEvento > total(3)) {
            pago = total(3);
            aplicarPagoStore( student.id, 3, Number(pago));
            swalCenterInfo('Regresar '+formatearCantidad(pagoEvento - total(3))+' de cambio');
        } else {
            aplicarPagoStore(student.id, 3, Number(pagoEvento));
        }
        form.resetFields();
    }

    return (
        <>
        <Card className='shadow' title={
            <div className='col-lg-12 h5 text-center' style={{color: colorEventos}} onClick={() => setIdFlag(!idFlag)}>
                <FontAwesomeIcon icon={faTrophy}/> Eventos
            </div>
            }
        >
            <div className='row'>
                <div className='col-lg-12'>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                            <th scope="col">Descripción</th>
                            <th scope="col">Monto</th>
                            <th scope="col">Abonado</th>
                            </tr>
                        </thead>
                        <tbody>
                        {student?.compras?.map((compra, index) => {
                            if(compra?.pagado < compra?.costo && compra?.concepto_id === 3){
                                return (
                                    <tr key={index} onClick={() => idFlag ? setRegistro(compra) : ' '}>
                                        <td className={idFlag ? 'text-primary' : ''}>{compra.descripcion}</td>
                                        <td>{formatearCantidad(compra.costo)}</td>
                                        <td>{formatearCantidad(compra.pagado)}</td>
                                    </tr>
                                )
                            }
                            if(compra?.pagado > compra?.costo && compra?.concepto_id === 3){
                                return (
                                    <tr key={index} onClick={() => idFlag ? setRegistro(compra) : ' '}>
                                        <td className={idFlag ? 'text-primary' : ''}>{compra.descripcion}</td>
                                        <td>{formatearCantidad(compra.costo)}</td>
                                        <td>--</td>
                                    </tr>
                                )
                            }
                        })}
                        {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 3).length === 0 &&
                            <tr>
                                <td className='text-center' colSpan={idFlag ? 5 : 4}>No se encontrarón pagos pendientes</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-12 text-end mt-2'>
                    <strong>Total a pagar: </strong> {formatearCantidad(total(3))}
                </div>
                
            </div>
            {total(3) > 0 && (
            <div className='row'>
                <div className='col-lg-12 text-end mt-2'>
                    <Form layout="horizontal" onFinish={aplicarPagoEvento} form={form}>
                        <Form.Item
                            name="pagoEvento"
                            rules={[{ required: true, message: 'Por favor ingrese una cantidad!' }]}
                        >
                            <InputNumber 
                                min={1} 
                                size="large" 
                                disabled={loading}
                                style={{width: '100%'}}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ background: colorEventos }}
                                shape="round" 
                                size='large'
                                loading={loading}
                            >
                                Abonar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            )}

        </Card>
        <ModalBorrarRegistroPago 
            open={registro !== null ? true : false}
            setOpen={() => setRegistro(null)}
            registro={registro}
        />
        </>
    )
}

export default EventosAlumno