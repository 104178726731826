import clienteAxios from "../config/axios";

export const createMasterService = async (params, token) => {
    return await clienteAxios.post('masters/create', params,
    {
        headers: {
            'Authorization' : token
        }
    });
};

export const actualizarMaestroService = async (params, token) => {
    return await clienteAxios.post('masters/update', params,
    {
        headers: {
            'Authorization' : token
        }
    });
};

export const getDataMasterService = async (token) => {
    return await clienteAxios.get('masters/data',
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const manageTokenService = async (accion, token) => {
    return await clienteAxios.get('masters/token/'+accion, 
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const changeEstatusMensualidadService = async (token) => {
    return await clienteAxios.get('pagos/mensualidades-estatus', 
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const actualizarPagosService = async (token) => {
    return await clienteAxios.get('pagos/actualizar-pagos', 
    {
        headers: {
            'Authorization' : token
        }
    });
}

export const getSaldosService = async (params, token) => {
    return await clienteAxios.post('masters/saldos', params,
    {
        headers: {
            'Authorization' : token
        }
    });
}