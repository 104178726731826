import { createStore, applyMiddleware, compose, } from 'redux';
import { combineReducers } from "@reduxjs/toolkit";
import { loadState, saveState } from './saveStore';
import thunk from 'redux-thunk';
import { rootSlice } from "./store/rootSlice";
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = loadState();

const middleware = applyMiddleware(thunk);

const store = createStore(
    combineReducers({ ...rootSlice }), 
    initialState,
    compose(middleware)
    //composeWithDevTools(middleware)
);

store.subscribe( function () {
        saveState(store.getState());
});

export default store;


/* import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { rootSlice } from "./store/rootSlice";

const store = configureStore({
    reducer: combineReducers({ ...rootSlice }),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    devTools: process.env.NODE_ENV !== "production",
});


export const useAppDispatch = () => useDispatch();  */
