import { faCalendar, faCartPlus, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { URL_GRADOS, URL_IMG, URL_IMG_USER, colorEventos, formatearCantidad, getColorGrado } from '../../../recursos';
import {
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined, } from '@ant-design/icons';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { globalStyles } from '../../../recursos/styles';

const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));




const PagosPendientesQR = () => {

    //const params = useParams();
    const { student, getAlumnoQRStore } = useStudentsStore();

    const total = (concepto) => {
        if (student?.adeudos.length > 0) {
 
            let data = student?.adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return Number(data[0].total);
            } else {
                return 0;
            }
            
        } else {
            return 0;
        }
    };
    
    return (
        <div className="container">
            <div className='row justify-content-center p-0'>
            <div className='col-lg-10 mb-2'>
                <Card className='shadow' style={{ height: '100%', borderRadius: 0 }}>
                    
                    <Card.Body>
                        <div className='col-lg-12 text-center mt-3'>
                        <strong>TOTAL PAGO PENDIENTE </strong>
                        </div>
                        <div className='col-lg-12 text-center mt-2 mb-3'>
                             {formatearCantidad(total(3) + total(2) + total(1))}
                        </div>
                        
                    </Card.Body>
                </Card>
            </div>
            {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 1).length !== 0 &&
                <div className='col-lg-10 mb-2'>
                    <Card className='shadow' style={{ height: '100%', borderRadius: 0 }}>
                        <Card.Header>
                        <div className='col-lg-12 text-success h5 text-center'>
                            <FontAwesomeIcon icon={faCalendar}/> Mensualidades
                        </div>
                        </Card.Header>
                        <Card.Body className='p-2'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <table className="table table-sm table-striped table-border">
                                    <thead className='bg-secondary text-white'>
                                        <tr>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Abonado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {student?.compras?.map((compra, index) => {
                                        if(compra?.pagado < compra?.costo && compra?.concepto_id === 1 ){
                                            return (
                                                <tr key={index}>
                                                    <td className='text-start'>{compra.descripcion}</td>
                                                    <td>{formatearCantidad(compra.costo)}</td>
                                                    <td>{formatearCantidad(compra.pagado)}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                    {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 1).length === 0 &&
                                        <tr>
                                            <td className='text-center' colSpan={4}>No se encontrarón pagos pendientes</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-lg-12 text-end' style={{...globalStyles.cantidad, color: 'brown'}}>
                                <strong>Total Mensualidades: </strong> {formatearCantidad(total(1))}
                            </div>
                            
                        </div>
                        </Card.Body>
                    </Card>
                    
                </div>}
                {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 2).length !== 0 &&
                <div className='col-lg-10 mb-2'>
                    <Card className='shadow' style={{ height: '100%', borderRadius: 0 }}>
                        <Card.Header>
                            <div className='col-lg-12 h5 text-center' style={{ color: 'orange' }}>
                                <FontAwesomeIcon icon={faCartPlus}/> Equipo
                            </div>
                        </Card.Header>
                        <Card.Body className='p-2'>
                        <div className='row'>
                            <div className='col-lg-12'>
                            <table className="table table-sm table-striped ">
                                <thead className='bg-secondary text-white'>
                                    <tr>
                                    <th scope="col">Desc</th>
                                    <th scope="col">Precio</th>
                                    <th scope="col">Cant</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Abonado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {student?.compras?.map((compra, index) => {
                                    if(compra?.pagado < compra?.costo && compra?.concepto_id === 2 && compra?.cantidad > 0){
                                        return (
                                            <tr key={index}>
                                                <td className='text-start align-middle'>{compra.descripcion}</td>
                                                <td className='align-middle'>{formatearCantidad(compra.costo)}</td>
                                                <td className='text-center align-middle'>{compra.cantidad}</td>
                                                <td className='align-middle'>{formatearCantidad(compra.total)}</td>
                                                <td className='align-middle'>{formatearCantidad(compra.pagado)}</td>
                                            </tr>
                                        )
                                    }
                                
                                })}
                                {student?.compras.filter(com => com.pagado < com.costo && com.concepto_id === 2).length === 0 &&
                                    <tr>
                                        <td className='text-center' colSpan={6}>No se encontrarón pagos pendientes</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-12 text-end' style={{...globalStyles.cantidad, color: 'brown'}}>
                            <strong>Total Equipo: </strong> {formatearCantidad(total(2))}
                        </div>
                            
                        </div>
                        </Card.Body>
                    </Card>
                </div>}
                {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 3).length !== 0 &&
                <div className='col-lg-10 mb-2'>
                    <Card className='shadow' style={{ height: '100%', borderRadius: 0 }}>
                        <Card.Header>
                        <div className='col-lg-12 h5 text-center' style={{ color: colorEventos }}>
                            <FontAwesomeIcon icon={faTrophy}/> Torneos y/o Eventos
                        </div>
                        </Card.Header>
                        <Card.Body className='p-2'>
                        <div className='row'>
                            <div className='col-lg-12 '>
                            <table className="table table-sm table-striped">
                                <thead className='bg-secondary text-white'>
                                    <tr>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Abonado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {student?.compras?.map((compra, index) => {
                                    if(compra?.pagado < compra?.costo && compra?.concepto_id === 3){
                                        return (
                                            <tr key={index}>
                                                <td className='text-start align-middle'>{compra.descripcion}</td>
                                                <td className='align-middle'>{formatearCantidad(compra.costo)}</td>
                                                <td className='align-middle'>{formatearCantidad(compra.pagado)}</td>
                                            </tr>
                                        )
                                    }
                                    if(compra?.pagado > compra?.costo && compra?.concepto_id === 3){
                                        return (
                                            <tr key={index}>
                                                <td>{compra.descripcion}</td>
                                                <td>{formatearCantidad(compra.costo)}</td>
                                                <td>--</td>
                                            </tr>
                                        )
                                    }
                                })}
                                {student?.compras?.filter(com => com.pagado < com.costo && com.concepto_id === 3).length === 0 &&
                                    <tr>
                                        <td className='text-center' colSpan={4}>No se encontrarón pagos pendientes</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className='col-lg-12 text-end' style={{...globalStyles.cantidad, color: 'brown'}}>
                            <strong>Total Eventos: </strong> {formatearCantidad(total(3))}
                        </div>
                            
                        </div>
                        </Card.Body>
                    </Card>
                </div>}
                
            </div>
        </div>
    )
};

const styleImagen = {
    width: 60,
    height: 60,
    borderRadius: 100,
};

const styleNombre = {
    color: '#2F2F2F',
    fontSize: 15,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: 2
};

const styleApellidos = {
    fontSize: 17,
    color: '#64748B',
    marginBottom: 2
};


export default PagosPendientesQR