import React from 'react';
import { globalStyles } from '../../../recursos/styles';
import { Card } from 'react-bootstrap';
import { Avatar, Badge, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCartPlus, faCircleNotch, faQrcode, faSackDollar, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { URL_GRADOS, URL_IMG, URL_IMG_USER, formatearCantidad, getColorGrado } from '../../../recursos';
import { useStudentsStore } from '../../../Hooks/Students/useStudentsStore';
import { useCourrentUserStore } from '../../../Hooks/CourrentUser/useCourrentUserStore';

const CardAlumno = ({ alumno, setModalOpciones }) => {

    const { 
        nombre, 
        urlimg,
        apellido_paterno, 
        apellido_materno,
        estatus,
        token, 
        grado_id,
        mensualidades,
        adeudos 
    } = alumno;

    const { 
        loading,
        setStudentStore
    } = useStudentsStore();

    const { 
        configuracion
    } = useCourrentUserStore();
    
    const total = (concepto) => {
        if (adeudos?.length > 0) {
    
            let data = adeudos.filter(data => data.concepto_id === concepto);
            if (data.length > 0) {
                return data[0].total;
            } else {
                return 0;
            }

        } else {
            return 0;
        }
    }

    const getCountTotales = () => {
        let count = 0;
        if (total(1)) 
            count = count +1;
        if (total(2)) 
            count = count +1;
        if (total(3)) 
            count = count +1;

        return count;
    }

    const getGradoConfiguracion = () => {
        if (
            !configuracion?.cardAlumno?.totalMensualidad &&
            !configuracion?.cardAlumno?.totalEquipo && 
            !configuracion?.cardAlumno?.totalEventos && 
            !configuracion?.cardAlumno?.totalGeneral
        ) { 
            return true;
        }
        return false;
    }

    return (
        <>
        {configuracion?.vistaAlumno === 1 && (
        <div className='col-12 p-0'>
            <Card className='shadow-sm animated fadeIn fast' style={{ borderRadius: 0 }}>
                <Card.Body className='' style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <div className='row' >
                        <div className='col-3' style={{alignSelf: 'center' }} >
                            <Badge style={{ background: 'purple' }}  count={configuracion?.cardAlumno?.notificacion ? getCountTotales() : 0}>
                            <Avatar
                                size={70}
                                src={urlimg !== null ? `${URL_IMG}${token}/${urlimg}` : URL_IMG_USER }
                            />
                            </Badge>
                        </div>
                        <div 
                            className='col-5' 
                            style={{alignSelf: 'center' }}
                            onClick={() => { 
                                setModalOpciones(true); 
                                setStudentStore(alumno);
                            }}
                        >
                            <div>
                            <p style={{...globalStyles.nombre, color: estatus === 1 ? '#496491' : 'gray'}}>
                                {nombre}.
                            </p>
                            <p style={{...globalStyles.apellidos}}>{apellido_paterno} {apellido_materno}.</p>
                            
                            {configuracion?.cardAlumno?.grado && (
                            <strong style={{ color: getColorGrado(alumno?.grado_id) }}>
                                <FontAwesomeIcon icon={faQrcode}/> {alumno?.grado?.nombre}
                            </strong>
                            )}
                            
                            </div>
                            
                        </div>
                        
                        <div className='col-4 text-end' style={{alignSelf: 'center' }}>
                            {configuracion?.cardAlumno?.totalMensualidad && (
                            <>
                            <strong style={{...globalStyles.cantidad, color: estatus ? 'green' : 'gray', fontSize: 13 }}>
                                {formatearCantidad( total(1) )} <FontAwesomeIcon icon={!loading ? faCalendarAlt : faCircleNotch} spin={loading}/>  
                            </strong>
                            <br></br>
                            </>
                            )}
                            {configuracion?.cardAlumno?.totalEquipo && (
                            <>
                            <strong style={{...globalStyles.cantidad, color: estatus ? 'orange' : 'gray', fontSize: 13}}>
                                {formatearCantidad( total(2) )} <FontAwesomeIcon icon={!loading ? faCartPlus : faCircleNotch} spin={loading}/> 
                            </strong>
                            <br></br>
                            </>
                            )}
                            {configuracion?.cardAlumno?.totalEventos && (
                            <>
                            <strong style={{...globalStyles.cantidad, color: estatus ? '#496491' : 'gray', fontSize: 13}}>
                                {formatearCantidad( total(3) )} <FontAwesomeIcon icon={!loading ? faTrophy : faCircleNotch} spin={loading}/>
                            </strong><br></br>
                            </>
                            )}
                            {configuracion?.cardAlumno?.totalGeneral && (
                            <>
                            <strong style={{...globalStyles.cantidad, color: 'purple', fontSize: 13}}>
                                {formatearCantidad( total(3) + total(2) + total(1) )} <FontAwesomeIcon icon={!loading ? faSackDollar : faCircleNotch} spin={loading}/>
                            </strong>
                            </>
                            )}
                            {getGradoConfiguracion() && (
                                <Avatar
                                    shape='square'
                                    size={70}
                                    src={`${URL_GRADOS}${grado_id}-png.png`}
                                />
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
        )}
        {configuracion?.vistaAlumno === 2 && (
        <div className='col-6 p-0'>
            <Card className='shadow-sm animated fadeIn fast' style={{ borderRadius: 0, height: '100%' }}>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12 text-center mb-1' style={{alignSelf: 'center' }}>
                            <Avatar
                                size={70}
                                src={urlimg !== null ? `${URL_IMG}${token}/${urlimg}` : URL_IMG_USER }
                            />
                        </div>
                        <div className='col-12 text-center'>
                        {/* <Dropdown
                            menu={{
                                items,
                                onClick 
                            }}
                            arrow={{
                                pointAtCenter: true,
                            }}
                            onClick={() => setStudentStore(alumno)}
                        > */}
                            <div>
                            <p style={{...globalStyles.nombre, color: estatus === 1 ? '#496491' : 'gray'}}>
                                {nombre}.
                            </p>
                            <p style={globalStyles.apellidos}>{apellido_paterno} {apellido_materno}.</p>
                            <strong style={{ color: getColorGrado(alumno?.grado_id) }}>
                            <FontAwesomeIcon icon={faQrcode}/> {alumno?.grado?.nombre}
                            </strong>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }}></Divider>
                            </div>
                        {/* </Dropdown> */}
                        </div>
                        <div className='col-6 '>
                            <strong style={{...globalStyles.cantidad, color: estatus ? 'green' : 'gray', fontSize: 12 }}>
                                <FontAwesomeIcon icon={!loading ? faCalendarAlt : faCircleNotch} spin={loading}/> {formatearCantidad( total(1) )}
                            </strong>
                        </div>
                        <div className='col-6 '>
                            <strong style={{...globalStyles.cantidad, color: estatus ? 'orange' : 'gray', fontSize: 12}}>
                                <FontAwesomeIcon icon={!loading ? faCartPlus : faCircleNotch} spin={loading}/>  {formatearCantidad( total(2) )}
                            </strong>
                        </div>
                        <div className='col-12 '>
                            <strong style={{...globalStyles.cantidad, color: estatus ? '#496491' : 'gray', fontSize: 12}}>
                                <FontAwesomeIcon icon={!loading ? faTrophy : faCircleNotch} spin={loading}/> {formatearCantidad( total(3) )} 
                            </strong>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
        )}
        </>
    )
}

export default CardAlumno;